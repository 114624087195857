import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { SvcClients } from 'src/app/common/client/SvcClients';
import { BaseClient } from 'src/app/common/client/base.client';
import { WorkflowDefinition } from '../@state/entity';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class WorkflowService extends BaseClient {
  constructor(
    private datePipe: DatePipe
  ) {
    super();
  }

  getWorkflows(customerCode) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflows?customerCode=${customerCode}`
      )
    );
  }

  getWorkflowDefinitions(customerCode) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflowClientExecutionConfigurations?customerCode=${customerCode}`
      )
    );
  }

  getWorkflowDefinitionTasks(workflowClientId, customerCode, isManualGeneric , gsFunctionType) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflowTaskClients?workflowClientId=${workflowClientId}&customerCode=${customerCode}&isManualGeneric=${isManualGeneric}&gsFunctionType=${gsFunctionType}`
      )
    );
  }

  GetScheduleFrequencyType() {
    return from(
      this.get(SvcClients.AdminSvc, `PDAPWorkflows/GetScheduleFrequencyType`)
    );
  }

  GetWorkflowClientExecutionType() {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflowClientExecutionType`
      )
    );
  }

  GetWorkflowTask() {
    return from(this.get(SvcClients.AdminSvc, `PDAPWorkflows/GetWorkflowTask`));
  }

  GetNewWorkflowTaskClient(workflowTaskId, clientCode, clientType) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetNewWorkflowTaskClient?workflowTaskId=${workflowTaskId}&clientCode=${clientCode}&clientType=${clientType}`
      )
    );
  }

  SetWorkflowDefinitions(
    customerCode: number, 
    payload: {
      Finished: boolean,
      InUse: boolean,
      Locked: boolean,
      definitions: WorkflowDefinition[],
      Message: string,
    }
  ) 
  {
    payload?.definitions.forEach((definition) => {
      definition?.Configurations.forEach((configuration) => {
        if(!!configuration.DoNotExecuteBefore) {
          configuration.DoNotExecuteBefore = this.datePipe.transform(configuration.DoNotExecuteBefore, 'yyyy-MM-dd HH:mm');
        }
      });
    });
    
    return from(
      this.post(
        SvcClients.AdminSvc,
        `PDAPWorkflows/SaveWorkflowConfiguration?customerCode=${customerCode}`,
        payload
      )
    );
  }

  executeWorkflow(workflowParams: any) {
    return from(
      this.post(
        SvcClients.AdminSvc,
        `PDAPWorkflows/ExecuteWorkflow`,
        workflowParams,
        undefined,
        undefined,
        true,
        null,
        false,
        workflowParams.processID
      )
    );
  }


}
