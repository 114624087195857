import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSTimeControl } from '../../controls/gsControls';

export class FormTimeProperty extends FormProperty {
  type = PropertyTypes.Time;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();
  component = GSTimeControl;

  constructor(
    name: string, 
    label: string, 
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    validate?: boolean,
    public withSeconds: boolean = true,
    public withEnableSwitch: boolean = false, 
    public enableSwitchInitialValue: boolean = false
  ) 
  {
        super(name, label, row , column ,
          rowspan, columnspan, validate);
  }
}
