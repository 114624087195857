import { FormTypes } from '../entity/enum/formTypes';
import { FormProperty } from '../entity/form/formProperty';
import { FormTextboxProperty } from '../entity/form/formTextbox.property';
import { FormAutocompleteProperty } from '../entity/form/formAutocomplete.property';
import { PropertyTypes } from '../entity/enums';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { FormPasswordProperty } from '../entity/form/formPassword.property';
import { FormInputSwitchProperty } from '../entity/form/formInputSwitch.property';
import { TranslateService } from '@ngx-translate/core';
import { FormRadioButtonProperty } from '../entity/form/formRadioButton.property';
import { FormDateProperty } from '../entity/form/formDate.property';
import { FormInfoLabelProperty } from '../entity/form/formInfoLabel.property';
import { FormNumberProperty } from '../entity/form/formNumber.property';
import { FormButtonProperty } from '../entity/form/formButton.property';
import { FormPhoneNumberProperty } from '../entity/form/formPhoneNumber.property';
import { FormNumberDecimalProperty } from '../entity/form/formNumberDecimal.property';
import { Column, EventCollection } from '../entity/entities';
import { FormTimeProperty } from '../entity/form/formTime.property';
import { PageObejctHelper } from '../helpers/pageObject.helper';
import { FormTimeSpanProperty } from '../entity/form/formTimeSpan.property';
import { FormTimeUSFormatProperty } from '../entity/form/formTimeUSFormat.property';
import { FormDateRangeProperty } from '../entity/form/formDateRange.property';
import { FormLocationDropdownProperty } from '../entity/form/formLocationDropdown.property';
import { FormLocationMultiselectProperty } from '../entity/form/formLocationMultiselect.property';
import { regExLib } from '../regEx/regExLib';
import { FormCompanyGroupDropdownProperty } from '../entity/form/formCompanyGroupDropdown.property';
import { FormCalendarFromToProperty } from '../entity/form/formCalendarFromTo.property';
import { FormMultiselectProperty } from '../entity/form/formMultiselect.property';
import { formMaskInputProperty } from '../entity/form/formMaskInput.property';
import { ValidationTypes } from '../entity/enum/validationTypes';

export class FormBuilder {
  public static DefaultColumnSpan: number = 4;

  public static build(formType: FormTypes, translate: TranslateService = null) {
    let properties: FormProperty[] = [];
    switch (formType) {
      case FormTypes.NewCustomer:
        properties = FormBuilder.getNewCustomer();
        break;
      case FormTypes.CustomerInfo:
        properties = FormBuilder.getCustomerInfo();
        break;
      case FormTypes.BusinessLocalization:
        properties = FormBuilder.getBusinessLocalization();
        break;
      case FormTypes.DatasourceConfiguration:
        properties = FormBuilder.getDatasourceConfiguration(translate);
        break;
      case FormTypes.DatasourceConfiguration_AdditionalSettings:
        properties =
          FormBuilder.getDatasourceConfiguration_additionalSettings();
        break;
      case FormTypes.TimeProvider:
        properties = FormBuilder.getTimeProvider();
        break;
      case FormTypes.NewPool:
        properties = FormBuilder.getNewPool();
        break;
      case FormTypes.GlobalConfiguration:
        properties = FormBuilder.getGlobalConfiguration();
        break;
      case FormTypes.GlobalConfiguration_ContinuesPayment:
        properties = FormBuilder.getGlobalConfigurationContinuesPayment();
        break;

      case FormTypes.PayCardCardProvider:
        properties = FormBuilder.getPayCardCardProvider(translate);
        break;
      case FormTypes.PayCardCardProvider_CardProperties:
        properties = FormBuilder.getPayCardCardProvider_properties(translate);
        break;
      //PayCardCardProvider_CardProperties
      case FormTypes.PayCardCardFlex:
        properties = FormBuilder.getPayCardCardFlex(translate);
        break;
      case FormTypes.PayCardGCC:
        properties = FormBuilder.getPayCardGCC(translate);
        break;
      case FormTypes.PayCardADP:
        properties = FormBuilder.getPayCardADP(translate);
        break;
      case FormTypes.PayCardMoneyNetwork:
        properties = FormBuilder.getPayCardMoneyNetwork(translate);
        break;
      case FormTypes.PayCardNetSpend:
        properties = FormBuilder.getPayCardNetSpend(translate);
        break;
      case FormTypes.PayCardRapidCard:
        properties = FormBuilder.getPayCardRapidCard(translate);
        break;
      case FormTypes.PayCardUSBank:
        properties = FormBuilder.getPayCardUSBank(translate);
        break;
      case FormTypes.PayCardTabaPay:
        properties = FormBuilder.getPayCardTabaPay(translate);
        break;
      case FormTypes.PayCardBranch:
        properties = FormBuilder.getPayCardBranch(translate);
        break;
      case FormTypes.Distribution_Replacement_Groups:
        properties = FormBuilder.getDistributionReplacementGroups(translate);
        break;
      case FormTypes.Payroll:
        properties = FormBuilder.getPayroll(translate);
        break;
      case FormTypes.Payroll_Automatic:
        properties = FormBuilder.getPayroll_automatic(translate);
        break;
      case FormTypes.PricingAndBilling:
        properties = FormBuilder.getPricingAndBilling(translate);
        break;
      case FormTypes.Pricing:
        properties = FormBuilder.getPricing(translate);
        break;
      case FormTypes.BillingInfo:
        properties = FormBuilder.getBillingInfo(translate);
        break;
      case FormTypes.BillingAdditionalInfo:
        properties = FormBuilder.getBillingAdditionalInfo(translate);
        break;
      case FormTypes.NewLocation:
        properties = FormBuilder.getNewLocation(translate);
        break;
      case FormTypes.AccountDetails:
        properties = FormBuilder.getAccountDetails(translate);
        break;
      case FormTypes.ChangePassword:
        properties = FormBuilder.getChangePassword(translate);
        break;
      case FormTypes.RegisterManagerAccount:
        properties = FormBuilder.getRegisterManagerAccount(translate);
        break;
      case FormTypes.ActivateManagerAccount:
        properties = FormBuilder.getManagerAcountActivation(translate);
        break;
      case FormTypes.ManagerAccountDetails:
        properties = FormBuilder.getManagerAccountDetails(translate);
        break;
      case FormTypes.ChangeLogFilter:
        properties = FormBuilder.getChangeLogFilter(translate);
        break;
      case FormTypes.ManagersAccountsFilter:
        properties = FormBuilder.getManagersAccountsFilter(translate);
        break;
      case FormTypes.RegisterTicket:
        properties = FormBuilder.getRegisterTicket(translate);
        break;
      case FormTypes.LoadCardReportFilter:
        properties = FormBuilder.getLoadCardReportFilter(translate);
        break;
      case FormTypes.DataExportReportFilter:
        properties = FormBuilder.getDataExportReportFilter(translate);
        break;

      case FormTypes.WithholdReportFilter:
        properties = FormBuilder.getWithholdReportFilter(translate);
        break;
      case FormTypes.ScheduleTraining:
        properties = FormBuilder.getScheduleTraining(translate);
        break;
      case FormTypes.EmployeeProfileFilter:
        properties = FormBuilder.getEmployeeProfileFilter(translate);
        break;
      case FormTypes.EmployeeProfileGratuityAccount:
        properties = FormBuilder.getEmployeeProfileGratuityAccount(translate);
        break;
      case FormTypes.EmployeeProfileCardSettings:
        properties = FormBuilder.getEmployeeProfileCardSettings(translate);
        break;
      case FormTypes.EmployeeProfileCardStatus:
        properties = FormBuilder.getEmployeeProfileCardStatus(translate);
        break;
      case FormTypes.EmployeeProfileCardAssigned:
        properties = FormBuilder.getEmployeeProfileCardAssigned(translate);
        break;
      case FormTypes.ControlPanelWithholdFilter:
        properties = FormBuilder.getControlPanelWithholdFilter(translate);
        break;
      case FormTypes.MonthlyOverviewFilter:
        properties = FormBuilder.getMonthlyOverviewFilter(translate);
        break;
      case FormTypes.ControlPanel_DistributionRules:
        properties = FormBuilder.getControlpanel_distributionRules(translate);
        break;
      case FormTypes.EmployeeSummaryReportFilter:
        properties = FormBuilder.getEmployeeSummaryReportFilter(translate);
        break;
      case FormTypes.Reporting_AuditDetails:
        properties = FormBuilder.getReporting_auditDetails(translate);
        break;
      case FormTypes.EmployeeMapping:
        properties = FormBuilder.getReporting_employeeMapping(translate);
        break;
      case FormTypes.JobMapping:
        properties = FormBuilder.getReporting_jobMapping(translate);
        break;
      case FormTypes.TransactionsWithEndDateFilter:
        properties = FormBuilder.getTransactionsWithEndDateFilter(translate);
        break;
      case FormTypes.Dashboard_CustomerTabLocations:
        properties = FormBuilder.getDashboard_CustomerTabLocations(translate);
        break;
      case FormTypes.Reportig_Payments:
        properties = FormBuilder.getReporting_payment(translate);
        break;
      case FormTypes.PayCardDailyPay:
        properties = FormBuilder.getPayCardDailyPay(translate);
        break;
      case FormTypes.PayCardInPrepaid:
        properties = FormBuilder.getPayCardInPrepaid(translate);
        break;
      case FormTypes.PayCardComData:
        properties = FormBuilder.getPayCardComData(translate);
        break;
      case FormTypes.ControlPanel_VisaChoice:
        properties = FormBuilder.getVisaChoice(translate);
        break;
      case FormTypes.ControlPanel_VisaChoice_Merchant:
        properties = FormBuilder.getVisaChoice_merchant(translate);
        break;
      case FormTypes.ControlPanel_VisaChoice_CustomerBankInfo:
        properties = FormBuilder.getVisaChoice_customerBankInfo(translate);
        break;
      case FormTypes.ControlPanel_VisaChoice_Ownership:
        properties = FormBuilder.getVisaChoice_ownership(translate);
        break;
      case FormTypes.ControlPanel_VisaChoice_AdditionalInfo:
        properties = FormBuilder.getVisaChoice_additionalInfo(translate);
        break;
      case FormTypes.Reporting_Analytics_Totals:
        properties = FormBuilder.getAnalyticsTotals(translate);
        break;
      case FormTypes.GlobalConfiguration_Wage:
        properties = FormBuilder.getGlobalConfiguration_wage(translate);
        break;
      case FormTypes.ControlPanel_PlaidConfiguration:
        properties = FormBuilder.getPlaidConfiguration(translate);
        break;
      case FormTypes.CustomerDeactivation:
        properties = FormBuilder.getCustomerDeactivation(translate);
        break;
      case FormTypes.TrackingGroupsCommissionSales:
        properties = FormBuilder.getTrackingGroupsCommissionSales(translate);
        break;
      case FormTypes.ControlPanel_CommissionDistributionRules:
        properties =
          FormBuilder.getControlpanel_commissionDistributionRules(translate);
        break;
      case FormTypes.CompanyGroupAddProperty:
        properties = FormBuilder.getcompanyGroupAddProperty(translate);
        break;
      case FormTypes.ControlPanel_VisaChoice_ACH:
        properties = FormBuilder.getVisaChoice_ach(translate);
        break;
      case FormTypes.TabapayReconciliationReportFilter:
        properties =
          FormBuilder.getTabapayReconciliationReportFilter(translate);
        break;
      case FormTypes.QuickbooksFilter:
        properties = FormBuilder.getQuickbooksFilter(translate);
        break;
      case FormTypes.PayCardFakeCard:
        properties = FormBuilder.getPayCardFakeCard(translate);
        break;
      case FormTypes.CompanyGroup_Details:
        properties = FormBuilder.getCompanyGroupDetails(translate);
        break;
      case FormTypes.CompanyGroup_Details2:
        properties = FormBuilder.getCompanyGroupDetails_part2(translate);
        break;
      case FormTypes.CompanyGroup_Details3:
        properties = FormBuilder.getCompanyGroupDetails_part3(translate);
        break;
      case FormTypes.Reporting_Analytics:
        properties = FormBuilder.getReportingAnaliytics(translate);
        break;
      case FormTypes.DwollaVerifiedCustomerFilter:
        properties = FormBuilder.getDwollaVerifiedCustomerFilter(translate);
        break;
      case FormTypes.DwollaVerifiedCustomer:
        properties = FormBuilder.getDwollaVerifiedCustomer(translate);
        break;
      case FormTypes.DwollaVerifiedCustomerController:
        properties = FormBuilder.getDwollaVerifiedCustomerController(translate);
        break;
      case FormTypes.DwollaVerifiedCustomerBenefitialOwner:
        properties =
          FormBuilder.getDwollaVerifiedCustomerBenefitialOwner(translate);
        break;
      case FormTypes.DwollaCustomerFundingSource:
        properties = FormBuilder.getDwollaCustomerFundingSource(translate);
        break;
      case FormTypes.DwollaVerifyMicrodeposit:
        properties =
          FormBuilder.getDwollaVerifyMicrodepositProperties(translate);
        break;
      case FormTypes.DwollaCustomerBankAccounts:
        properties = FormBuilder.getDwollaCustomerBankAccounts(translate);
        break;
      case FormTypes.DwollaAssignLocations:
        properties = FormBuilder.getDwollaAssignedLocations(translate);
        break;
      case FormTypes.DwollaEmailVerification:
        properties = FormBuilder.getDwollaEmailVerification(translate);
        break;
      case FormTypes.TabapayReconciliationBankStatementsFilter:
        properties =
          FormBuilder.getTabapayReconciliationBankStatsFilter(translate);
        break;
      case FormTypes.TabapayReconciledTransactionsFilter:
        properties =
          FormBuilder.getTabapayReconciledTransactionsFilter(translate);
        break;
      case FormTypes.TabapayReconciledTransactionsByCustomerFilter:
        properties =
          FormBuilder.getTabapayReconciledTransactionsByCustomerFilter(
            translate
          );
        break;
      case FormTypes.CompanyGroupDwolla:
        properties = FormBuilder.getCompanyGroupDwolla(translate);
        break;
      case FormTypes.TabapayUnreconciledTransactionsFilter:
        properties =
          FormBuilder.getTabapayUnreconciledTransactionsFilter(translate);
        break;
      case FormTypes.CustomerAdditionalParamsText:
        properties = FormBuilder.getCustomerAdditionalParamsText(translate);
        break;
      case FormTypes.CustomerAdditionalParamsDecimal:
        properties = FormBuilder.getCustomerAdditionalParamsDecimal(translate);
        break;
      case FormTypes.CustomerAdditionalParamsDate:
        properties = FormBuilder.getCustomerAdditionalParamsDate(translate);
        break;
      case FormTypes.CustomerAdditionalParamsPassword:
        properties = FormBuilder.getCustomerAdditionalParamsPassword(translate);
        break;
      case FormTypes.CompanyGroupCustomerAdditionalParams:
        properties =
          FormBuilder.getCompanyGroupCustomerAdditionalParams(translate);
        break;
      case FormTypes.CompanyGroup_BasicDetails:
        properties = FormBuilder.getCompanyGroup_basicDetails(translate);
        break;
      case FormTypes.CompanyGroup_Datasource:
        properties = FormBuilder.getCompanyGroup_datasource(translate);
        break;
      case FormTypes.ControlPanel_VisaChoiceCustomerMapping:
        properties = FormBuilder.getVisaChoice_customerMapping(translate);
        break;
      case FormTypes.Plaid_Originators:
        properties = FormBuilder.getPlaid_Originators(translate);
        break;
      case FormTypes.Plaid_CustomerMappings:
        properties = FormBuilder.getPlaid_CustomerMappings(translate);
        break;
      case FormTypes.PayCardInstant:
        properties = FormBuilder.getpayCardInstant(translate);
        break;
      case FormTypes.Plaid_OriginatorsTransferVolume:
        properties = FormBuilder.getPlaidOriginatorsTransferVolume(translate);
        break;
      case FormTypes.PayCardPlaid:
        properties = FormBuilder.getpayCardPlaid(translate);
        break;
      case FormTypes.DataExportConnectorDetails:
        properties = FormBuilder.getDataExportConnector(translate);
        break;
      case FormTypes.DataExportConnectorDetails_Credentials:
        properties = FormBuilder.getDataExportConnector_credentials(translate);
        break;
      case FormTypes.DataExportDetails:
        properties = FormBuilder.getDataExportDetails(translate);
        break;
      case FormTypes.TAConfiguration:
        properties = FormBuilder.getTAConfiguration(translate);
        break;
      case FormTypes.Plaid_BankAccount:
        properties = FormBuilder.getPlaidBankAccount(translate);
        break;
      case FormTypes.Vendor_Details:
        properties = FormBuilder.getVendorDetails(translate);
        break;
      case FormTypes.Vendor_Credentials_Details:
        properties = FormBuilder.getVendorCredentialDetails(translate);
        break;
      case FormTypes.PaymentIntegrations_PlaidOriginatorsFilter:
        properties = FormBuilder.getPlaidOriginatorsFilter(translate);
        break;
      case FormTypes.PaymentIntegrations_PlaidDebitTrFilter:
        properties = FormBuilder.getPlaidDebitTransactionsFilter(translate);
        break;
      case FormTypes.PayCardNetworkFee:
        properties = FormBuilder.getCardProviderNetworkFee(translate);
        break;
      case FormTypes.EmployeeProfileCardAssignedActive:
        properties =
          FormBuilder.getEmployeeProfileCardAssignedActive(translate);
        break;
      case FormTypes.PayCardTabaPayBank:
        properties = FormBuilder.getPayCardTabaPayBank(translate);
        break;
      case FormTypes.WorkflowExecutionLog:
        properties = FormBuilder.getWorkflowExecutionLog(translate);
        break;
      case FormTypes.Workflow_Definitions_Details:
        properties = FormBuilder.getWorkflowDefinitionDetails(translate);
        break;
      case FormTypes.Workflow_Definitions_Execution_Configuration:
        properties =
          FormBuilder.getWorkflowDefinitionExecutionConfiguration(translate);
        break;
      case FormTypes.Workflow_Task_Invocation_Details:
        properties = FormBuilder.getWorkflowTaskInvocationDetails(translate);
        break;
      case FormTypes.CompanyGroup_Settings:
        properties = FormBuilder.getCompanyGroupSettings(translate);
        break;
      case FormTypes.EmployeeProfileCardAssignedEnableLoad:
        properties =
          FormBuilder.getEmployeeProfileCardAssignedEnableLoad(translate);
        break;
      case FormTypes.EmployeeProfileDetailsVirtual:
        properties = FormBuilder.getEmployeeProfileDetailsVirtual(translate);
        break;
    }
    return properties;
  }

  private static getNewCustomer() {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'enrollmentID',
      'Enter code provided in CRM',
      0,
      0,
      null,
      12,
      true
    );
    p1.validationMessage = 'Enrollment code is mandatory';
    properties.push(p1);
    let p2: FormAutocompleteProperty = new FormAutocompleteProperty(
      'group',
      'Group',
      null,
      1,
      0,
      null,
      12,
      true,
      true
    );
    p2.validationMessage = 'Customer group is mandatory';
    properties.push(p2);

    let p3: FormDropdownProperty = new FormDropdownProperty(
      'implementationType',
      'Implementation type',
      null,
      2,
      0,
      null,
      12,
      true,
      true
    );
    p3.validationMessage = 'Customer group is mandatory';
    p3.options = [];
    p3.options.push({ label: 'Full integration', value: 1 });
    p3.options.push({ label: 'Direct Payments to Card', value: 2 });
    properties.push(p3);
    p3.visible = false;

    return properties;
  }

  private static getCustomerInfo() {
    let properties: FormProperty[] = [];

    let p1: FormAutocompleteProperty = new FormAutocompleteProperty(
      'CompanyGroupId',
      'Group',
      null,
      0,
      0,
      null,
      6,
      true,
      true
    );
    p1.validationMessage = 'Group is mandatory';
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'UserFirstName',
      'First name',
      0,
      1,
      null,
      3,
      true
    );
    p2.validationMessage = 'First name is mandatory';
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'UserLastName',
      'Last name',
      0,
      2,
      null,
      3,
      true
    );
    p3.validationMessage = 'Last name is mandatory';
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'CustomerName',
      'Outlet name',
      1,
      0,
      null,
      4,
      true
    );
    p4.validationMessage = 'Outlet name is mandatory';
    properties.push(p4);

    let p41: FormTextboxProperty = new FormTextboxProperty(
      'ExternalID',
      'External ID',
      1,
      1,
      null,
      2,
      false
    );
    properties.push(p41);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'UserEmail',
      'E-mail',
      1,
      2,
      null,
      3,
      true,
      regExLib.regex_Email
    );
    properties.push(p5);

    let p6: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'UserPhone',
      'Phone number',
      1,
      3,
      null,
      3,
      true
    );
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'CustomerStreet',
      'Adress line 1',
      2,
      0,
      null,
      3,
      true
    );
    properties.push(p7);

    let p71: FormTextboxProperty = new FormTextboxProperty(
      'CustomerStreetNumber',
      'Adress line 2',
      2,
      0,
      null,
      3,
      false
    );
    properties.push(p71);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'CustomerNote',
      'Note',
      2,
      1,
      2,
      6,
      false
    );
    properties.push(p8);

    let p9: FormTextboxProperty = new FormTextboxProperty(
      'CustomerZIP',
      'Zip code',
      3,
      0,
      null,
      1,
      true
    );
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'CustomerCity',
      'City',
      3,
      1,
      null,
      2,
      true
    );
    properties.push(p10);

    let p11: FormDropdownProperty = new FormDropdownProperty(
      'CustomerState',
      'State',
      null,
      3,
      2,
      null,
      2,
      true
    );
    properties.push(p11);

    let p12: FormTextboxProperty = new FormTextboxProperty(
      'CustomerStateCode',
      'State Code',
      3,
      3,
      null,
      1,
      false
    );
    p12.maxLen = 4;
    p12.readonly = true;
    properties.push(p12);

    return properties;
  }

  private static getBusinessLocalization() {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'TimeZoneID',
      'Time zone',
      null,
      0,
      0,
      null,
      12,
      false
    );
    p1.validationMessage = 'Time zone is mandatory';
    properties.push(p1);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'Note',
      'Note',
      4,
      0,
      6,
      12,
      false
    );
    properties.push(p6);

    return properties;
  }

  private static getDatasourceConfiguration(translate) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'PosID',
      'POS',
      null,
      0,
      0,
      null,
      12,
      false
    );
    properties.push(p1);
    p1.validate = true;
    p1.validateAllowNull = false;

    //-------Revel
    let p2: FormTextboxProperty = new FormTextboxProperty(
      'RevelApiURL',
      'URL',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'RevelApiKey',
      'Key',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p3);

    let p4: FormPasswordProperty = new FormPasswordProperty(
      'RevelApiSecret',
      'Secret',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'RevelEstablishmentID',
      'Establishment',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p5);

    //-------ALOHA
    let p6: FormTextboxProperty = new FormTextboxProperty(
      'AlohaArchiveFolder',
      'Archive folder',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'AlohaGrindExePath',
      'Grinder exe path',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p7);

    let p7_1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'AlohaQuickServe',
      'Quick Serve POS',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p7_1);

    //-------POSitouch
    let p8: FormTextboxProperty = new FormTextboxProperty(
      'POSitouchFolder',
      'Folder',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p8);

    //-------RmWin
    let p9: FormTextboxProperty = new FormTextboxProperty(
      'RmWinFolder',
      'Folder',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p9);

    //-------Aldelo
    let p10: FormTextboxProperty = new FormTextboxProperty(
      'AldeloDBLocation',
      'Database location',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p10);

    //-------Focus
    let p11: FormTextboxProperty = new FormTextboxProperty(
      'FocusDataFolder',
      'Data folder',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p11);

    //-------Toast
    let p12: FormTextboxProperty = new FormTextboxProperty(
      'ToastApiUrl',
      'Api URL',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p12);

    let p13: FormPasswordProperty = new FormPasswordProperty(
      'ToastClientId',
      'Client identity',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p13);

    let p14: FormPasswordProperty = new FormPasswordProperty(
      'ToastClientSecret',
      'Client secret',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p14);

    let p15: FormTextboxProperty = new FormTextboxProperty(
      'ToastRestaurantId',
      'Restaurant identity',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p15);

    //-------BreadCrumbs
    let p16: FormTextboxProperty = new FormTextboxProperty(
      'BreadCrumbsApiUrl',
      'Api URL',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p16);

    let p17: FormPasswordProperty = new FormPasswordProperty(
      'BreadCrumbsApiKey',
      'Api key',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p17);

    let p18: FormPasswordProperty = new FormPasswordProperty(
      'BreadCrumbsUsername',
      'Username',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p18);

    let p19: FormPasswordProperty = new FormPasswordProperty(
      'BreadCrumbsPassword',
      'Password',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p19);

    //-------SUltimate
    let p20: FormTextboxProperty = new FormTextboxProperty(
      'SUltimateApiUrl',
      'Api URL',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p20);

    let p21: FormPasswordProperty = new FormPasswordProperty(
      'SUltimateEmail',
      'Email',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p21);

    let p22: FormPasswordProperty = new FormPasswordProperty(
      'SUltimatePassword',
      'Password',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p22);

    let p23: FormTextboxProperty = new FormTextboxProperty(
      'SUltimateStore',
      'Store',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p23);

    //-------Brink
    let p24: FormTextboxProperty = new FormTextboxProperty(
      'BrinkApiUrl',
      'Api URL',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p24);

    let p25: FormPasswordProperty = new FormPasswordProperty(
      'BrinkAccessToken',
      'Token',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p25);

    let p26: FormPasswordProperty = new FormPasswordProperty(
      'BrinkLocationToken',
      'Location token',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p26);

    //-------NorthStar
    let p27: FormTextboxProperty = new FormTextboxProperty(
      'NorthStarSiteId',
      'Site identity',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p27);

    let p28: FormTextboxProperty = new FormTextboxProperty(
      'NorthStarApiUrl',
      'Api Url',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p28);

    let p29: FormTextboxProperty = new FormTextboxProperty(
      'NorthStarConfigurationApiUrl',
      'Configuration Api URL',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p29);

    let p30: FormPasswordProperty = new FormPasswordProperty(
      'NorthStarAccessToken',
      'Access token',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p30);

    //-------Emagine
    let p31: FormTextboxProperty = new FormTextboxProperty(
      'EmagineApiBaseURL',
      'Api base url',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p31);

    let p32: FormTextboxProperty = new FormTextboxProperty(
      'EmagineExternalPartner',
      'External partner',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p32);

    let p33: FormTextboxProperty = new FormTextboxProperty(
      'EmagineLocation',
      'Location',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p33);

    let p34: FormPasswordProperty = new FormPasswordProperty(
      'EmagineApiKey',
      'Api key',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p34);

    //-------Rosnet
    let p35: FormTextboxProperty = new FormTextboxProperty(
      'RosnetApiUrl',
      'Api URL',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p35);

    let p36: FormTextboxProperty = new FormTextboxProperty(
      'RosnetLocationId',
      'Location identity',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p36);

    let p37: FormPasswordProperty = new FormPasswordProperty(
      'RosnetUsername',
      'Username',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p37);

    let p38: FormPasswordProperty = new FormPasswordProperty(
      'RosnetPassword',
      'Password',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p38);

    //GDA
    let p391: FormDropdownProperty = new FormDropdownProperty(
      'GDAVendorId',
      'Vendor',
      null,
      1,
      0,
      null,
      12,
      true
    );
    p391.validationMessage = 'Vandor selection is mandatory';
    properties.push(p391);

    let p39: FormTextboxProperty = new FormTextboxProperty(
      'GDAClientId',
      'Client identity',
      2,
      0,
      null,
      12,
      false
    );
    p39.readonly = true;
    p39.tooltip = 'Client identity will be automaticaly generated after save';
    properties.push(p39);

    let p40: FormTextboxProperty = new FormTextboxProperty(
      'GDAClientSecret',
      'Client secret',
      3,
      0,
      null,
      12,
      false
    );
    p40.readonly = true;
    p40.tooltip = 'Client secret will be automaticaly generated after save';
    properties.push(p40);

    let p401: FormTextboxProperty = new FormTextboxProperty(
      'GDAScopeID',
      'Scope ID',
      4,
      0,
      null,
      12,
      true
    );
    p401.readonly = false;
    p401.tooltip =
      'Default value is Customers "API KEY". If the customer wants to call GDA API using his own "Location ID", then enter here Customer\'s "Location ID" instead of the default "API KEY" value.';
    properties.push(p401);

    //DigitalDining
    let p41: FormTextboxProperty = new FormTextboxProperty(
      'CustomerDataFolder',
      'Data folder',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p41);

    //-------NCR
    let p42: FormTextboxProperty = new FormTextboxProperty(
      'NCRApiUrl',
      'Api url',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p42);

    let p43: FormPasswordProperty = new FormPasswordProperty(
      'NCRHMACKey',
      'HMAC key',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p43);

    let p44: FormPasswordProperty = new FormPasswordProperty(
      'NCRUsername',
      'Username',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p44);

    let p45: FormPasswordProperty = new FormPasswordProperty(
      'NCRPassword',
      'Password',
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p45);

    let p46: FormTextboxProperty = new FormTextboxProperty(
      'NCRStoreId',
      'Store Id',
      5,
      0,
      null,
      12,
      false
    );
    properties.push(p46);

    let p46_1: FormTextboxProperty = new FormTextboxProperty(
      'NCRLocationId',
      'Location Id',
      5,
      0,
      null,
      12,
      false
    );
    properties.push(p46_1);

    //-------NCR
    let p47: FormPasswordProperty = new FormPasswordProperty(
      'SquareAPIToken',
      'Api token',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p47);

    let p48: FormTextboxProperty = new FormTextboxProperty(
      'SquareLocationId',
      'Location',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p48);

    let p49: FormTextboxProperty = new FormTextboxProperty(
      'SquareApiBaseURL',
      'Api base url',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p49);

    //-------Linga
    let p50: FormTextboxProperty = new FormTextboxProperty(
      'LingaAPIKey',
      'Api Key',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p50);

    let p51: FormTextboxProperty = new FormTextboxProperty(
      'LingaStoreId',
      'Store ID',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p51);

    //--Heartland
    let p55: FormPasswordProperty = new FormPasswordProperty(
      'HeartlandAuthenticationKey',
      'Authentication Key',
      1,
      0,
      null,
      12,
      false
    );
    properties.push(p55);

    let p56: FormPasswordProperty = new FormPasswordProperty(
      'HeartlandLocationAPIKey',
      'Location API Key',
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p56);

    let p57: FormTextboxProperty = new FormTextboxProperty(
      'HeartlandBaseAPIURL',
      'API URL',
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p57);

    //-------Ctuit
    let p58: FormPasswordProperty = new FormPasswordProperty(
      'CtuitAPIToken',
      'Api Token',
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p58);

    let p59: FormTextboxProperty = new FormTextboxProperty(
      'CtuitUserId',
      'User ID',
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p59);

    let p60: FormTextboxProperty = new FormTextboxProperty(
      'CtuitLocationId',
      'Location ID',
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p60);

    let p61: FormTextboxProperty = new FormTextboxProperty(
      'CtuitLocationGroupId',
      'Location Group ID',
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p61);

    //-------Squirrel

    let p62: FormTextboxProperty = new FormTextboxProperty(
      'SquirrelServer',
      'Server',
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p62);

    let p63: FormTextboxProperty = new FormTextboxProperty(
      'SquirrelDatabase',
      'Database',
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p63);

    let p64: FormTextboxProperty = new FormTextboxProperty(
      'SquirrelUser',
      'User',
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p64);

    let p65: FormPasswordProperty = new FormPasswordProperty(
      'SquirrelPassword',
      'Password',
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p65);

    let p651: FormNumberProperty = new FormNumberProperty(
      'SquirrelGrossNet',
      'Squirrel Gross Net',
      'decimal',
      0,
      0,
      5,
      0,
      null,
      12
    );
    p651.visible = false;
    properties.push(p651);

    //AGILYSYS
    let p66: FormPasswordProperty = new FormPasswordProperty(
      'AgilysysUsername',
      translate.instant('datasourceConfiguration.agilysy.username'),
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p66);

    let p67: FormPasswordProperty = new FormPasswordProperty(
      'AgilysysPassword',
      translate.instant('datasourceConfiguration.agilysy.password'),
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p67);

    let p68: FormTextboxProperty = new FormTextboxProperty(
      'AgilysysHost',
      translate.instant('datasourceConfiguration.agilysy.host'),
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p68);

    let p69: FormTextboxProperty = new FormTextboxProperty(
      'AgilysysPort',
      translate.instant('datasourceConfiguration.agilysy.port'),
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p69);

    let p70: FormTextboxProperty = new FormTextboxProperty(
      'AgilysysFolder',
      translate.instant('datasourceConfiguration.agilysy.folder'),
      5,
      0,
      null,
      12,
      true
    );
    properties.push(p70);

    let p71: FormTextboxProperty = new FormTextboxProperty(
      'AgilysysProfitCenterIds',
      translate.instant('datasourceConfiguration.agilysy.centerIds'),
      6,
      0,
      null,
      12,
      true
    );
    properties.push(p71);

    //SIMPHONY
    let p72: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyApiBaseURL',
      translate.instant('datasourceConfiguration.simphony.apiBaseUrl'),
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p72);

    let p73: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyAuthApiBaseURL',
      translate.instant('datasourceConfiguration.simphony.authApiBaseUrl'),
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p73);

    let p74: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyLocationId',
      translate.instant('datasourceConfiguration.simphony.location'),
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p74);

    let p75: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyEnterpriseShortName',
      translate.instant('datasourceConfiguration.simphony.enterpriseShortName'),
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p75);

    let p76: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyBIClientId',
      translate.instant('datasourceConfiguration.simphony.BIClient'),
      5,
      0,
      null,
      12,
      true
    );
    properties.push(p76);

    let p77: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyLMApiToken',
      translate.instant('datasourceConfiguration.simphony.LMApiToken'),
      6,
      0,
      null,
      12,
      false
    );
    properties.push(p77);

    let p78: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyLMPassword',
      translate.instant('datasourceConfiguration.simphony.LMPassword'),
      7,
      0,
      null,
      12,
      false
    );
    properties.push(p78);

    let p771: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyBIUsername',
      translate.instant('datasourceConfiguration.simphony.BIUsername'),
      7,
      0,
      null,
      12,
      true
    );
    properties.push(p771);

    let p781: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyBIPassword',
      translate.instant('datasourceConfiguration.simphony.BIPassword'),
      8,
      0,
      null,
      12,
      true
    );
    properties.push(p781);

    let p791: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyBIOrgName',
      translate.instant('datasourceConfiguration.simphony.BIOrgName'),
      9,
      0,
      null,
      12,
      true
    );
    properties.push(p791);

    //-------BrinkerWebhook

    let p80: FormPasswordProperty = new FormPasswordProperty(
      'BrinkerApiUrl',
      translate.instant('datasourceConfiguration.BrinkerWebhook.apiUrl'),
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p80);

    let p81: FormPasswordProperty = new FormPasswordProperty(
      'BrinkerClientId',
      translate.instant('datasourceConfiguration.BrinkerWebhook.clientId'),
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p81);

    let p82: FormTextboxProperty = new FormTextboxProperty(
      'BrinkerClientSecret',
      translate.instant('datasourceConfiguration.BrinkerWebhook.clientSecret'),
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p82);

    let p83: FormTextboxProperty = new FormTextboxProperty(
      'BrinkerRestaurantId',
      translate.instant('datasourceConfiguration.BrinkerWebhook.restaurantId'),
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p83);

    //-------NCRTLDM

    let p84: FormPasswordProperty = new FormPasswordProperty(
      'NCRAPISharedKey',
      translate.instant('datasourceConfiguration.NCRTLDM.sharedKey'),
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p84);

    let p85: FormPasswordProperty = new FormPasswordProperty(
      'NCRAPISecretKey',
      translate.instant('datasourceConfiguration.NCRTLDM.secretKey'),
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p85);

    let p86: FormTextboxProperty = new FormTextboxProperty(
      'NCRAPINepOrganization',
      translate.instant('datasourceConfiguration.NCRTLDM.nepOrganization'),
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p86);

    let p87: FormTextboxProperty = new FormTextboxProperty(
      'NCRAPINepEnterpriseUnit',
      translate.instant('datasourceConfiguration.NCRTLDM.nepEnterpriseUnit'),
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p87);

    //simphony export

    let p88: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyExportHost',
      translate.instant('datasourceConfiguration.simphonyExport.host'),
      2,
      0,
      null,
      12,
      true
    );
    properties.push(p88);

    let p89: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyExportLocationId',
      translate.instant('datasourceConfiguration.simphonyExport.locationId'),
      3,
      0,
      null,
      12,
      true
    );
    properties.push(p89);

    let p90: FormPasswordProperty = new FormPasswordProperty(
      'SimphonyExportPassword',
      translate.instant('datasourceConfiguration.simphonyExport.password'),
      4,
      0,
      null,
      12,
      true
    );
    properties.push(p90);

    let p91: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyExportUsername',
      translate.instant('datasourceConfiguration.simphonyExport.username'),
      5,
      0,
      null,
      12,
      true
    );
    properties.push(p91);

    let p92: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ModifyTimeCardByShift',
      translate.instant('datasourceConfiguration.simphony.modifyTimeCard'),
      9,
      0,
      null,
      12,
      true
    );
    p92.tooltip = 'Use only if the restaurant is open 24 hours (non-stop)';
    p92.rightLabel = true;
    properties.push(p92);

    let p93: FormTextboxProperty = new FormTextboxProperty(
      'SimphonyExportRevenueMasterCenterId',
      translate.instant(
        'datasourceConfiguration.simphonyExport.revenueMasterCenter'
      ),
      6,
      0,
      null,
      12,
      false
    );
    properties.push(p93);

    let p94: FormInputSwitchProperty = new FormInputSwitchProperty(
      'UseEmployeeIDAsOriginalID',
      translate.instant(
        'datasourceConfiguration.simphony.useEmployeeIDAsOriginalID'
      ),
      10,
      0,
      null,
      12,
      true
    );
    p94.rightLabel = true;
    properties.push(p94);

    let p95: FormPasswordProperty = new FormPasswordProperty(
      'SilverwareWebID',
      translate.instant('datasourceConfiguration.silverWare.webId'),
      6,
      0,
      null,
      12,
      false
    );
    properties.push(p95);

    let p96: FormPasswordProperty = new FormPasswordProperty(
      'SilverwareAccessToken',
      translate.instant('datasourceConfiguration.silverWare.accessToken'),
      6,
      0,
      null,
      12,
      false
    );
    properties.push(p96);

    let p97: FormTextboxProperty = new FormTextboxProperty(
      'SilverwareApiUrl',
      translate.instant('datasourceConfiguration.silverWare.apiUrl'),
      6,
      0,
      null,
      12,
      false
    );
    properties.push(p97);

    properties
      .filter((p) => p.name != 'PosID')
      .forEach((p) => (p.visible = false));

    return properties;
  }

  private static getDatasourceConfiguration_additionalSettings() {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'DefaultSyncButtonBehavior',
      'Default sync behavior',
      null,
      0,
      0,
      null,
      12,
      false
    );
    p1.visible = false;
    properties.push(p1);

    let p2: FormInputSwitchProperty = new FormInputSwitchProperty(
      'KeepLocalFiles',
      'Keep local files',
      1,
      0,
      null,
      12,
      false
    );
    p2.visible = false;
    properties.push(p2);

    //let p3: FormTextboxProperty = new FormTextboxProperty('Note', 'Note', 2, 0, 2, 12, false);
    //properties.push(p3);

    return properties;
  }

  private static getTimeProvider() {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'TimeProviderID',
      'Time provider',
      null,
      0,
      0,
      null,
      6,
      false
    );
    properties.push(p1);

    //-------Paycom
    let p2: FormTextboxProperty = new FormTextboxProperty(
      'PaycomCredentials.SID',
      'SID',
      1,
      0,
      null,
      6
    );
    p2.validate = true;
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'PaycomCredentials.Token',
      'Token',
      2,
      0,
      null,
      6
    );
    p3.validate = true;
    properties.push(p3);

    let p4: FormPasswordProperty = new FormPasswordProperty(
      'PaycomCredentials.LocationID',
      'LocationID',
      3,
      0,
      null,
      6
    );
    p4.validate = true;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'HarriLocationID',
      'HarriLocationID',
      1,
      0,
      null,
      6
    );
    p5.validate = true;
    p5.validationMessage = 'Harri location is mandatory';
    properties.push(p5);

    let p6: FormDropdownProperty = new FormDropdownProperty(
      'EETLaborLevelDefinitionName',
      'Labor Level Name',
      null,
      1,
      0,
      null,
      6
    );
    p6.validate = true;
    p6.validationMessage = 'Load definition name is mandatory';
    properties.push(p6);

    let p61: FormDropdownProperty = new FormDropdownProperty(
      'EETRegularPayCodeName',
      'Regular pay code name',
      null,
      1,
      1,
      null,
      6
    );
    p61.validate = true;
    p61.validationMessage = 'Regular pay code name is mandatory';
    properties.push(p61);

    let p7: FormDropdownProperty = new FormDropdownProperty(
      'EETLaborLevelDepartmantName',
      'Labor Level Department Name',
      null,
      2,
      0,
      null,
      6
    );
    properties.push(p7);

    let p71: FormDropdownProperty = new FormDropdownProperty(
      'EETTipsPayCodeName',
      'Tips pay code name',
      null,
      2,
      1,
      null,
      6
    );
    properties.push(p71);

    let p8: FormDropdownProperty = new FormDropdownProperty(
      'EETHyperFindQueryName',
      'Hyper Find Query Name',
      null,
      3,
      0,
      null,
      6
    );
    p8.validate = true;
    p8.validationMessage = 'Hyper Find Query Name is mandatory';
    properties.push(p8);

    let p81: FormDropdownProperty = new FormDropdownProperty(
      'EETOvertimePayCodeName',
      'Overtime pay code name',
      null,
      3,
      1,
      null,
      6
    );
    properties.push(p81);

    let p9: FormPasswordProperty = new FormPasswordProperty(
      'HotSchedulesCredentials.Username',
      'Username',
      1,
      0,
      null,
      6
    );
    p9.validate = true;
    p9.validationMessage = 'Username is mandatory';
    properties.push(p9);

    let p10: FormPasswordProperty = new FormPasswordProperty(
      'HotSchedulesCredentials.Password',
      'Password',
      2,
      0,
      null,
      6
    );
    p10.validate = true;
    p10.validationMessage = 'Password is mandatory';
    properties.push(p10);

    let p11: FormTextboxProperty = new FormTextboxProperty(
      'HotSchedulesCredentials.Namespace',
      'Namespace',
      3,
      0,
      null,
      6
    );
    p11.validate = true;
    p11.validationMessage = 'Namespace is mandatory';
    properties.push(p11);

    let p12: FormNumberProperty = new FormNumberProperty(
      'HotSchedulesCredentials.ConceptID',
      'ConceptID',
      'decimal',
      0,
      0,
      4,
      0,
      null,
      6
    );
    p12.formatNumber = false;
    p12.validate = true;
    p12.validationMessage = 'ConceptID is mandatory';
    properties.push(p12);

    let p13: FormNumberProperty = new FormNumberProperty(
      'HotSchedulesCredentials.StoreID',
      'StoreID',
      'decimal',
      0,
      0,
      5,
      0,
      null,
      6
    );
    p13.formatNumber = false;
    p13.validate = true;
    p13.validationMessage = 'StoreID is mandatory';
    properties.push(p13);

    let p14: FormInputSwitchProperty = new FormInputSwitchProperty(
      'HotSchedulesCredentials.SyncDataToHS',
      'Sync data to HS',
      6,
      0,
      null,
      6
    );
    p14.validate = false;
    properties.push(p14);

    let p15: FormTextboxProperty = new FormTextboxProperty(
      'HotSchedulesCredentials.Email',
      'Email',
      7,
      0,
      null,
      6
    );
    p15.validateAllowNull = true;
    p15.validationRegEx = regExLib.regex_Email;
    p15.validate = true;
    p15.tooltip = 'In case of several email addresses, separate them with ;';
    properties.push(p15);

    let p16: FormTextboxProperty = new FormTextboxProperty(
      'HotSchedulesCredentials.BaseHSUrl',
      'Base HS Url',
      8,
      0,
      null,
      6
    );
    p16.validate = false;
    properties.push(p16);

    let p23: FormTextboxProperty = new FormTextboxProperty(
      'CustomerSevenShiftsCredentials.CompanyId',
      'CompanyId',
      1,
      0,
      null,
      6
    );
    p23.validate = true;
    p23.readonly = false;
    properties.push(p23);

    let p24: FormTextboxProperty = new FormTextboxProperty(
      'CustomerSevenShiftsCredentials.CompanyGuid',
      'Company Guid',
      2,
      0,
      null,
      6
    );
    p24.validate = true;
    p24.readonly = false;
    properties.push(p24);

    let p25: FormTextboxProperty = new FormTextboxProperty(
      'CustomerSevenShiftsCredentials.LocationID',
      'Location ID',
      3,
      0,
      null,
      6
    );
    p25.validate = true;
    p25.readonly = false;
    properties.push(p25);

    let p26: FormPasswordProperty = new FormPasswordProperty(
      'CustomerSevenShiftsCredentials.AccessToken',
      'Access Token',
      4,
      0,
      null,
      6
    );
    p26.autocompleteOff = true;
    p26.validate = true;
    p26.readonly = false;
    properties.push(p26);

    let p27: FormTextboxProperty = new FormTextboxProperty(
      'CustomerSevenShiftsCredentials.ClientId',
      'Client Id',
      5,
      0,
      null,
      6
    );
    p27.validate = true;
    p27.readonly = false;
    properties.push(p27);

    let p28: FormTextboxProperty = new FormTextboxProperty(
      'CustomerSevenShiftsCredentials.ClientSecret',
      'Client Secret',
      6,
      0,
      null,
      6
    );
    p28.validate = true;
    p28.readonly = false;
    p28.autocompleteOff = true;
    properties.push(p28);

    let p29: FormTextboxProperty = new FormTextboxProperty(
      'CustomerSevenShiftsCredentials.BaseUrl',
      'Base Url',
      7,
      0,
      null,
      6
    );
    p29.validate = true;
    p29.readonly = false;
    properties.push(p29);

    return properties;
  }

  private static getGlobalConfiguration() {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'MinEmployeeTime',
      'Tip Eligibility time',
      0,
      0,
      0,
      6
    );
    p1.tooltip =
      'Min time required employee to be on shift to get shares from pool';
    properties.push(p1);

    let p6: FormInputSwitchProperty = new FormInputSwitchProperty(
      'UseTippableAsAlohaCheckOwner',
      'Use tippable Employee as owner',
      0,
      1,
      0,
      6
    );
    properties.push(p6);

    let p2: FormInputSwitchProperty = new FormInputSwitchProperty(
      'UseDECTIPSForAloha',
      'Use Aloha DEC TIPS ',
      1,
      1,
      0,
      6
    );
    properties.push(p2);

    let p3: FormInputSwitchProperty = new FormInputSwitchProperty(
      'EmployeeRetainsAdditionalTips',
      'All jobs retain additional tips',
      1,
      0,
      0,
      6
    );
    p3.tooltip =
      'If enabled system will ignore individual job configuration for this option';
    properties.push(p3);

    let p22: FormTimeProperty = new FormTimeProperty(
      'DoNotAllowCloseDateBefore',
      'Do not allow Close Date before',
      2,
      0,
      0,
      6,
      false,
      false,
      true,
      false
    );
    p22.readonly = false;
    p22.tooltip =
      'If this setting is configured and enabled, close date will not be allowed before given LOCAL time for current business date.';
    properties.push(p22);

    let p23: FormInputSwitchProperty = new FormInputSwitchProperty(
      'IncludePaidBreaks',
      'Tip - Include paid breaks',
      2,
      1,
      0,
      6
    );
    p23.tooltip =
      'Include paid break time when calculating employee total time for shift or for check serve time';
    p23.readonly = false;
    properties.push(p23);

    let p5: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ShowEmployeeFromPool',
      'Show pool employees on Tip out Analytics report',
      3,
      0,
      0,
      6
    );
    properties.push(p5);

    let p21: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ForceAlohaShifts',
      'Force aloha shifts',
      3,
      1,
      0,
      6
    );
    properties.push(p21);

    let p7: FormNumberProperty = new FormNumberProperty(
      'TrackNegativeAmountsNumOfDays',
      'Days to track negative tip earnings',
      'decimal',
      0,
      0,
      4,
      0,
      0,
      6
    );
    p7.formatNumber = false;
    p7.readonly = false;
    p7.min = 0;
    p7.max = 60;

    p7.tooltip =
      "Enter the desired number of days (0 - 60) for tracking negative amounts and recoupment. \n Mandatory if the 'Pay Me' or 'Tip Share - Mobile app' function is used. \n Leave the field blank if no card loading or specified functions. \n * Re - sync data after the first setup";
    properties.push(p7);

    let p4: FormInputSwitchProperty = new FormInputSwitchProperty(
      'UseTippableNetSales',
      'Use tippable Net sales option',
      4,
      1,
      0,
      6
    );
    properties.push(p4);

    let p9: FormInputSwitchProperty = new FormInputSwitchProperty(
      'TipOutShareFromServiceChargeOnly',
      'Tip put from SVC only',
      5,
      0,
      0,
      6
    );
    properties.push(p9);

    let p18: FormInputSwitchProperty = new FormInputSwitchProperty(
      'DoNotAllowNegativeTipOut',
      'Do not allow negative tip out for employees',
      5,
      1,
      0,
      6
    );
    p18.readonly = false;
    properties.push(p18);

    let p11: FormInputSwitchProperty = new FormInputSwitchProperty(
      'CompSalesIsNetSales',
      'Add Comps to NET sales',
      6,
      0,
      0,
      6
    );
    properties.push(p11);

    let p8: FormInputSwitchProperty = new FormInputSwitchProperty(
      'GiftCardIsCreditCard',
      'Treat Gift Card as Credit Card Payment ',
      6,
      1,
      0,
      6
    );
    properties.push(p8);

    let p14: FormInputSwitchProperty = new FormInputSwitchProperty(
      'TransactionsByTimePeriods',
      'Capture transactions by time periods',
      7,
      0,
      0,
      6
    );
    properties.push(p14);

    let p10: FormInputSwitchProperty = new FormInputSwitchProperty(
      'HouseAccountIsCreditCard',
      'Treat House account as Credit Card Payment',
      7,
      1,
      0,
      6
    );
    properties.push(p10);

    let p15: FormInputSwitchProperty = new FormInputSwitchProperty(
      'CalculationByCheckTime',
      'Distribution Calculation by Check time',
      8,
      0,
      0,
      6
    );
    properties.push(p15);

    let p12: FormDropdownProperty = new FormDropdownProperty(
      'CollectToastDeliveryOrdersType',
      'Toast Delivery options',
      null,
      8,
      1,
      0,
      6
    );
    properties.push(p12);

    let p151: FormNumberProperty = new FormNumberProperty(
      'MaxCheckDuration',
      'Max check duration',
      'decimal',
      0,
      0,
      9,
      0,
      0,
      6
    );
    p151.validate = true;
    p151.validateAllowNull = true;
    p151.validationRegEx = regExLib.regex_PositiveInteger;
    p151.validationMessage = 'Max check duration can be only positive number.';
    properties.push(p151);

    let p13: FormInputSwitchProperty = new FormInputSwitchProperty(
      'IgnoreSales',
      'Ignore Sales for missing time cards',
      9,
      1,
      0,
      6
    );
    properties.push(p13);

    let p16: FormInputSwitchProperty = new FormInputSwitchProperty(
      'CalculationViaLambda',
      'Distribution Calculation via Lambda function',
      10,
      0,
      0,
      6
    );
    p16.readonly = true;
    properties.push(p16);

    let p19: FormInputSwitchProperty = new FormInputSwitchProperty(
      'IgnoreAlohaSVCFeeReduction',
      'Ignore Aloha SVC fee reduction',
      10,
      1,
      0,
      6
    );
    p19.readonly = false;
    properties.push(p19);

    let p17: FormInputSwitchProperty = new FormInputSwitchProperty(
      'NoDistributionWithZeroCCTips',
      'No distribution from Zero CC Tips transactions',
      11,
      0,
      0,
      6
    );
    p17.readonly = false;
    properties.push(p17);

    let p20: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'MinimumWage',
      'Minimum Wage',
      2,
      2,
      11,
      1,
      0,
      6
    );
    p20.readonly = false;
    properties.push(p20);

    //[p2, p3, p4, p5, p6, p8, p9, p10, p11, p13].forEach(p => p.leftLabel = true);

    return properties;
  }

  private static getGlobalConfigurationContinuesPayment() {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ConPaymentEnableTips',
      'For Tips',
      0,
      0,
      null,
      6,
      false
    );
    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'ConPaymentEnableTipsDateFrom',
      'From date',
      false,
      0,
      1,
      null,
      6,
      false
    );
    p2.readOnly = true;
    properties.push(p2);

    let p11: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ConPaymentEnableWages',
      'For Wages',
      1,
      0,
      null,
      6,
      false
    );
    properties.push(p11);

    let p21: FormDateProperty = new FormDateProperty(
      'ConPaymentEnableWagesDateFrom',
      'From date',
      false,
      1,
      1,
      null,
      6,
      false
    );
    p21.readOnly = true;
    properties.push(p21);

    let p12: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ConPaymentEnableCommissions',
      'For Commissions',
      2,
      0,
      null,
      6,
      false
    );
    properties.push(p12);

    let p22: FormDateProperty = new FormDateProperty(
      'ConPaymentEnableCommissionsDateFrom',
      'From date',
      false,
      2,
      1,
      null,
      6,
      false
    );
    p22.readOnly = true;
    properties.push(p22);

    return properties;
  }

  private static getNewPool() {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'PoolName',
      'Name',
      0,
      0,
      null,
      12,
      true
    );
    p1.validationMessage = 'Name is mandatory';
    properties.push(p1);

    return properties;
  }

  private static getPayCardCardFlex(translate: TranslateService) {
    let properties: FormProperty[] = [];

    this.setLoadCardAllowedProperties(properties, translate);
    return properties;
  }

  private static getPayCardGCC(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.Username'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.validationMessage =
      translate.instant('payCard.Username') + ' is mandatory';
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.Password'),
      1,
      2,
      null,
      this.DefaultColumnSpan,
      true
    );
    p3.validationMessage =
      translate.instant('payCard.Password') + ' is mandatory';
    properties.push(p3);

    let p4: FormPasswordProperty = new FormPasswordProperty(
      'API_KEY',
      translate.instant('payCard.ClientID'),
      3,
      0,
      null,
      this.DefaultColumnSpan * 2,
      false
    );
    properties.push(p4);

    let p5: FormPasswordProperty = new FormPasswordProperty(
      'APISecret',
      translate.instant('payCard.ClientSecret'),
      4,
      0,
      null,
      this.DefaultColumnSpan * 2,
      false
    );

    properties.push(p5);
    return properties;
  }

  private static getPayCardADP(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.ClientID'),
      3,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.validationMessage =
      translate.instant('payCard.ClientID') + ' is mandatory';
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.ClientSecret'),
      3,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    p3.validationMessage =
      translate.instant('payCard.ClientSecret') + ' is mandatory';
    properties.push(p3);

    let p4: FormAutocompleteProperty = new FormAutocompleteProperty(
      'LocationID',
      translate.instant('payCard.LocationID'),
      null,
      4,
      0,
      null,
      this.DefaultColumnSpan,
      false,
      true,
      'blank',
      'fas fa-search',
      undefined,
      true,
      'LocationID'
    ); //, 'label');
    properties.push(p4);

    let p5: FormAutocompleteProperty = new FormAutocompleteProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      null,
      4,
      1,
      null,
      this.DefaultColumnSpan,
      false,
      true,
      'blank',
      'fas fa-search',
      undefined,
      true,
      'AccountNumber'
    );
    properties.push(p5);

    return properties;
  }

  private static getPayCardRapidCard(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p5: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      1,
      0,
      null,
      this.DefaultColumnSpan * 1.5,
      true
    );
    p5.validationMessage =
      translate.instant('payCard.AccountNumber') + ' is mandatory';
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumberPassCode',
      translate.instant('payCard.AccountNumberPassCode'),
      1,
      2,
      null,
      this.DefaultColumnSpan * 1.5,
      true
    );
    p6.validationMessage =
      translate.instant('payCard.AccountNumberPassCode') + ' is mandatory';
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'CertCard',
      translate.instant('payCard.CertifiedCard'),
      2,
      0,
      null,
      this.DefaultColumnSpan * 1.5,
      true
    );
    p7.validationMessage =
      translate.instant('payCard.CertifiedCard') + ' is mandatory';
    properties.push(p7);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'CertCardPassCode',
      translate.instant('payCard.CertifiedCardPassCode'),
      2,
      1,
      null,
      this.DefaultColumnSpan * 1.5,
      true
    );
    p8.validationMessage =
      translate.instant('payCard.CertifiedCardPassCode') + ' is mandatory';
    properties.push(p8);

    return properties;
  }

  private static getPayCardMoneyNetwork(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p0: FormInputSwitchProperty = new FormInputSwitchProperty(
      'MoneyNetworkCrossFunding',
      translate.instant('payCard.crossFunding'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      false
    );
    properties.push(p0);

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'LoadByAccountNum',
      translate.instant('payCard.loadByAccountNum'),
      1,
      2,
      null,
      this.DefaultColumnSpan * 1.5,
      false
    );
    properties.push(p1);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      2,
      3,
      null,
      this.DefaultColumnSpan,
      true
    );
    p5.validate = true;
    p5.validationMessage =
      translate.instant('payCard.AccountNumber') + ' is mandatory';
    properties.push(p5);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.Username'),
      2,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.validationMessage =
      translate.instant('payCard.Username') + ' is mandatory';
    p2.autocompleteOff = true;
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.Password'),
      2,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    p3.validationMessage =
      translate.instant('payCard.Password') + ' is mandatory';
    p3.autocompleteOff = true;
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'ServiceURL',
      translate.instant('payCard.ServiceURL'),
      5,
      0,
      null,
      this.DefaultColumnSpan * 3,
      true
    );
    p4.validationMessage =
      translate.instant('payCard.ServiceURL') + ' is mandatory';
    properties.push(p4);
    return properties;
  }

  private static getPayCardUSBank(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.Username'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.autocompleteOff = true;
    p2.validationMessage =
      translate.instant('payCard.Username') + ' is mandatory';
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.Password'),
      1,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    p3.autocompleteOff = true;
    p3.validationMessage =
      translate.instant('payCard.Password') + ' is mandatory';
    properties.push(p3);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      1,
      2,
      null,
      this.DefaultColumnSpan,
      true
    );
    p5.validationMessage =
      translate.instant('payCard.AccountNumber') + ' is mandatory';
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumberPassCode',
      translate.instant('payCard.AccountNumberPassCode'),
      2,
      0,
      null,
      this.DefaultColumnSpan * 2,
      true
    );
    p6.validationMessage =
      translate.instant('payCard.AccountNumberPassCode') + ' is mandatory';
    properties.push(p6);

    //COLUMN 1

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'CertCard',
      translate.instant('payCard.CertifiedCard'),
      2,
      2,
      null,
      this.DefaultColumnSpan,
      true
    );
    p7.validationMessage =
      translate.instant('payCard.CertifiedCard') + ' is mandatory';
    properties.push(p7);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'CertCardPassCode',
      translate.instant('payCard.CertifiedCardPassCode'),
      3,
      0,
      null,
      this.DefaultColumnSpan * 2,
      true
    );
    p8.validationMessage =
      translate.instant('payCard.CertifiedCardPassCode') + ' is mandatory';
    properties.push(p8);

    let p9: FormDropdownProperty = new FormDropdownProperty(
      'USBankParam7',
      translate.instant('payCard.CardMappingUseEmployeeID'),
      null,
      3,
      1,
      null,
      this.DefaultColumnSpan,
      false
    );
    //p9.validationMessage = translate.instant('payCard.CardMappingUseEmployeeID') + ' is mandatory';
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'ServiceURL',
      translate.instant('payCard.ServiceURL'),
      4,
      0,
      0,
      this.DefaultColumnSpan * 3,
      false
    );
    p10.validationMessage =
      translate.instant('payCard.ServiceURL') + ' is mandatory';
    properties.push(p10);

    let p11: FormPasswordProperty = new FormPasswordProperty(
      'API_KEY',
      'Client ID',
      5,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p11.autocompleteOff = true;
    p11.validationMessage = 'Client ID is mandatory';
    properties.push(p11);

    let p12: FormPasswordProperty = new FormPasswordProperty(
      'APISecret',
      'Client secret',
      5,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    p12.autocompleteOff = true;
    p12.validationMessage = 'Client secret is mandatory';
    properties.push(p12);

    let p13: FormTextboxProperty = new FormTextboxProperty(
      'AuthURL',
      translate.instant('payCard.AuthURL'),
      6,
      0,
      null,
      this.DefaultColumnSpan * 3,
      true
    );
    p13.validationMessage =
      translate.instant('payCard.AuthURL') + ' is mandatory';
    properties.push(p13);
    return properties;
  }

  private static getPayCardNetSpend(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p1: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.Username'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p1);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.Password'),
      1,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      translate.instant('payCard.LocationID'),
      1,
      2,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p3);
    return properties;
  }

  private static getPayCardCardProvider(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'CardProvider',
      translate.instant('payCard.selectCardProvider'),
      null,
      0,
      0,
      null,
      6,
      false
    );
    properties.push(p1);

    return properties;
  }

  private static getPayCardCardProvider_properties(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p2: FormInputSwitchProperty = new FormInputSwitchProperty(
      'JobActive',
      'Load card automatically ',
      0,
      0,
      0,
      6
    );
    p2.readonly = true;
    properties.push(p2);

    let options = [];
    //options.push({label:'',value:undefined});
    options.push({ label: 'After auto close date', value: 'FundCards' });
    options.push({
      label: 'Force load - even if date is closed',
      value: 'ForceFundCards',
    });
    let p2_3: FormDropdownProperty = new FormDropdownProperty(
      'JobKey',
      'Load options',
      null,
      1,
      0,
      0,
      6
    );
    p2_3.options = options;
    p2_3.autoDisplayFirstDisabled = true;
    p2_3.readonly = true;
    properties.push(p2_3);

    let p2_1: FormTextboxProperty = new FormTextboxProperty(
      'Subscribers[0].Email',
      'Register 1st email for notification ',
      1,
      1,
      0,
      6
    );
    p2_1.readonly = true;
    properties.push(p2_1);

    let p3: FormTimeProperty = new FormTimeProperty(
      'UTCTime',
      'UTC Time',
      2,
      0,
      0,
      3
    );
    p3.withSeconds = false;
    p3.readonly = true;
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'LocalTime',
      'Location Time',
      2,
      1,
      0,
      3
    );
    p4.readonly = true;
    properties.push(p4);

    let p5_1: FormTextboxProperty = new FormTextboxProperty(
      'Subscribers[1].Email',
      'Register 2nd email for notification ',
      2,
      1,
      0,
      6
    );
    p5_1.readonly = true;
    properties.push(p5_1);

    let p5: FormNumberProperty = new FormNumberProperty(
      'DelayDays',
      'Delay days',
      'decimal',
      null,
      null,
      3,
      0,
      0,
      6,
      false
    );
    p5.min = 0;
    p5.max = 366;
    p5.readonly = true;
    properties.push(p5);

    let p5_2: FormTextboxProperty = new FormTextboxProperty(
      'Subscribers[2].Email',
      'Register 3rd email for notification ',
      3,
      1,
      0,
      6
    );
    properties.push(p5_2);
    p5_2.readonly = true;
    return properties;
  }

  private static getPayCardTabaPay(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p2: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      translate.instant('payCard.SubClientID'),
      1,
      0,
      null,
      this.DefaultColumnSpan * 2,
      false
    );
    p2.readonly = true;
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      1,
      2,
      null,
      this.DefaultColumnSpan,
      true
    );
    p3.validationMessage =
      translate.instant('payCard.AccountNumber') + ' is mandatory';
    properties.push(p3);
    return properties;
  }

  private static getPayCardBranch(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p2: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      translate.instant('payCard.OrganizationId'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.validationMessage =
      translate.instant('payCard.OrganizationId') + ' is mandatory';
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'API_KEY',
      translate.instant('payCard.apiKey'),
      1,
      1,
      null,
      this.DefaultColumnSpan * 1.5,
      true
    );
    p3.validationMessage =
      translate.instant('payCard.apiKey') + ' is mandatory';
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'ServiceURL',
      translate.instant('payCard.ServiceURL'),
      2,
      0,
      null,
      this.DefaultColumnSpan * 3,
      true
    );
    p4.validationMessage =
      translate.instant('payCard.ServiceURL') + ' is mandatory';
    properties.push(p4);

    return properties;
  }

  private static getPayCardFakeCard(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    return properties;
  }

  private static getPayCardInPrepaid(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p1: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.Username'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p1);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.Password'),
      1,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      translate.instant('payCard.LocationID'),
      2,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p3);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'AdditionalData',
      translate.instant('payCard.ProgramIds'),
      2,
      1,
      null,
      this.DefaultColumnSpan * 2,
      false
    );
    properties.push(p5);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'ServiceURL',
      translate.instant('payCard.ServiceURL'),
      3,
      0,
      1,
      this.DefaultColumnSpan * 3,
      true
    );
    p4.validationMessage =
      translate.instant('payCard.ServiceURL') + ' is mandatory';
    properties.push(p4);

    return properties;
  }

  private static getPayCardDailyPay(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'API_KEY',
      translate.instant('payCard.apiKey'),
      1,
      0,
      0,
      this.DefaultColumnSpan,
      true
    );
    p1.validationMessage =
      translate.instant('payCard.apiKey') + ' is mandatory';
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'ServiceURL',
      translate.instant('payCard.ServiceURL'),
      1,
      0,
      0,
      this.DefaultColumnSpan * 3,
      true
    );
    p2.validationMessage =
      translate.instant('payCard.ServiceURL') + ' is mandatory';
    properties.push(p2);
    return properties;
  }

  private static getPayCardComData(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);
    let p1: FormPasswordProperty = new FormPasswordProperty(
      'ClientID',
      translate.instant('payCard.Username'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p1.validationMessage =
      translate.instant('payCard.Username') + ' is mandatory';
    properties.push(p1);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      translate.instant('payCard.Password'),
      1,
      1,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.validationMessage =
      translate.instant('payCard.Password') + ' is mandatory';
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      1,
      2,
      null,
      this.DefaultColumnSpan,
      true
    );
    p3.validationMessage =
      translate.instant('payCard.AccountNumber') + ' is mandatory';
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'ServiceURL',
      translate.instant('payCard.ServiceURL'),
      2,
      0,
      null,
      this.DefaultColumnSpan * 3,
      true
    );
    p4.validationMessage =
      translate.instant('payCard.ServiceURL') + ' is mandatory';
    properties.push(p4);

    return properties;
  }

  private static getPayroll(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'PayrollTypeID',
      translate.instant('payroll.selectPayrollProvider'),
      null,
      0,
      0,
      null,
      4,
      false
    );
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'HarriLocationID',
      translate.instant('payroll.harriLocationID'),
      1,
      0,
      null,
      4,
      false
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'PayrollExportFileName',
      translate.instant('payroll.exporFileName'),
      2,
      0,
      null,
      4,
      false
    );
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'PayrollExportFirstLineValue',
      translate.instant('payroll.exporFirsLinetValue'),
      3,
      0,
      null,
      4,
      false
    );
    properties.push(p4);

    let p5: FormInputSwitchProperty = new FormInputSwitchProperty(
      'PayrollExportNoHeader',
      translate.instant('payroll.noHeader'),
      4,
      0,
      null,
      4,
      false
    );
    properties.push(p5);

    let p6: FormDropdownProperty = new FormDropdownProperty(
      'PayingFrequencyID',
      translate.instant('payroll.paymentFrequency'),
      null,
      5,
      0,
      null,
      4,
      false
    );
    properties.push(p6);

    let p7: FormNumberProperty = new FormNumberProperty(
      'PFChangsLocationId',
      translate.instant('payroll.locationId'),
      'decimal',
      0,
      0,
      2,
      0,
      null,
      6,
      false
    );
    p7.visible = false;
    properties.push(p7);

    let p9: FormTextboxProperty = new FormTextboxProperty(
      'PFChangsEndpoint',
      translate.instant('payroll.endpoint'),
      3,
      0,
      null,
      6,
      false
    );
    p9.visible = false;
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'PFChangsToken',
      translate.instant('payroll.token'),
      4,
      0,
      3,
      6,
      false
    );
    p10.visible = false;
    properties.push(p10);

    //let p11: FormTextboxProperty = new FormTextboxProperty(
    //  'DailyPayApiKey',
    //  translate.instant('payroll.apiKey'),
    //  3,
    //  0,
    //  null,
    //  6,
    //  false
    //);
    //p11.visible = false;
    //properties.push(p11);

    //let p12: FormTextboxProperty = new FormTextboxProperty(
    //  'DailyPayBaseUrl',
    //  translate.instant('payroll.baseUrl'),
    //  4,
    //  0,
    //  null,
    //  6,
    //  false
    //);
    //p12.visible = false;
    //properties.push(p12);

    //let p121: FormInputSwitchProperty = new FormInputSwitchProperty(
    //  'DailyPayForceCloseDate',
    //  translate.instant('payroll.forceCloseDate'),
    //  5,
    //  0,
    //  null,
    //  6,
    //  false
    //);
    //p121.visible = false;
    //properties.push(p121);

    let p13: FormTextboxProperty = new FormTextboxProperty(
      'FuegoFileName',
      translate.instant('payroll.fileName'),
      2,
      0,
      null,
      6,
      false
    );
    p13.visible = false;
    properties.push(p13);

    let p14: FormTextboxProperty = new FormTextboxProperty(
      'FuegoSFTPAddress',
      translate.instant('payroll.address'),
      2,
      1,
      null,
      6,
      false
    );
    p14.visible = false;
    properties.push(p14);

    let p15: FormTextboxProperty = new FormTextboxProperty(
      'FuegoSFTPPort',
      translate.instant('payroll.port'),
      3,
      0,
      null,
      6,
      false
    );
    p15.visible = false;
    properties.push(p15);

    let p16: FormPasswordProperty = new FormPasswordProperty(
      'FuegoSFTPPassword',
      translate.instant('payroll.password'),
      3,
      1,
      null,
      6,
      false
    );
    p16.visible = false;
    properties.push(p16);

    let p17: FormTextboxProperty = new FormTextboxProperty(
      'FuegoSFTPUsername',
      translate.instant('payroll.username'),
      4,
      0,
      null,
      6,
      false
    );
    p17.visible = false;
    properties.push(p17);

    let p18: FormTextboxProperty = new FormTextboxProperty(
      'FuegoSFTPFolder',
      translate.instant('payroll.folder'),
      4,
      1,
      null,
      6,
      false
    );
    p18.visible = false;
    properties.push(p18);

    //let p19: FormTextboxProperty = new FormTextboxProperty(
    //  'FuegoAPIBaseUrl',
    //  translate.instant('payroll.baseUrl'),
    //  1,
    //  0,
    //  null,
    //  6,
    //  true
    //);
    //p19.visible = false;
    //properties.push(p19);

    //let p20: FormTextboxProperty = new FormTextboxProperty(
    //  'FuegoAPIOrganizationID',
    //  translate.instant('payroll.organizationId'),
    //  2,
    //  0,
    //  null,
    //  6,
    //  true
    //);
    //p20.visible = false;
    //properties.push(p20);

    let p23: FormTextboxProperty = new FormTextboxProperty(
      'CsvToSftpFileName',
      translate.instant('payroll.fileName'),
      2,
      0,
      null,
      6,
      false
    );
    p23.visible = false;
    properties.push(p23);

    let p24: FormTextboxProperty = new FormTextboxProperty(
      'CsvToSftpAddress',
      translate.instant('payroll.address'),
      2,
      1,
      null,
      6,
      false
    );
    p24.visible = false;
    properties.push(p24);

    let p25: FormTextboxProperty = new FormTextboxProperty(
      'CsvToSftpPort',
      translate.instant('payroll.port'),
      3,
      0,
      null,
      6,
      false
    );
    p25.autocompleteOff = true;
    p25.visible = false;
    properties.push(p25);

    let p26: FormPasswordProperty = new FormPasswordProperty(
      'CsvToSftpPassword',
      translate.instant('payroll.password'),
      3,
      1,
      null,
      6,
      false
    );
    p26.autocompleteOff = true;
    p26.visible = false;
    properties.push(p26);

    let p27: FormTextboxProperty = new FormTextboxProperty(
      'CsvToSftpUsername',
      translate.instant('payroll.username'),
      4,
      0,
      null,
      6,
      false
    );
    p27.visible = false;
    properties.push(p27);

    let p28: FormTextboxProperty = new FormTextboxProperty(
      'CsvToSftpFolder',
      translate.instant('payroll.folder'),
      4,
      1,
      null,
      6,
      false
    );
    p28.visible = false;
    properties.push(p28);

    let p29: FormTextboxProperty = new FormTextboxProperty(
      'RDMCEndpoint',
      translate.instant('payroll.rdmcEndpoint'),
      1,
      0,
      null,
      6,
      false
    );
    p29.visible = false;
    properties.push(p29);

    let p31: FormTextboxProperty = new FormTextboxProperty(
      'RDMCUsername',
      translate.instant('payroll.rdmcUsername'),
      3,
      0,
      null,
      6,
      false
    );
    p31.visible = false;
    properties.push(p31);

    let p32: FormTextboxProperty = new FormTextboxProperty(
      'RDMCPassword',
      translate.instant('payroll.rdmcPassword'),
      4,
      0,
      null,
      6,
      false
    );
    p32.visible = false;
    properties.push(p32);

    let p33: FormTextboxProperty = new FormTextboxProperty(
      'RDMCReportID',
      translate.instant('payroll.rdmcReportID'),
      5,
      0,
      null,
      6,
      false
    );
    p33.visible = false;
    properties.push(p33);

    let p34: FormDropdownProperty = new FormDropdownProperty(
      'RDMCAuthenticationType',
      translate.instant('payroll.rdmcAuthenticationType'),
      null,
      2,
      0,
      null,
      6,
      false
    );
    p34.visible = false;
    p34.validationMessage = 'Authentication type is mandatory';
    p34.options = [];
    p34.options.push({
      label: 'Username and password',
      value: 'UsernameAndPassword',
    });
    p34.options.push({ label: 'Key', value: 'Key' });
    properties.push(p34);

    let p35: FormTextboxProperty = new FormTextboxProperty(
      'RDMCUnitID',
      translate.instant('payroll.rdmcUnitID'),
      6,
      0,
      null,
      6,
      false
    );
    p35.visible = false;
    properties.push(p35);

    let p36: FormDropdownProperty = new FormDropdownProperty(
      'RDMCPayMemoTransactionsVersion',
      translate.instant('payroll.rdmcPayMemoTransactionsVersion'),
      null,
      7,
      0,
      null,
      6,
      false
    );
    p36.visible = false;
    p36.validationMessage = 'PayMemo transaction version is mandatory';
    p36.options = [];
    p36.options.push({ label: 'V1', value: '1' });
    p36.options.push({ label: 'V2', value: '2' });
    properties.push(p36);

    let p37: FormTextboxProperty = new FormTextboxProperty(
      'RDMCEmployeeMappingField',
      translate.instant('payroll.rdmcEmployeeMappingField'),
      8,
      0,
      null,
      6,
      false
    );
    p37.visible = false;
    properties.push(p37);

    return properties;
  }

  private static getPayroll_automatic(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'AutomaticAfterClose',
      translate.instant('payroll.automaticAfterClose'),
      1,
      0,
      null,
      6,
      false
    );
    properties.push(p1);
    p1.tooltip = translate.instant('payroll.automaticAfterCloseTooltip');
    p1.visible = false;

    let p14: FormInputSwitchProperty = new FormInputSwitchProperty(
      'AutomaticActive',
      translate.instant('payroll.automaticActive'),
      2,
      0,
      null,
      6,
      false
    );
    properties.push(p14);
    p14.visible = false;

    let p10: FormTimeProperty = new FormTimeProperty(
      'JobScheduleTime',
      'UTC Time',
      3,
      0,
      0,
      3
    );
    p10.withSeconds = false;
    p10.visible = false;
    properties.push(p10);

    let p11: FormTextboxProperty = new FormTextboxProperty(
      'LocalTime',
      'Location Time',
      3,
      1,
      0,
      3
    );
    p11.readonly = true;
    p11.visible = false;
    properties.push(p11);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'Email_1',
      translate.instant('payroll.email_first'),
      1,
      1,
      null,
      6,
      false
    );
    p8.visible = false;
    properties.push(p8);

    let p12: FormTextboxProperty = new FormTextboxProperty(
      'Email_2',
      translate.instant('payroll.email_second'),
      2,
      1,
      null,
      6,
      false
    );
    p12.visible = false;
    properties.push(p12);

    let p13: FormTextboxProperty = new FormTextboxProperty(
      'Email_3',
      translate.instant('payroll.email_third'),
      3,
      3,
      null,
      6,
      false
    );
    p13.visible = false;
    properties.push(p13);

    return properties;
  }

  private static getPricingAndBilling(translate: TranslateService) {
    let properties: FormProperty[] = [];

    return properties;
  }

  private static getPricing(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormInfoLabelProperty = new FormInfoLabelProperty(
      'Pricing.LastInvoicedDate',
      translate.instant('pricingAndBilling.lastInvoicedDate'),
      0,
      0,
      null,
      12,
      false
    );
    properties.push(p0);

    let p1: FormDateProperty = new FormDateProperty(
      'Pricing.DateFrom',
      translate.instant('pricingAndBilling.effectiveDateFrom'),
      true,
      1,
      0,
      null,
      4,
      false,
      false
    );
    properties.push(p1);

    let p11: FormDateProperty = new FormDateProperty(
      'Pricing.EffectiveUntil',
      translate.instant('pricingAndBilling.effectiveDateUntil'),
      false,
      1,
      1,
      null,
      4,
      false,
      true
    );
    properties.push(p11);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'Pricing.FK_PriceList',
      translate.instant('pricingAndBilling.setPricingFrom'),
      null,
      2,
      0,
      null,
      12,
      false
    );
    properties.push(p2);

    let p3: FormDropdownProperty = new FormDropdownProperty(
      'Pricing.EditionCode',
      translate.instant('pricingAndBilling.setCustomModel'),
      null,
      3,
      0,
      null,
      12,
      false
    );
    properties.push(p3);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'Pricing.PricingCategory',
      null,
      null,
      4,
      0,
      null,
      12,
      false
    );
    properties.push(p4);

    let p7: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'Pricing.Price',
      translate.instant('pricingAndBilling.price'),
      2,
      2,
      5,
      0,
      null,
      3,
      false
    );
    properties.push(p7);

    let p9: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'Pricing.AdminFee',
      translate.instant('pricingAndBilling.adminFee'),
      2,
      2,
      5,
      1,
      null,
      3,
      false
    );
    properties.push(p9);

    let p8: FormDropdownProperty = new FormDropdownProperty(
      'Pricing.FK_ST_4_ID',
      translate.instant('pricingAndBilling.model'),
      null,
      5,
      1,
      null,
      5,
      false
    );
    properties.push(p8);

    let p5: FormNumberProperty = new FormNumberProperty(
      'Pricing.MinimumEmployeeTip',
      translate.instant('pricingAndBilling.minimumEmployeeTip'),
      'decimal',
      2,
      2,
      6,
      0,
      null,
      4,
      false
    );
    properties.push(p5);

    let p6: FormNumberProperty = new FormNumberProperty(
      'Pricing.MinimumEmployeeTime',
      translate.instant('pricingAndBilling.minimumEmployeeTime'),
      'decimal',
      0,
      0,
      7,
      0,
      null,
      4,
      false
    );
    properties.push(p6);

    return properties;
  }

  private static getBillingInfo(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormDropdownProperty = new FormDropdownProperty(
      'BillingAndAdditionalInfo.SelectedId',
      translate.instant('pricingAndBilling.centralOfficeForBilling'),
      null,
      0,
      0,
      null,
      5,
      false
    );
    properties.push(p0);

    let p01: FormButtonProperty = new FormButtonProperty(
      'BillingAndAdditionalInfo.AddLocation',
      '',
      'pi pi-plus-circle',
      0,
      1,
      null,
      1,
      false
    );
    properties.push(p01);

    //let p1: FormTextboxProperty = new FormTextboxProperty('BillingAndAdditionalInfo.Details.GroupName', translate.instant('pricingAndBilling.companyGroup'), 1, 0, null, 6, false);
    //properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.OutletName',
      translate.instant('pricingAndBilling.locationName'),
      1,
      0,
      null,
      6,
      true
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.Street',
      translate.instant('pricingAndBilling.addresLine#1'),
      2,
      0,
      null,
      6,
      true
    );
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.StreetNumber',
      translate.instant('pricingAndBilling.addresLine#2'),
      3,
      0,
      null,
      6,
      false
    );
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.ZIP',
      translate.instant('pricingAndBilling.zip'),
      4,
      0,
      null,
      2,
      true
    );
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.City',
      translate.instant('pricingAndBilling.city'),
      4,
      2,
      null,
      2,
      true
    );
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.State',
      translate.instant('pricingAndBilling.state'),
      4,
      4,
      null,
      2,
      true
    );
    properties.push(p7);

    //RIGHT COLUMN
    let p8: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.BillingContactName',
      translate.instant('pricingAndBilling.billingContactFirstName'),
      1,
      1,
      null,
      3,
      true
    );
    properties.push(p8);

    let p9: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.BillingContactLastName',
      translate.instant('pricingAndBilling.billingContactLastName'),
      1,
      2,
      null,
      3,
      true
    );
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'BillingAndAdditionalInfo.Details.BillingContactEmail',
      translate.instant('pricingAndBilling.billingContactEmail'),
      2,
      1,
      null,
      3,
      true,
      regExLib.regex_Email
    );
    properties.push(p10);

    let p11: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'BillingAndAdditionalInfo.Details.BillingContactNumber',
      translate.instant('pricingAndBilling.billingContactPhone'),
      2,
      2,
      null,
      3,
      true
    );
    properties.push(p11);

    let p12: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'BillingAndAdditionalInfo.Details.OutletMainNumber',
      translate.instant('pricingAndBilling.locationMainPhone'),
      3,
      1,
      null,
      6,
      false
    );
    properties.push(p12);

    return properties;
  }

  private static getNewLocation(translate: TranslateService) {
    let properties: FormProperty[] = [];

    //let p1: FormTextboxProperty = new FormTextboxProperty('GroupName', translate.instant('pricingAndBilling.companyGroup'), 0, 0, null, 6, true);
    //properties.push(p1);

    let p1: FormAutocompleteProperty = new FormAutocompleteProperty(
      'FK_CompanyGroup',
      translate.instant('pricingAndBilling.companyGroup'),
      null,
      0,
      0,
      null,
      6,
      false,
      true
    );
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'OutletName',
      translate.instant('pricingAndBilling.locationName'),
      1,
      0,
      null,
      6,
      true
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'Street',
      translate.instant('pricingAndBilling.addresLine#1'),
      2,
      0,
      null,
      6,
      true
    );
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'StreetNumber',
      translate.instant('pricingAndBilling.addresLine#2'),
      3,
      0,
      null,
      6,
      false
    );
    p4.maxLen = 20;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'ZIP',
      translate.instant('pricingAndBilling.zip'),
      4,
      0,
      null,
      2,
      true
    );
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'City',
      translate.instant('pricingAndBilling.city'),
      4,
      1,
      null,
      2,
      true
    );
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'State',
      translate.instant('pricingAndBilling.state'),
      4,
      2,
      null,
      2,
      true
    );
    properties.push(p7);

    //RIGHT COLUMN
    let p8: FormTextboxProperty = new FormTextboxProperty(
      'BillingContactName',
      translate.instant('pricingAndBilling.billingContactFirstName'),
      0,
      1,
      null,
      3,
      true
    );
    properties.push(p8);

    let p9: FormTextboxProperty = new FormTextboxProperty(
      'BillingContactLastName',
      translate.instant('pricingAndBilling.billingContactLastName'),
      0,
      2,
      null,
      3,
      true
    );
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'BillingContactEmail',
      translate.instant('pricingAndBilling.billingContactEmail'),
      1,
      1,
      null,
      3,
      true,
      regExLib.regex_Email
    );
    properties.push(p10);

    let p11: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'BillingContactNumber',
      translate.instant('pricingAndBilling.billingContactPhone'),
      1,
      2,
      null,
      3,
      true
    );
    properties.push(p11);

    let p12: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'OutletMainNumber',
      translate.instant('pricingAndBilling.locationMainPhone'),
      2,
      1,
      null,
      6,
      false
    );
    properties.push(p12);

    return properties;
  }

  private static getBillingAdditionalInfo(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'BillingAndAdditionalInfo.ReferralGroupID',
      translate.instant('pricingAndBilling.referralGroup'),
      null,
      0,
      0,
      null,
      3,
      false
    );
    properties.push(p1);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'BillingAndAdditionalInfo.IntegrationSpecialistID',
      translate.instant('pricingAndBilling.integrationSpecialist'),
      null,
      0,
      1,
      null,
      4,
      false
    );
    properties.push(p2);

    let p3: FormDateProperty = new FormDateProperty(
      'BillingAndAdditionalInfo.TrialExpirationDate',
      translate.instant('pricingAndBilling.trialEndDate'),
      true,
      0,
      2,
      null,
      1,
      false,
      false
    );
    properties.push(p3);

    let p4: FormButtonProperty = new FormButtonProperty(
      'BillingAndAdditionalInfo.RollOut',
      translate.instant('pricingAndBilling.rollOut'),
      null,
      0,
      3,
      null,
      2,
      false
    );
    properties.push(p4);

    return properties;
  }

  private static getDistributionReplacementGroups(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'RecipientGroupID',
      translate.instant('distribution.recipientGroup'),
      null,
      0,
      0,
      null,
      3,
      false
    );
    properties.push(p1);

    let p3: FormRadioButtonProperty = new FormRadioButtonProperty(
      'AmountTypeDollarAmount',
      translate.instant('distribution.amountType'),
      0,
      1,
      null,
      2,
      false
    );
    properties.push(p3);

    let p4: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'Amount',
      translate.instant('distribution.amount'),
      2,
      2,
      0,
      2,
      null,
      2,
      false
    );
    properties.push(p4);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'RecipientGroupID_2',
      translate.instant('distribution.receipentGroup2'),
      null,
      0,
      3,
      null,
      3,
      false
    );
    properties.push(p2);

    let p5: FormDropdownProperty = new FormDropdownProperty(
      'CalcBasedOnID',
      translate.instant('distribution.calculationBasedOn'),
      null,
      0,
      4,
      null,
      2,
      false
    );
    properties.push(p5);

    return properties;
  }

  public static BuildFormForColums(
    columns: EventCollection<Column>,
    skipPropertyTypes: PropertyTypes[] = []
  ) {
    let properties: FormProperty[] = [];

    let propsPerColumn = Math.ceil(
      columns.values.filter((column) => column.data.IsVisible == true).length /
        3
    );
    let row = 0;
    let col = 0;
    let counter = 0;

    columns.values
      .filter(
        (column) =>
          column.data.IsVisible == true &&
          skipPropertyTypes.indexOf(
            column.data.PageObjectLayout.PropertyType
          ) == -1
      )
      .sort(
        (a, b) =>
          <any>(a.data.PageObjectLayout.Order === null) -
            <any>(b.data.PageObjectLayout.Order === null) ||
          +(a.data.PageObjectLayout.Order > b.data.PageObjectLayout.Order) ||
          -(a.data.PageObjectLayout.Order < b.data.PageObjectLayout.Order)
      )
      .forEach((column) => {
        switch (column.data.PageObjectLayout.PropertyType) {
          case PropertyTypes.Textbox:
            properties.push(
              new FormTextboxProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4,
                null,
                null,
                column.data.PageObjectLayout.Align
              )
            );
            break;
          case PropertyTypes.Dropdown:
            let prop = new FormDropdownProperty(
              column.data.PageObjectLayout &&
              column.data.PageObjectLayout.RelatedCode
                ? column.data.PageObjectLayout.RelatedCode
                : column.data.Code,
              column.data.Title,
              null,
              row,
              col,
              null,
              4
            );
            prop.readonly = true;
            PageObejctHelper.getDropdownOptions(column.data.Code).then(
              (res) => {
                if (res) prop.options = res;
              }
            );
            properties.push(prop);

            break;
          case PropertyTypes.Textarea:
            properties.push(
              new FormTextboxProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                2,
                4
              )
            );
            break;
          case PropertyTypes.Autocomplete:
            properties.push(
              new FormAutocompleteProperty(
                column.data.Code,
                column.data.Title,
                null,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.Password:
            properties.push(
              new FormPasswordProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.InputSwitch:
            properties.push(
              new FormInputSwitchProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.Time:
            properties.push(
              new FormTimeProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.RadioButton:
            properties.push(
              new FormRadioButtonProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.Date:
            properties.push(
              new FormDateProperty(
                column.data.Code,
                column.data.Title,
                true,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.InfoLabel:
            properties.push(
              new FormInfoLabelProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.Number:
            properties.push(
              new FormNumberDecimalProperty(
                column.data.Code,
                column.data.Title,
                2,
                2,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.Button:
            properties.push(
              new FormButtonProperty(
                column.data.Code,
                column.data.Title,
                null,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.PhoneNumber:
            properties.push(
              new FormPhoneNumberProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.TimeSpan:
            properties.push(
              new FormTimeSpanProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;
          case PropertyTypes.TimeUSFormat:
            properties.push(
              new FormTimeUSFormatProperty(
                column.data.Code,
                column.data.Title,
                row,
                col,
                null,
                4
              )
            );
            break;

          default:
            break;
        }

        properties[properties.length - 1].readonly = true;
        properties[properties.length - 1].align = column.data.PageObjectLayout
          ? column.data.PageObjectLayout.Align
          : -1;
        row++;
        counter++;
        if (!(counter % propsPerColumn)) {
          col++;
          row = 0;
        }
      });

    return properties;
  }

  private static getAccountDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'UserName',
      translate.instant('security.username'),
      0,
      0,
      null,
      12
    );
    p1.validationRegEx = regExLib.regex_NoSpecialChars;
    p1.validate = true;
    p1.validationMessage = translate.instant(
      'security.usernameValidationMessage'
    );
    properties.push(p1);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'FirstName',
      translate.instant('security.firstName'),
      0,
      0,
      null,
      12
    );
    p3.validate = true;
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'LastName',
      translate.instant('security.lastName'),
      0,
      0,
      null,
      12
    );
    p4.validate = true;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'Email',
      translate.instant('security.email'),
      0,
      0,
      null,
      12
    );
    p5.validate = true;
    p5.validateAllowNull = true;
    p5.validationRegEx = regExLib.regex_Email;
    properties.push(p5);

    let p6: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'PhoneNumber',
      translate.instant('security.phone'),
      0,
      0,
      null,
      12
    );
    //p6.validate = true;
    properties.push(p6);

    return properties;
  }

  private static getChangePassword(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormPasswordProperty = new FormPasswordProperty(
      'OldPassword',
      translate.instant('login.oldPassword'),
      0,
      0,
      null,
      12
    );
    p1.validate = true;
    p1.validationMessage = translate.instant('security.oldPasswordRequired');
    properties.push(p1);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'NewPassword',
      translate.instant('login.newPassword'),
      0,
      0,
      null,
      12
    );
    p2.validate = true;
    p2.feedback = true;
    p2.validateEqualFrom = 'ConfirmNewPassword';
    p2.validationMessage = translate.instant('security.passwordRequired');
    p2.validationRegEx = regExLib.regex_ComplexPassword12;
    properties.push(p2);

    let p3: FormPasswordProperty = new FormPasswordProperty(
      'ConfirmNewPassword',
      translate.instant('login.confirmPassword'),
      0,
      0,
      null,
      12
    );
    p3.validationMessage = translate.instant(
      'security.confirmPasswordRequired'
    );
    p3.validate = true;
    p3.validateEqualTo = 'NewPassword';
    properties.push(p3);

    return properties;
  }

  private static getRegisterManagerAccount(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'FirstName',
      translate.instant('controlPanel.firstName'),
      0,
      0,
      null,
      6
    );
    p1.validate = true;
    p1.validationMessage = translate.instant('controlPanel.firstNameRequired');
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'LastName',
      translate.instant('controlPanel.lastName'),
      0,
      0,
      null,
      6
    );
    p2.validate = true;
    p2.validationMessage = translate.instant('controlPanel.lastNameRequired');
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'Email',
      translate.instant('controlPanel.email'),
      2,
      0,
      null,
      6
    );
    p3.validate = true;
    p3.validationRegEx = regExLib.regex_Email;
    p3.validationMessage = translate.instant('controlPanel.emailRequired');
    properties.push(p3);

    let p4: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'Phone',
      translate.instant('controlPanel.phoneNumber'),
      1,
      1,
      null,
      6
    );
    p4.validate = true;
    p4.validationMessage = translate.instant(
      'controlPanel.phoneNumberRequired'
    );
    properties.push(p4);

    let p5: FormDropdownProperty = new FormDropdownProperty(
      'RoleID',
      translate.instant('controlPanel.role'),
      null,
      1,
      1,
      null,
      6
    );
    p5.validate = true;
    p5.readonly = true;
    p5.autoDisplayFirstDisabled = true;
    p5.infoMessage = translate.instant('controlPanel.roleInfo');
    p5.validationMessage = translate.instant('controlPanel.roleRequired');
    properties.push(p5);

    let p51: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('controlPanel.customers'),
        null,
        2,
        1,
        null,
        6
      );
    p51.validate = true;
    p51.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p51.validationMessage = translate.instant('controlPanel.customerRequired');
    properties.push(p51);

    //let p6: FormInfoLabelProperty = new FormInfoLabelProperty('ExistingAccountNote', translate.instant('controlPanel.existingAccountNotice'), 3, 0, null, 12);
    //p6.color = 'var(--nav-main-light-background-color)';
    //p6.fontSize = '14px';
    //p6.lineHeight = '18px';
    //p6.pureText = true;
    //properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'Username',
      translate.instant('controlPanel.username'),
      4,
      0,
      null,
      6
    );
    p7.validate = true;
    p7.validationMessage = translate.instant('controlPanel.usernameRequired');
    p7.validationRegEx = regExLib.regex_NoSpecialChars;
    p7.autocompleteOff = true;
    properties.push(p7);

    let p8: FormPasswordProperty = new FormPasswordProperty(
      'Password',
      translate.instant('controlPanel.password'),
      5,
      0,
      null,
      6
    );
    p8.validateEqualFrom = 'ConfirmPassword';
    p8.validationRegEx = regExLib.regex_ComplexPassword12;
    p8.validationMessage = translate.instant('controlPanel.passwordRequired');
    p8.validate = true;
    p8.autocompleteOff = true;
    properties.push(p8);

    let p9: FormPasswordProperty = new FormPasswordProperty(
      'ConfirmPassword',
      translate.instant('controlPanel.confirmPassword'),
      6,
      0,
      null,
      6
    );
    p9.validationMessage = translate.instant(
      'controlPanel.confirmPasswordDontMatch'
    );
    p9.validate = true;
    p9.validateEqualTo = 'Password';
    properties.push(p9);

    return properties;
  }

  private static getManagerAcountActivation(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'VerificationCode',
      translate.instant('controlPanel.verificationCode'),
      0,
      0,
      null,
      4
    );
    p1.validate = true;
    p1.validationMessage = translate.instant(
      'controlPanel.verificationCodeRequired'
    );
    properties.push(p1);

    return properties;
  }

  private static getManagerAccountDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'UserName',
      translate.instant('controlPanel.username'),
      0,
      0,
      null,
      6
    );
    p1.validate = true;
    p1.validationMessage = translate.instant('controlPanel.usernameRequired');
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'FirstName',
      translate.instant('controlPanel.firstName'),
      1,
      0,
      null,
      6
    );
    p2.validate = true;
    p2.validationMessage = translate.instant('controlPanel.firstNameRequired');
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'LastName',
      translate.instant('controlPanel.lastName'),
      2,
      0,
      null,
      6
    );
    p3.validate = true;
    p3.validationMessage = translate.instant('controlPanel.lastNameRequired');
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'Email',
      translate.instant('controlPanel.email'),
      3,
      0,
      null,
      6
    );
    p4.validate = true;
    p4.validationRegEx = regExLib.regex_Email;
    p4.validationMessage = translate.instant('controlPanel.emailRequired');
    properties.push(p4);

    let p5: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'PhoneNumber',
      translate.instant('controlPanel.phoneNumber'),
      4,
      0,
      null,
      6
    );
    p5.validate = true;
    p5.validationMessage = translate.instant(
      'controlPanel.phoneNumberRequired'
    );
    properties.push(p5);

    let p6: FormDropdownProperty = new FormDropdownProperty(
      'FK_Role',
      translate.instant('controlPanel.role'),
      null,
      5,
      0,
      null,
      6
    );
    p6.validate = true;
    p6.validationMessage = translate.instant('controlPanel.roleRequired');
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'StatusLabel',
      translate.instant('controlPanel.status'),
      6,
      0,
      null,
      6
    );
    p7.validate = false;
    p7.readonly = true;
    p7.validationMessage = translate.instant('controlPanel.roleRequired');
    properties.push(p7);

    let p8: FormButtonProperty = new FormButtonProperty(
      'SendEmail',
      translate.instant('controlPanel.send'),
      'fas fa-paper-plane',
      3,
      1,
      null,
      2
    );
    p8.visible = false;
    properties.push(p8);

    let p9: FormButtonProperty = new FormButtonProperty(
      'SendSMS',
      translate.instant('controlPanel.send'),
      'fas fa-paper-plane',
      4,
      1,
      null,
      2
    );
    p9.visible = false;
    properties.push(p9);

    let p10: FormInfoLabelProperty = new FormInfoLabelProperty(
      'SendEmailLabel',
      translate.instant('controlPanel.resendCodeEmail'),
      3,
      1,
      null,
      4
    );
    p10.visible = false;
    p10.color = 'var(--nav-main-light-background-color)';
    p10.fontSize = '14px';
    p10.lineHeight = '16px';
    p10.pureText = true;
    properties.push(p10);

    let p11: FormInfoLabelProperty = new FormInfoLabelProperty(
      'SendSMSLabel',
      translate.instant('controlPanel.resendCodeSMS'),
      4,
      1,
      null,
      4
    );
    p11.visible = false;
    p11.color = 'var(--nav-main-light-background-color)';
    p11.fontSize = '14px';
    p11.lineHeight = '16px';
    p11.pureText = true;
    properties.push(p11);

    let p12: FormButtonProperty = new FormButtonProperty(
      'TransferOwnership',
      translate.instant('controlPanel.transferOwnership'),
      '',
      7,
      0,
      2,
      4
    );
    p12.visible = false;
    //p12.color = 'var(--nav-main-light-background-color)';
    properties.push(p12);

    let p13: FormInfoLabelProperty = new FormInfoLabelProperty(
      'TransferOwnershipLabel',
      translate.instant('controlPanel.transferOwnershipLabel'),
      7,
      1,
      null,
      6
    );
    p13.visible = false;
    p13.color = 'var(--nav-main-light-background-color)';
    p13.fontSize = '14px';
    p13.lineHeight = '28px';
    p13.pureText = true;
    properties.push(p13);
    return properties;
  }

  private static getChangeLogFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'Type',
      translate.instant('reporting.changeLog.filter.type'),
      null,
      0,
      2,
      null,
      2
    );
    p1.visible = false;
    properties.push(p1);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'DateRange',
      translate.instant('reporting.changeLog.filter.dateRange'),
      null,
      0,
      1,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.changeLog.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'Location',
        translate.instant('reporting.changeLog.filter.location'),
        null,
        0,
        0,
        null,
        3,
        true
      );
    p3.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p3.validationMessage = translate.instant(
      'reporting.changeLog.filter.locationValidation'
    );
    properties.push(p3);

    let p4: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      4,
      null,
      1,
      false
    );
    p4.disabled = true;
    properties.push(p4);

    let p5: FormButtonProperty = new FormButtonProperty(
      'Rollback',
      translate.instant('general.rollback'),
      'fas fa-undo',
      0,
      6,
      null,
      2,
      false
    );
    p5.tooltip = 'Rollback POS data only for OPEN dates';
    p5.disabled = true;
    p5.styleClass = 'marginLeft30';
    properties.push(p5);

    return properties;
  }

  private static getReporting_payment(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'byPosition',
      translate.instant('reporting.payment.byPosition'),
      0,
      1,
      null,
      1
    );
    properties.push(p1);

    let p11: FormInputSwitchProperty = new FormInputSwitchProperty(
      'byShift',
      translate.instant('reporting.payment.byShift'),
      0,
      2,
      null,
      1
    );
    properties.push(p11);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'dateRange',
      translate.instant('reporting.payment.dateRange'),
      null,
      0,
      3,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.payment.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('reporting.payment.location'),
        null,
        0,
        4,
        null,
        3,
        true
      );
    p3.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p3.validationMessage = translate.instant(
      'reporting.payment.locationValidation'
    );
    properties.push(p3);

    let p4: FormButtonProperty = new FormButtonProperty(
      'Generate',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      5,
      null,
      2,
      false
    );
    p4.disabled = true;
    properties.push(p4);

    return properties;
  }

  private static getManagersAccountsFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('controlPanel.location'),
        null,
        0,
        0,
        null,
        3,
        false
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlpanel_manageraccounts_locations'
    ).then((res) => {
      p1.options = res;
    });
    properties.push(p1);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'ShowAll',
      translate.instant('controlPanel.filterByStatus'),
      null,
      0,
      1,
      null,
      3
    );
    p2.options = [
      { label: 'Active or unfinished registration', value: false },
      { label: 'All', value: true },
    ];
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.apply'),
      'fas fa-filter',
      0,
      2,
      null,
      1,
      false
    );
    p3.disabled = false;
    properties.push(p3);

    return properties;
  }

  private static getRegisterTicket(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('help.support.customer'),
      null,
      0,
      0,
      null,
      12
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('help.support.locationRequired');
    PageObejctHelper.getDropdownOptions('help_support_locations').then(
      (res) => {
        p0.options = res;
      }
    );
    properties.push(p0);

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Email',
      translate.instant('help.support.email'),
      1,
      0,
      null,
      12,
      false
    );
    p1.readonly = true;
    //p1.validationRegEx = this.regex_Email;
    //p1.validationMessage = translate.instant('help.support.emailValidation');
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Subject',
      translate.instant('help.support.subject'),
      2,
      0,
      null,
      12,
      true
    );
    p2.validationMessage = translate.instant('help.support.subjectValidation');
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'Message',
      translate.instant('help.support.message'),
      3,
      0,
      null,
      12,
      true
    );
    p3.validationMessage = translate.instant('help.support.messageValidation');
    p3.textRows = 10;
    properties.push(p3);

    //let p4: FormButtonProperty = new FormButtonProperty('AttachButton', translate.instant('help.support.attachFile'), null, 5, 0, null, 4);
    //properties.push(p4);

    //let p5: FormInfoLabelProperty = new FormButtonProperty('Base64File.FileName', translate.instant('help.support.attachFile'), null, 5, 5, null, 4);
    //properties.push(p5);

    return properties;
  }

  private static getLoadCardReportFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('reporting.loadCard.filter.location'),
        null,
        0,
        0,
        null,
        3,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'reporting.loadCard.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions('reporting_loadCard_locations').then(
      (res) => {
        p1.options = res;
      }
    );
    properties.push(p1);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'DateRange',
      translate.instant('reporting.loadCard.filter.dateRange'),
      null,
      0,
      1,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.loadCard.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      4,
      null,
      1,
      false
    );
    p3.disabled = true;
    properties.push(p3);

    return properties;
  }

  private static getDataExportReportFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormDropdownProperty = new FormDropdownProperty(
      'GsClientTypeId',
      translate.instant('reporting.dataExport.filter.clientType'),
      null,
      0,
      0,
      null,
      2,
      true
    );
    p0.validationMessage = translate.instant(
      'reporting.dataExport.filter.clientTypeValidation'
    );

    properties.push(p0);

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'ClientCodes',
        translate.instant('reporting.loadCard.filter.location'),
        null,
        0,
        0,
        null,
        3,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'reporting.loadCard.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions('reporting_dataExport_locations').then(
      (res) => {
        p1.options = res;
      }
    );
    p1.visible = false;
    properties.push(p1);

    let p11: FormDropdownProperty = new FormDropdownProperty(
      'DataExport',
      translate.instant('reporting.dataExport.filter.dataExportType'),
      null,
      0,
      0,
      null,
      2,
      true
    );
    p11.validationMessage = translate.instant(
      'reporting.dataExport.filter.dataExportTypeValidation'
    );

    properties.push(p11);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'DateRange',
      translate.instant('reporting.dataExport.filter.dateRange'),
      null,
      0,
      1,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.dataExport.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      4,
      null,
      1,
      false
    );
    p3.disabled = true;
    properties.push(p3);

    return properties;
  }

  private static getWithholdReportFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('reporting.withhold.filter.location'),
        null,
        0,
        0,
        null,
        3,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'reporting.withhold.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions('reporting_withhold_locations').then(
      (res) => {
        p1.options = res;
      }
    );
    properties.push(p1);

    let p2: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ByMonth',
      translate.instant('reporting.withhold.filter.byMonth'),
      0,
      5,
      null,
      null
    );
    properties.push(p2);

    let p3: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ByWithholdType',
      translate.instant('reporting.withhold.filter.byWithholdType'),
      0,
      6,
      null,
      2
    );
    properties.push(p3);

    let p4: FormDateRangeProperty = new FormDateRangeProperty(
      'Period',
      translate.instant('reporting.withhold.filter.period'),
      true,
      0,
      4,
      null,
      2,
      true
    );
    p4.monthOnly = true;
    p4.validationMessage = translate.instant(
      'reporting.withhold.filter.periodValidationMessage'
    );
    properties.push(p4);

    let p6: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      7,
      null,
      1,
      false
    );
    p6.disabled = true;
    properties.push(p6);

    return properties;
  }

  private static getScheduleTraining(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('help.scheduleTraining.customer'),
      null,
      0,
      0,
      null,
      8
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant(
      'help.scheduleTraining.locationRequired'
    );
    PageObejctHelper.getDropdownOptions('help_scheduleTraining_locations').then(
      (res) => {
        p0.options = res;
      }
    );
    properties.push(p0);

    let p1: FormDateProperty = new FormDateProperty(
      'Date',
      translate.instant('help.scheduleTraining.selectDate'),
      true,
      1,
      0,
      null,
      8,
      true,
      false
    );
    p1.inline = true;
    p1.minDate = new Date(new Date().setDate(new Date().getDate() + 2));
    p1.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    p1.validationMessage = translate.instant(
      'help.scheduleTraining.dateRequired'
    );
    properties.push(p1);

    let p2: FormRadioButtonProperty = new FormRadioButtonProperty(
      'Time',
      translate.instant('help.scheduleTraining.selectTime'),
      1,
      6,
      2,
      4,
      true
    );
    p2.validationMessage = translate.instant(
      'help.scheduleTraining.timeRequired'
    );
    p2.vertical = true;
    p2.overflowY = 'auto';
    p2.maxHeight = '350px';
    p2.hideNoOptions = true;
    p2.noOptionsColor = 'red';
    p2.noOptionsLabel = translate.instant(
      'help.scheduleTraining.noSlotsForSelectedDate'
    );
    properties.push(p2);

    let p4: FormInfoLabelProperty = new FormInfoLabelProperty(
      'ScheduleLabel',
      null,
      2,
      0,
      null,
      12
    );
    p4.pureText = true;
    p4.fontSize = '14px';
    p4.lineHeight = '28px';
    p4.visible = true;
    p4.color = 'var(--nav-main-light-background-color)';
    properties.push(p4);

    let p5: FormButtonProperty = new FormButtonProperty(
      'Schedule',
      translate.instant('help.scheduleTraining.schedule'),
      'pi pi-check',
      3,
      0,
      null,
      2
    );
    p5.disabled = true;
    properties.push(p5);

    //let p5: FormInfoLabelProperty = new FormButtonProperty('Base64File.FileName', translate.instant('help.support.attachFile'), null, 5, 5, null, 4);
    //properties.push(p5);

    return properties;
  }

  private static getEmployeeProfileFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('reporting.loadCard.filter.location'),
        null,
        0,
        0,
        null,
        5,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'controlPanel.employeeProfile.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions(
      'controlPanel_employeeProfile_locations'
    ).then((res) => {
      p1.options = res;
    });
    properties.push(p1);

    //let p2: FormAutocompleteProperty = new FormAutocompleteProperty('Employee_PK', translate.instant('controlPanel.employeeProfile.filter.employee'), null, 0, 1, null, 3);
    //p2.field = 'FullName';
    //properties.push(p2);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Employee',
      translate.instant('controlPanel.employeeProfile.filter.employee'),
      0,
      1,
      null,
      4
    );
    p2.readonly = true;
    p2.validationRegEx = regExLib.regex_MinChars(3);
    properties.push(p2);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'Status',
      translate.instant('controlPanel.employeeProfile.filter.status'),
      null,
      0,
      2,
      null,
      2
    );
    properties.push(p4);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.apply'),
      'fas fa-filter',
      0,
      3,
      null,
      1,
      false
    );
    p3.disabled = true;
    properties.push(p3);

    return properties;
  }

  private static getEmployeeProfileGratuityAccount(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Username',
      translate.instant('controlPanel.employeeProfile.details.username'),
      0,
      0,
      null,
      6
    );
    p1.readonly = true;
    properties.push(p1);

    let p2: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'PhoneNumber',
      translate.instant('controlPanel.employeeProfile.details.phoneNumber'),
      1,
      0,
      null,
      6
    );
    //p2.validate = true;
    //p2.validationMessage = translate.instant('controlPanel.employeeProfile.details.phoneNumberRequired');
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'EmployeeID',
      translate.instant('controlPanel.employeeProfile.details.employeeID'),
      2,
      0,
      null,
      6
    );
    p3.readonly = true;
    properties.push(p3);

    let p4: FormDateProperty = new FormDateProperty(
      'DateOfBirth',
      translate.instant('controlPanel.employeeProfile.details.dateOfBirth'),
      true,
      3,
      0,
      null,
      6
    );
    p4.yearNavigator = true;
    p4.yearRange =
      (new Date().getFullYear() - 100).toString() +
      ':' +
      new Date().getFullYear().toString();
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'StatusMessage',
      translate.instant(
        'controlPanel.employeeProfile.details.registrationStatus'
      ),
      4,
      0,
      null,
      6
    );
    p5.readonly = true;
    properties.push(p5);

    let p8: FormButtonProperty = new FormButtonProperty(
      'SendInvite',
      translate.instant('controlPanel.invite'),
      'fas fa-paper-plane',
      5,
      0,
      null,
      2
    );
    p8.visible = true;
    p8.disabled = true;
    properties.push(p8);

    let p10: FormInfoLabelProperty = new FormInfoLabelProperty(
      'SendInviteLabel',
      translate.instant('controlPanel.employeeProfile.details.sendInvite'),
      5,
      0,
      null,
      4
    );
    //p10.visible = false;
    p10.color = 'var(--nav-main-light-background-color)';
    p10.fontSize = '14px';
    p10.lineHeight = '16px';
    p10.pureText = true;
    properties.push(p10);

    return properties;
  }

  private static getEmployeeProfileCardSettings(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'MappindID',
      translate.instant('controlPanel.employeeProfile.details.mappingID'),
      0,
      0,
      null,
      6,
      true
    );
    properties.push(p1);

    let p2: FormAutocompleteProperty = new FormAutocompleteProperty(
      'LocationID',
      translate.instant('controlPanel.employeeProfile.details.locationID'),
      null,
      1,
      0,
      null,
      6,
      false,
      true,
      'blank',
      'fas fa-search',
      undefined,
      true,
      'LocationID'
    );
    properties.push(p2);

    let p3: FormAutocompleteProperty = new FormAutocompleteProperty(
      'AccountNumber',
      translate.instant('controlPanel.employeeProfile.details.accountNumber'),
      null,
      2,
      0,
      null,
      6,
      false,
      true,
      'blank',
      'fas fa-search',
      undefined,
      true,
      'AccountNumber'
    );
    properties.push(p3);

    return properties;
  }

  private static getEmployeeProfileCardStatus(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Active',
      translate.instant('controlPanel.employeeProfile.details.cardActive'),
      0,
      0,
      null,
      6
    );
    properties.push(p1);

    return properties;
  }

  private static getEmployeeProfileCardAssigned(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'MappingID',
      translate.instant('controlPanel.employeeProfile.details.mappingID'),
      0,
      1,
      null,
      12,
      true
    );
    properties.push(p1);

    let p2: FormAutocompleteProperty = new FormAutocompleteProperty(
      'LocationID',
      translate.instant('controlPanel.employeeProfile.details.locationID'),
      null,
      1,
      0,
      null,
      6,
      false,
      true,
      'blank',
      'fas fa-search',
      undefined,
      true,
      'LocationID'
    );
    properties.push(p2);

    let p3: FormAutocompleteProperty = new FormAutocompleteProperty(
      'AccountNumber',
      translate.instant('controlPanel.employeeProfile.details.accountNumber'),
      null,
      1,
      1,
      null,
      6,
      false,
      true,
      'blank',
      'fas fa-search',
      undefined,
      true,
      'AccountNumber'
    );
    properties.push(p3);

    return properties;
  }

  private static getControlPanelWithholdFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('general.locationDropdownDefaultLabel'),
      null,
      0,
      0,
      null,
      4,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions('controlPanel_withhold_locations').then(
      (res) => {
        p0.options = res;
      }
    );
    properties.push(p0);

    return properties;
  }

  private static getMonthlyOverviewFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormDateProperty = new FormDateProperty(
      'SelectedDate',
      translate.instant('dashboard.monthlyOverview.selectMonth'),
      null,
      0,
      0,
      null,
      2
    );
    p0.calendarView = 'month';
    p0.dateFormat = 'mm/yy';
    p0.yearNavigator = true;
    p0.yearRange =
      (new Date().getFullYear() - 20).toString() +
      ':' +
      new Date().getFullYear().toString();
    p0.readonlyInput = true;
    p0.showIcon = true;

    properties.push(p0);

    return properties;
  }

  private static getControlpanel_distributionRules(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);

    let p1: FormDateProperty = new FormDateProperty(
      'SelectedDate',
      '',
      null,
      0,
      2,
      null,
      2
    );
    p1.yearNavigator = true;
    p1.readonly = true;
    p1.yearRange =
      (new Date().getFullYear() - 20).toString() +
      ':' +
      new Date().getFullYear().toString();
    properties.push(p1);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'ConfigDate',
      '',
      null,
      0,
      1,
      null,
      2
    );
    p2.readonly = true;
    properties.push(p2);

    //let p3: FormButtonProperty = new FormButtonProperty('Apply', translate.instant('general.apply'), 'master-color', 0, 3, null, 1, false);
    //p3.disabled = false;
    //properties.push(p3);

    return properties;
  }

  private static getEmployeeSummaryReportFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('reporting.employeeSummary.filter.location'),
        null,
        0,
        0,
        null,
        3
      );
    p1.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    PageObejctHelper.getDropdownOptions(
      'reporting_employeeSummary_locations'
    ).then((res) => {
      p1.options = res;
      //p1.options.unshift({ label: 'All', value: null });
    });
    p1.validate = true;
    properties.push(p1);

    let p2: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ByDate',
      translate.instant('reporting.employeeSummary.filter.byDate'),
      0,
      4,
      null,
      null
    );
    properties.push(p2);

    let p3: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ByLocation',
      translate.instant('reporting.employeeSummary.filter.byLocation'),
      0,
      5,
      null,
      null
    );
    properties.push(p3);

    let p4: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ByShift',
      translate.instant('reporting.employeeSummary.filter.byShift'),
      0,
      6,
      null,
      null
    );
    properties.push(p4);

    let p5: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ByPosition',
      translate.instant('reporting.employeeSummary.filter.byPosition'),
      0,
      7,
      null,
      null
    );
    properties.push(p5);

    let p6: FormDateRangeProperty = new FormDateRangeProperty(
      'Period',
      translate.instant('reporting.employeeSummary.filter.dateRange'),
      null,
      1,
      1,
      null,
      3,
      true
    );
    p6.showIcon = true;
    p6.validationMessage = translate.instant(
      'reporting.employeeSummary.filter.dateRangeValidation'
    );
    properties.push(p6);

    let p7: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.show'),
      'fas fa-eye',
      1,
      4,
      null,
      1,
      false
    );
    p7.disabled = true;
    properties.push(p7);

    return properties;
  }

  private static getReporting_auditDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'DateRange',
      translate.instant('reporting.changeLog.filter.dateRange'),
      null,
      0,
      1,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.changeLog.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      4,
      null,
      1,
      false
    );
    p3.disabled = true;
    properties.push(p3);

    return properties;
  }

  private static getAnalyticsTotals(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'DateRange',
      translate.instant('reporting.changeLog.filter.dateRange'),
      null,
      0,
      1,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.changeLog.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      4,
      null,
      1,
      false
    );
    p3.disabled = true;
    properties.push(p3);

    return properties;
  }

  private static getReporting_employeeMapping(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);
    return properties;
  }

  private static getReporting_jobMapping(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);
    return properties;
  }

  private static getTransactionsWithEndDateFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('dashboard.transactionsWithEndDate.filter.location'),
        null,
        0,
        0,
        null,
        3,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'dashboard.transactionsWithEndDate.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions(
      'dashboard_transactionsWithEndDate_locations'
    ).then((res) => {
      p1.options = res;
    });
    properties.push(p1);

    //let p2: FormTextboxProperty = new FormTextboxProperty('EmployeeNameFilter', translate.instant('dashboard.transactionsWithEndDate.filter.employee'), 0, 1, null, 3);
    //p2.readonly = true;
    //p2.validationRegEx = FormBuilder.regex_MinChars(3);
    //properties.push(p2);
    let p2: FormAutocompleteProperty = new FormAutocompleteProperty(
      'EmployeeFilter',
      translate.instant('dashboard.transactionsWithEndDate.filter.employee'),
      null,
      0,
      1,
      null,
      3
    );
    p2.dropdownIcon = null;
    //p2.readonly = true;
    //p2.validationRegEx = FormBuilder.regex_MinChars(3);
    properties.push(p2);

    let p3: FormDateRangeProperty = new FormDateRangeProperty(
      'Period',
      translate.instant('dashboard.transactionsWithEndDate.filter.dateRange'),
      null,
      0,
      4,
      null,
      2,
      true
    );
    p3.showIcon = true;
    p3.validationMessage = translate.instant(
      'dashboard.transactionsWithEndDate.filter.dateRangeValidation'
    );
    properties.push(p3);

    let p4: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.apply'),
      'fas fa-filter',
      0,
      6,
      null,
      1,
      false
    );
    p4.disabled = true;
    properties.push(p4);

    return properties;
  }

  private static getDashboard_CustomerTabLocations(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('help.support.customer'),
      null,
      0,
      0,
      null,
      12
    );
    p0.validate = true;
    p0.preselectFirst = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('help.support.locationRequired');
    properties.push(p0);

    return properties;
  }

  private static getVisaChoice(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormDateProperty = new FormDateProperty(
      'Date',
      translate.instant('controlPanel.visaChoice.date'),
      true,
      0,
      0,
      null,
      9,
      true
    );
    p0.dateFormat = 'yy-MM-dd';
    p0.validate = false;
    properties.push(p0);

    return properties;
  }

  private static getVisaChoice_merchant(translate: TranslateService) {
    let properties = [];
    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      translate.instant('controlPanel.visaChoice.name'),
      0,
      1,
      null,
      3,
      true
    );
    p2.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.name'
    )} is mandatory`;
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'Dba',
      translate.instant('controlPanel.visaChoice.dba'),
      0,
      2,
      null,
      3,
      true
    );
    p3.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.dba'
    )} is mandatory`;
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'Ein',
      translate.instant('controlPanel.visaChoice.ein'),
      0,
      3,
      null,
      3,
      true
    );
    p4.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ein'
    )} is mandatory`;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'Address',
      translate.instant('controlPanel.visaChoice.address'),
      0,
      4,
      null,
      3,
      true
    );
    p5.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.address'
    )} is mandatory`;
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'City',
      translate.instant('controlPanel.visaChoice.city'),
      1,
      1,
      null,
      3,
      true
    );
    p6.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.city'
    )} is mandatory`;
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'State',
      translate.instant('controlPanel.visaChoice.state'),
      1,
      2,
      null,
      3,
      true
    );
    p7.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.state'
    )} is mandatory`;
    properties.push(p7);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'ZIP',
      translate.instant('controlPanel.visaChoice.zip'),
      1,
      3,
      null,
      3,
      true
    );
    p8.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.zip'
    )} is mandatory`;
    properties.push(p8);

    let p9: FormNumberProperty = new FormNumberProperty(
      'YearEstablished',
      translate.instant('controlPanel.visaChoice.year'),
      'decimal',
      0,
      0,
      1,
      4,
      null,
      3,
      true,
      regExLib.regex_Year
    );
    p9.formatNumber = false;
    p9.maxLength = 4;
    p9.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.year'
    )} is mandatory and must have 4 digits`;
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'Website',
      translate.instant('controlPanel.visaChoice.website'),
      2,
      1,
      null,
      3,
      true
    );
    p10.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.website'
    )} is mandatory`;
    properties.push(p10);

    let p11: FormTextboxProperty = new FormTextboxProperty(
      'ServiceType',
      translate.instant('controlPanel.visaChoice.typeService'),
      2,
      2,
      null,
      3,
      true
    );
    p11.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.typeService'
    )} is mandatory`;
    properties.push(p11);

    let p12: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'CustomerService',
      translate.instant('controlPanel.visaChoice.customerService'),
      2,
      3,
      null,
      3,
      true
    );
    p12.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.customerService'
    )} is mandatory and must contain 10 digits`;
    properties.push(p12);

    let p13: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'AdminContact',
      translate.instant('controlPanel.visaChoice.admin'),
      2,
      4,
      null,
      3,
      true
    );
    p13.validationMessage = 'This field must contain 10 digits.';
    properties.push(p13);

    return properties;
  }

  private static getVisaChoice_customerBankInfo(translate: TranslateService) {
    let properties = [];

    let p131: FormTextboxProperty = new FormTextboxProperty(
      'Rtn',
      translate.instant('controlPanel.visaChoice.rtn'),
      0,
      1,
      null,
      3,
      true
    );
    p131.validationRegEx = regExLib.regex_RTN;
    p131.validate = true;
    p131.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.rtn'
    )} is mandatory`;
    properties.push(p131);

    let p132: FormTextboxProperty = new FormTextboxProperty(
      'Aba',
      translate.instant('controlPanel.visaChoice.aba'),
      0,
      2,
      null,
      3,
      true
    );
    p132.validate = true;
    p132.validationRegEx = regExLib.regex_Aba;
    p132.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.aba'
    )} is mandatory`;
    properties.push(p132);

    return properties;
  }

  private static getVisaChoice_ownership(translate: TranslateService) {
    let properties = [];

    let p14: FormTextboxProperty = new FormTextboxProperty(
      'Owner',
      translate.instant('controlPanel.visaChoice.owner'),
      0,
      1,
      null,
      3,
      true
    );
    p14.validate = true;
    p14.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.owner'
    )} is mandatory`;
    properties.push(p14);

    let p15: FormTextboxProperty = new FormTextboxProperty(
      'OwnershipPercentage',
      translate.instant('controlPanel.visaChoice.ownership'),
      0,
      2,
      null,
      3,
      true,
      regExLib.regex_OneToHundered
    );
    p15.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ownership'
    )} is mandatory and must be between 1 and 100`;
    properties.push(p15);

    let p16: FormDateProperty = new FormDateProperty(
      'DOB',
      translate.instant('controlPanel.visaChoice.dob'),
      true,
      0,
      3,
      null,
      3,
      true
    );
    p16.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.dob'
    )} is mandatory`;
    properties.push(p16);

    let p17: FormTextboxProperty = new FormTextboxProperty(
      'OwnerSSN',
      translate.instant('controlPanel.visaChoice.ownerSSN'),
      0,
      4,
      null,
      3,
      true
    );
    p17.validationRegEx = regExLib.regex_SSN;
    p17.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ownerSSN'
    )} is mandatory`;
    properties.push(p17);

    let p18: FormTextboxProperty = new FormTextboxProperty(
      'Address',
      translate.instant('controlPanel.visaChoice.ownerAddress'),
      1,
      1,
      null,
      3,
      true
    );
    p18.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ownerAddress'
    )} is mandatory`;
    properties.push(p18);

    let options = [
      { value: undefined, label: undefined },
      { value: 'Y', label: 'Yes' },
      { value: 'N', label: 'No' },
    ];

    let p19: FormDropdownProperty = new FormDropdownProperty(
      'Arrests',
      translate.instant('controlPanel.visaChoice.arrests'),
      null,
      1,
      2,
      null,
      4
    );
    p19.options = options;
    p19.validate = true;
    p19.labelClass = 'long-label';
    p19.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.arrests'
    )} is mandatory`;
    properties.push(p19);

    return properties;
  }

  private static getVisaChoice_additionalInfo(translate: TranslateService) {
    let properties = [];

    let options = [
      { value: undefined, label: undefined },
      { value: 'Y', label: 'Yes' },
      { value: 'N', label: 'No' },
    ];

    let p20: FormDropdownProperty = new FormDropdownProperty(
      'MoneyService',
      translate.instant('controlPanel.visaChoice.moneyService'),
      null,
      0,
      1,
      null,
      4
    );
    p20.options = options;
    p20.validate = true;
    p20.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.moneyService'
    )} is mandatory`;
    properties.push(p20);

    let p21: FormDropdownProperty = new FormDropdownProperty(
      'Gambling',
      translate.instant('controlPanel.visaChoice.gambling'),
      null,
      0,
      2,
      null,
      4
    );
    p21.options = options;
    p21.validate = true;
    p21.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.gambling'
    )} is mandatory`;
    properties.push(p21);

    let p22: FormDropdownProperty = new FormDropdownProperty(
      'ProhibitedBusiness',
      translate.instant('controlPanel.visaChoice.prohibitedBusiness'),
      null,
      0,
      3,
      null,
      4
    );
    p22.options = options;
    p22.validate = true;
    p22.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.prohibitedBusiness'
    )} is mandatory`;
    properties.push(p22);

    let p23: FormDropdownProperty = new FormDropdownProperty(
      'RestrictedBusiness',
      translate.instant('controlPanel.visaChoice.restrictedBusiness'),
      null,
      1,
      1,
      null,
      4
    );
    p23.options = options;
    p23.validate = true;
    p23.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.restrictedBusiness'
    )} is mandatory`;
    properties.push(p23);

    let p24: FormDropdownProperty = new FormDropdownProperty(
      'ThridParty',
      translate.instant('controlPanel.visaChoice.3rdParty'),
      null,
      1,
      2,
      null,
      4
    );
    p24.options = options;
    p24.validate = false;
    properties.push(p24);

    let p25: FormDropdownProperty = new FormDropdownProperty(
      'checkComplete',
      translate.instant('controlPanel.visaChoice.checkComplete'),
      null,
      1,
      3,
      null,
      4
    );
    p25.options = options;
    p25.validate = true;
    p25.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.checkComplete'
    )} is mandatory`;
    properties.push(p25);

    let p26: FormDropdownProperty = new FormDropdownProperty(
      'agreementOnFile',
      translate.instant('controlPanel.visaChoice.agreementOnFile'),
      null,
      2,
      1,
      null,
      4
    );
    p26.options = options;
    p26.validate = true;
    p26.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.agreementOnFile'
    )} is mandatory`;
    properties.push(p26);

    return properties;
  }

  private static getGlobalConfiguration_wage(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormDropdownProperty = new FormDropdownProperty(
      'WeekStartOn',
      'Work week start at',
      null,
      0,
      0,
      null,
      4,
      false
    );
    p0.options = [];
    p0.options.push({ value: 1, label: 'Monday' });
    p0.options.push({ value: 2, label: 'Tuesday' });
    p0.options.push({ value: 3, label: 'Wednesday' });
    p0.options.push({ value: 4, label: 'Thursday' });
    p0.options.push({ value: 5, label: 'Friday' });
    p0.options.push({ value: 6, label: 'Saturday' });
    p0.options.push({ value: 7, label: 'Sunday' });
    properties.push(p0);

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'RegularHours',
      'Regular hours',
      0,
      1,
      null,
      3,
      false
    );
    p1.align = 1;
    p1.afterText = 'h';
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'OverTimeRate',
      'Ovt Rate',
      0,
      2,
      null,
      2,
      false
    );
    p2.align = 1;
    p2.afterText = 'x';
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'DoubleTimeRate',
      'Dbl Rate',
      0,
      3,
      null,
      2,
      false
    );
    p3.align = 1;
    p3.afterText = 'x';
    properties.push(p3);

    return properties;
  }

  private static getPlaidConfiguration(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormTextboxProperty = new FormTextboxProperty(
      'Email',
      translate.instant('controlPanel.plaid.email'),
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = false;
    properties.push(p0);

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'AccountId',
      translate.instant('controlPanel.plaid.accountNumber'),
      null,
      1,
      0,
      null,
      3
    );
    p1.validate = true;
    p1.visible = false;
    p1.validationMessage = `${translate.instant(
      'controlPanel.plaid.accountNumber'
    )} is mandatory`;
    properties.push(p1);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Update',
      translate.instant('general.update'),
      'fas fa-save',
      2,
      0,
      null,
      3,
      false
    );
    properties.push(p3);

    return properties;
  }

  private static getCustomerDeactivation(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormDateProperty = new FormDateProperty(
      'DeactivationDate',
      translate.instant('customerList.CustomerDeactivationDate'),
      true,
      0,
      4,
      null,
      4,
      false,
      false
    );
    p0.validate = true;
    p0.validateAllowNull = false;
    p0.readonlyInput = true;
    p0.validationMessage = `${translate.instant(
      'customerList.DeactivationDate'
    )} is mandatory`;
    properties.push(p0);

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'DeactivationMessageId',
      translate.instant('customerList.ReasonForDeactivation'),
      null,
      1,
      0,
      null,
      4
    );
    p1.validate = true;
    p1.validateAllowNull = false;
    p1.validationMessage = `${translate.instant(
      'customerList.ReasonForDeactivation'
    )} is mandatory`;
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'AdditionalNote',
      translate.instant('customerList.AdditionalNote'),
      2,
      4,
      3,
      5,
      false
    );
    p2.validate = false;
    properties.push(p2);

    return properties;
  }

  private static getTrackingGroupsCommissionSales(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p6: FormDropdownProperty = new FormDropdownProperty(
      'Pk_Id',
      translate.instant('trackingGroups.startDates'),
      null,
      0,
      0,
      null,
      2
    );
    p6.autoDisplayFirstDisabled = true;
    properties.push(p6);

    let p7: FormDateProperty = new FormDateProperty(
      'NewStartDate',
      translate.instant('trackingGroups.newStartDate'),
      null,
      0,
      1,
      null,
      2
    );
    p7.minDate = new Date(new Date().setDate(new Date().getDate() - 21));
    properties.push(p7);

    let p8: FormButtonProperty = new FormButtonProperty(
      'AddCommissionStartDate',
      translate.instant('trackingGroups.add'),
      null,
      0,
      1,
      null,
      2
    );
    properties.push(p8);

    let p0: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'CommissionPercent_1',
      translate.instant('trackingGroups.commissionPercent_1'),
      2,
      2,
      1,
      0,
      null,
      null,
      false
    );
    properties.push(p0);

    let p1: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'CommissionPercent_2',
      translate.instant('trackingGroups.commissionPercent_2'),
      2,
      2,
      1,
      0,
      null,
      null,
      false
    );
    properties.push(p1);

    let p2: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'CommissionPercent_3',
      translate.instant('trackingGroups.commissionPercent_3'),
      2,
      2,
      1,
      0,
      null,
      null,
      false
    );
    properties.push(p2);

    let p3: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'Commission_Fee',
      translate.instant('trackingGroups.commissionFee'),
      2,
      2,
      1,
      0,
      null,
      null,
      false
    );
    properties.push(p3);

    let p4: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'WitholdPercent',
      translate.instant('trackingGroups.witholdPercent'),
      2,
      2,
      1,
      0,
      null,
      null,
      false
    );
    properties.push(p4);
    return properties;
  }

  private static getControlpanel_commissionDistributionRules(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_commission_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);

    let p1: FormDateProperty = new FormDateProperty(
      'SelectedDate',
      '',
      null,
      0,
      2,
      null,
      2
    );
    p1.yearNavigator = true;
    p1.readonly = true;
    p1.yearRange =
      (new Date().getFullYear() - 20).toString() +
      ':' +
      new Date().getFullYear().toString();
    properties.push(p1);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'ConfigDate',
      '',
      null,
      0,
      1,
      null,
      2
    );
    p2.readonly = true;
    properties.push(p2);

    //let p3: FormButtonProperty = new FormButtonProperty('Apply', translate.instant('general.apply'), 'master-color', 0, 3, null, 1, false);
    //p3.disabled = false;
    //properties.push(p3);

    return properties;
  }

  private static getcompanyGroupAddProperty(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'Property',
      '',
      null,
      0,
      0,
      null,
      6
    );
    p1.options = [];
    p1.placeholder = 'Please select property';
    p1.options.push({ label: 'Dwolla multiple applications', value: 2 });
    properties.push(p1);
    return properties;
  }

  private static getVisaChoice_ach(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'SignerName',
      translate.instant('controlPanel.visaChoice.ach.Name'),
      1,
      1,
      null,
      3,
      true
    );
    p5.validate = true;
    p5.validateAllowNull = false;
    p5.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ach.Name'
    )} is mandatory`;
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'SignerTitle',
      translate.instant('controlPanel.visaChoice.ach.Title'),
      1,
      2,
      null,
      3,
      true
    );
    p6.validate = true;
    p6.validateAllowNull = false;
    p6.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ach.Title'
    )} is mandatory`;
    properties.push(p6);

    let p7: FormDateProperty = new FormDateProperty(
      'Date',
      translate.instant('controlPanel.visaChoice.ach.Date'),
      true,
      1,
      4,
      null,
      3,
      true
    );
    p7.validate = true;
    p7.validateAllowNull = false;
    p7.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ach.Date'
    )} is mandatory`;
    properties.push(p7);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'SignerEmail',
      translate.instant('controlPanel.visaChoice.ach.Email'),
      1,
      3,
      null,
      3,
      true
    );
    p8.validate = true;
    p8.validateAllowNull = false;
    p8.validationRegEx = regExLib.regex_Email;
    p8.validationMessage = `${translate.instant(
      'controlPanel.visaChoice.ach.Email'
    )} is mandatory`;
    properties.push(p8);

    return properties;
  }

  private static getTabapayReconciliationReportFilter(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p0: FormCompanyGroupDropdownProperty =
      new FormCompanyGroupDropdownProperty(
        'CompanyGroup',
        translate.instant('reportingadmin.tabapay.group'),
        null,
        0,
        0,
        null,
        3,
        false
      );
    p0.validationMessage = translate.instant(
      'reportingadmin.tabapay.groupValidation'
    );

    PageObejctHelper.getDropdownOptions(
      'reportingadmin_tabapay_companyGroups'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'CustomerCodes',
        translate.instant('reportingadmin.tabapay.location'),
        null,
        0,
        1,
        null,
        3,
        true
      );

    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'reporting.tabapay.locationValidation'
    );

    PageObejctHelper.getDropdownOptions(
      'reportingadmin_tabapay_customers'
    ).then((res) => {
      p1.options = res;
    });
    properties.push(p1);

    let p2: FormDateRangeProperty = new FormDateRangeProperty(
      'DateRange',
      translate.instant('reportingadmin.tabapay.dateRange'),
      null,
      0,
      2,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.loadCard.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      4,
      null,
      1,
      false
    );
    p3.disabled = true;
    properties.push(p3);

    return properties;
  }

  private static getQuickbooksFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormDateProperty = new FormDateProperty(
      'Date',
      translate.instant('quickbooks.selectMonth'),
      true,
      0,
      0,
      null,
      2,
      false
    );
    p1.calendarView = 'month';
    p1.dateFormat = 'MM/yy';
    properties.push(p1);

    let p2: FormMultiselectProperty = new FormMultiselectProperty(
      'Status',
      translate.instant('quickbooks.statusFilter'),
      null,
      0,
      1,
      null,
      2,
      false
    );
    p2.options = [
      { label: 'Pending', value: 1 },
      { label: 'Processed', value: 2 },
      { label: 'Failed', value: 3 },
    ];
    properties.push(p2);

    //let p3: FormInfoLabelProperty = new FormInfoLabelProperty(
    //  'Info',
    //  '',
    //  0,
    //  2,
    //  null,
    //  4
    //);
    //p3.pureText = true;
    //properties.push(p3);

    return properties;
  }

  private static getCompanyGroupDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Code',
      translate.instant('companyGroups.code'),
      0,
      0,
      null,
      1,
      false
    );
    p1.readonly = true;
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      translate.instant('companyGroups.name'),
      0,
      1,
      null,
      3,
      true
    );
    p2.validationMessage = 'Company group name is mandatory';
    properties.push(p2);

    let p3: FormDropdownProperty = new FormDropdownProperty(
      'ImplementationType',
      translate.instant('companyGroups.implementationType'),
      null,
      0,
      2,
      null,
      2,
      true
    );
    p3.validationMessage = 'Implementation type is mandatory';
    PageObejctHelper.getDropdownOptions(
      'companygroup_details_implementationtypes'
    ).then((res) => {
      p3.options = res;
    });
    properties.push(p3);

    let p4: FormRadioButtonProperty = new FormRadioButtonProperty(
      'FK_NoEmailTicketMessage',
      translate.instant('companyGroups.noEmailTicketMessage'),
      1,
      0,
      null,
      12,
      true
    );
    p4.validationRegEx = regExLib.regex_NoZero;
    p4.validationMessage = 'No email ticket message is mandatory';
    PageObejctHelper.getDropdownOptions(
      'companygroup_details_noEmailTicketMessage'
    ).then((res) => {
      p4.options = res;
    });
    p4.leftLabel = false;
    p4.vertical = true;
    p4.noLeftMargin = true;
    p4.readonly = false;
    properties.push(p4);

    let p5: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'VisaChoiceCardServiceFee',
      translate.instant('companyGroups.visaChoiceFee'),
      0,
      2,
      2,
      0,
      null,
      3,
      false
    );
    p5.visible = false;
    properties.push(p5);
    let p6: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ValidatePayrollExportTemplate',
      translate.instant('companyGroups.validatePayrollExportTemplate'),
      3,
      0,
      null,
      4,
      false
    );
    p6.styleClass = 'font-size-left-label';
    p6.visible = true;
    p6.leftLabel = true;
    properties.push(p6);

    return properties;
  }
  private static getCompanyGroupDetails_part2(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ShowSpecialUserAgreement',
      translate.instant('companyGroups.showAgreement'),
      0,
      0,
      null,
      12,
      true
    );
    p1.leftLabel = true;

    properties.push(p1);

    return properties;
  }

  private static getCompanyGroupDetails_part3(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'ShowAgreement',
      translate.instant('companyGroups.showAgreement'),
      0,
      0,
      null,
      12,
      true
    );
    p1.leftLabel = true;
    properties.push(p1);

    return properties;
  }

  private static getReportingAnaliytics(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('reporting.analytic.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('reporting.analytic.location');
    PageObejctHelper.getDropdownOptions(
      'controlPanel_distribution_rules_locations'
    ).then((res) => {
      p0.options = res;
    });
    properties.push(p0);

    let p2: FormCalendarFromToProperty = new FormCalendarFromToProperty(
      'DateRange',
      translate.instant('reporting.analytic.dateRange'),
      null,
      0,
      1,
      null,
      3,
      true
    );
    p2.showIcon = true;
    p2.validationMessage = translate.instant(
      'reporting.changeLog.filter.dateRangeValidation'
    );
    properties.push(p2);

    let p3: FormDropdownProperty = new FormDropdownProperty(
      'Category',
      translate.instant('reporting.analytic.category'),
      null,
      0,
      2,
      null,
      2,
      false
    );
    p3.validate = true;
    p3.options = [
      {
        value: 1,
        label: 'Employee',
      },
      {
        value: 2,
        label: 'Check',
      },
    ];
    properties.push(p3);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'EmployeeId',
      translate.instant('reporting.analytic.employee'),
      null,
      0,
      3,
      null,
      2,
      false
    );
    p4.showFilter = true;
    p4.visible = true;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'CheckId',
      translate.instant('reporting.analytic.check'),
      0,
      4,
      null,
      2
    );
    p5.visible = false;
    properties.push(p5);

    //

    let p6: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      5,
      null,
      2,
      false
    );
    p6.disabled = true;
    properties.push(p6);

    return properties;
  }

  private static getDwollaVerifiedCustomerFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p0: FormLocationDropdownProperty = new FormLocationDropdownProperty(
      'CustomerCode',
      translate.instant('controlPanel.distributionRule.location'),
      null,
      0,
      0,
      null,
      3,
      true
    );
    p0.validate = true;
    p0.defaultLabel = translate.instant('general.locationDropdownDefaultLabel');
    p0.validationMessage = translate.instant('controlPanel.locationValidation');
    PageObejctHelper.getDropdownOptions('dwolla_gratuityLocations').then(
      (res) => {
        p0.options = res;
      }
    );
    properties.push(p0);

    let p1: FormButtonProperty = new FormButtonProperty(
      'ConfigureDwollaVerifiedCustomer',
      translate.instant('controlPanel.dwolla.configure'),
      'pi pi-plus-circle',
      0,
      1,
      null,
      2,
      false
    );
    properties.push(p1);
    return properties;
  }

  private static getDwollaVerifiedCustomer(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p10: FormDropdownProperty = new FormDropdownProperty(
      'BusinessType',
      translate.instant('controlPanel.dwolla.businessType'),
      [],
      0,
      0,
      null,
      3,
      true
    );
    properties.push(p10);
    p10.options.push({
      label: 'Sole proprietorship',
      value: 'SoleProprietorship',
    });
    p10.options.push({
      label: 'Unincorporated association',
      value: 'UnincorporatedAssociation',
    });
    p10.options.push({ label: 'Trust', value: 'Trust' });
    p10.options.push({ label: 'Corporation', value: 'Corporation' });
    p10.options.push({
      label: 'Publicly traded corporations',
      value: 'PubliclyTradedCorporations',
    });
    p10.options.push({
      label: 'Non-profits - corporation',
      value: 'Non-profitsCorporation',
    });
    p10.options.push({ label: 'Non-profits - LLC', value: 'Non-profitsLLC' });
    p10.options.push({ label: 'LLCs', value: 'LLC' });
    p10.options.push({
      label: "Partnerships, LP's, LLP's",
      value: 'PartnershipsLPsLLPs',
    });
    p10.autoDisplayFirstDisabled = true;

    let p21: FormDropdownProperty = new FormDropdownProperty(
      'BusinessClassificationMaster',
      translate.instant('controlPanel.dwolla.businessClassification'),
      [],
      0,
      1,
      null,
      3,
      true
    );
    p21.autoDisplayFirstDisabled = true;
    properties.push(p21);

    let p24: FormDropdownProperty = new FormDropdownProperty(
      'BusinessClassification',
      translate.instant('controlPanel.dwolla.industryClassification'),
      [],
      0,
      2,
      null,
      3,
      true
    );
    p24.autoDisplayFirstDisabled = true;
    properties.push(p24);

    let p22: formMaskInputProperty = new formMaskInputProperty(
      'EIN',
      translate.instant('controlPanel.dwolla.ein'),
      0,
      2,
      null,
      2,
      true
    );
    p22.mask = '99-9999999';
    //p22.placeholder ='99-999999'
    p22.validationRegEx = regExLib.regex_EIN;
    properties.push(p22);

    let p20: FormInputSwitchProperty = new FormInputSwitchProperty(
      'HasBenefitialOwnerWithMorethan25PercentOwnership',
      translate.instant(
        'controlPanel.dwolla.hasBenefitialOwnerWithMorethan25PercentOwnership'
      ),
      0,
      3,
      null,
      6,
      false
    );
    p20.visible = false;
    properties.push(p20);
    //

    let p00: FormTextboxProperty = new FormTextboxProperty(
      'BusinessName',
      translate.instant('controlPanel.dwolla.businessName'),
      1,
      0,
      null,
      6,
      true
    );
    properties.push(p00);

    let p12: FormTextboxProperty = new FormTextboxProperty(
      'DoingBusinessAs',
      translate.instant('controlPanel.dwolla.doingBusinessAs'),
      1,
      1,
      null,
      6,
      false
    );
    properties.push(p12);

    //

    let p01: FormTextboxProperty = new FormTextboxProperty(
      'FirstName',
      translate.instant('controlPanel.dwolla.firstName'),
      2,
      0,
      null,
      2,
      true
    );
    properties.push(p01);

    let p02: FormTextboxProperty = new FormTextboxProperty(
      'LastName',
      translate.instant('controlPanel.dwolla.lastName'),
      2,
      1,
      null,
      2,
      true
    );
    properties.push(p02);

    let p16: FormDateProperty = new FormDateProperty(
      'DateOfBirth',
      translate.instant('controlPanel.dwolla.dateOfBirth'),
      true,
      2,
      1,
      null,
      2,
      true
    );
    properties.push(p16);

    let p03: FormTextboxProperty = new FormTextboxProperty(
      'Email',
      translate.instant('controlPanel.dwolla.email'),
      2,
      2,
      null,
      2,
      true
    );
    p03.validationRegEx = regExLib.regex_Email;
    properties.push(p03);

    let p09: FormPhoneNumberProperty = new FormPhoneNumberProperty(
      'Phone',
      translate.instant('controlPanel.dwolla.phone'),
      2,
      3,
      null,
      2,
      false
    );
    properties.push(p09);

    let p15: formMaskInputProperty = new formMaskInputProperty(
      'SSN',
      translate.instant('controlPanel.dwolla.ssnLast4'),
      2,
      4,
      null,
      2,
      true
    );
    p15.validationRegEx = regExLib.regex_4Digits;
    p15.mask = '9999';
    properties.push(p15);
    //

    let p04: FormTextboxProperty = new FormTextboxProperty(
      'Address1',
      translate.instant('controlPanel.dwolla.addressLine1'),
      3,
      0,
      null,
      6,
      true
    );
    properties.push(p04);

    let p05: FormTextboxProperty = new FormTextboxProperty(
      'Address2',
      translate.instant('controlPanel.dwolla.addressLine2'),
      3,
      1,
      null,
      6,
      false
    );
    properties.push(p05);

    let p06: FormTextboxProperty = new FormTextboxProperty(
      'City',
      translate.instant('controlPanel.dwolla.city'),
      4,
      0,
      null,
      3,
      true
    );
    properties.push(p06);

    let p07: formMaskInputProperty = new formMaskInputProperty(
      'State',
      translate.instant('controlPanel.dwolla.state'),
      4,
      1,
      null,
      2,
      true
    );
    p07.mask = 'aa';
    properties.push(p07);

    let p08: formMaskInputProperty = new formMaskInputProperty(
      'PostalCode',
      translate.instant('controlPanel.dwolla.postalCode'),
      4,
      2,
      null,
      1,
      true
    );
    p08.mask = '99999';
    //p08.placeholder = '99999'
    p08.validationRegEx = regExLib.regex_5Digits;
    properties.push(p08);

    let p13: FormTextboxProperty = new FormTextboxProperty(
      'Website',
      translate.instant('controlPanel.dwolla.website'),
      4,
      3,
      null,
      6,
      false
    );
    properties.push(p13);

    //
    let p11: FormTextboxProperty = new FormTextboxProperty(
      'DwStatusStr',
      translate.instant('controlPanel.dwolla.status'),
      5,
      0,
      null,
      3,
      false
    );
    properties.push(p11);

    let p14: FormDateProperty = new FormDateProperty(
      'Created',
      translate.instant('controlPanel.dwolla.created'),
      false,
      5,
      1,
      null,
      3,
      false
    );
    properties.push(p14);

    return properties;
  }

  private static getDwollaVerifiedCustomerController(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p01: FormTextboxProperty = new FormTextboxProperty(
      'ControllerFirstName',
      translate.instant('controlPanel.dwolla.firstName'),
      2,
      0,
      null,
      3,
      true
    );
    properties.push(p01);

    let p02: FormTextboxProperty = new FormTextboxProperty(
      'ControllerLastName',
      translate.instant('controlPanel.dwolla.lastName'),
      2,
      1,
      null,
      3,
      true
    );
    properties.push(p02);

    let p03: FormTextboxProperty = new FormTextboxProperty(
      'ControllerTitle',
      translate.instant('controlPanel.dwolla.title'),
      2,
      2,
      null,
      2,
      true
    );
    properties.push(p03);

    let p09: FormDateProperty = new FormDateProperty(
      'ControllerDateOfBirth',
      translate.instant('controlPanel.dwolla.dateOfBirth'),
      true,
      2,
      3,
      null,
      2,
      true
    );
    properties.push(p09);

    let p15: formMaskInputProperty = new formMaskInputProperty(
      'ControllerSSN',
      translate.instant('controlPanel.dwolla.ssnLast4'),
      2,
      4,
      null,
      2,
      true
    );
    properties.push(p15);
    p15.mask = '9999';
    p15.validationRegEx = regExLib.regex_4Digits;
    //

    let p04: FormTextboxProperty = new FormTextboxProperty(
      'ControllerAddressAddress1',
      translate.instant('controlPanel.dwolla.addressLine1'),
      3,
      0,
      null,
      6,
      true
    );
    properties.push(p04);

    let p05: FormTextboxProperty = new FormTextboxProperty(
      'ControllerAddressAddress2',
      translate.instant('controlPanel.dwolla.addressLine2'),
      3,
      1,
      null,
      6,
      false
    );
    properties.push(p05);

    let p06: FormTextboxProperty = new FormTextboxProperty(
      'ControllerAddressCity',
      translate.instant('controlPanel.dwolla.city'),
      4,
      0,
      null,
      3,
      true
    );
    properties.push(p06);

    let p07: formMaskInputProperty = new formMaskInputProperty(
      'ControllerAddressStateProvinceRegion',
      translate.instant('controlPanel.dwolla.state'),
      4,
      1,
      null,
      1,
      true
    );
    p07.mask = 'aa';
    properties.push(p07);

    let p08: formMaskInputProperty = new formMaskInputProperty(
      'ControllerAddressPostalCode',
      translate.instant('controlPanel.dwolla.postalCode'),
      4,
      2,
      null,
      1,
      true
    );
    p08.validationRegEx = regExLib.regex_5Digits;
    p08.mask = '99999';
    properties.push(p08);

    let p10: formMaskInputProperty = new formMaskInputProperty(
      'ControllerAddressCountry',
      translate.instant('controlPanel.dwolla.country'),
      4,
      3,
      null,
      1,
      true
    );
    p10.mask = 'aa';
    properties.push(p10);

    return properties;
  }

  private static getDwollaVerifiedCustomerBenefitialOwner(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p01: FormTextboxProperty = new FormTextboxProperty(
      'FirstName',
      translate.instant('controlPanel.dwolla.firstName'),
      2,
      0,
      null,
      3,
      true
    );
    properties.push(p01);

    let p02: FormTextboxProperty = new FormTextboxProperty(
      'LastName',
      translate.instant('controlPanel.dwolla.lastName'),
      2,
      1,
      null,
      3,
      true
    );
    properties.push(p02);

    let p09: FormDateProperty = new FormDateProperty(
      'DateOfBirth',
      translate.instant('controlPanel.dwolla.dateOfBirth'),
      true,
      2,
      3,
      null,
      2,
      true
    );
    properties.push(p09);

    let p15: formMaskInputProperty = new formMaskInputProperty(
      'SSN',
      translate.instant('controlPanel.dwolla.ssn'),
      2,
      4,
      null,
      2,
      true
    );
    p15.validationType = ValidationTypes.SSN_4existing_or_9new;
    p15.mask = '999999999';
    properties.push(p15);

    let p16: FormTextboxProperty = new FormTextboxProperty(
      'VerificationStatus',
      translate.instant('controlPanel.dwolla.verificationStatus'),
      2,
      4,
      null,
      2,
      false
    );
    p16.readonly = true;
    properties.push(p16);

    let p04: FormTextboxProperty = new FormTextboxProperty(
      'AddressAddress1',
      translate.instant('controlPanel.dwolla.addressLine1'),
      3,
      0,
      null,
      6,
      true
    );
    properties.push(p04);

    let p05: FormTextboxProperty = new FormTextboxProperty(
      'AddressAddress2',
      translate.instant('controlPanel.dwolla.addressLine2'),
      3,
      1,
      null,
      6,
      false
    );
    properties.push(p05);

    let p06: FormTextboxProperty = new FormTextboxProperty(
      'AddressCity',
      translate.instant('controlPanel.dwolla.city'),
      4,
      0,
      null,
      4,
      true
    );
    properties.push(p06);

    let p07: formMaskInputProperty = new formMaskInputProperty(
      'AddressStateProvinceRegion',
      translate.instant('controlPanel.dwolla.state'),
      4,
      1,
      null,
      2,
      true
    );
    p07.mask = 'aa';
    properties.push(p07);

    let p08: formMaskInputProperty = new formMaskInputProperty(
      'AddressPostalCode',
      translate.instant('controlPanel.dwolla.postalCode'),
      4,
      2,
      null,
      2,
      true
    );
    p08.validationRegEx = regExLib.regex_5Digits;
    p08.mask = '99999';
    properties.push(p08);

    let p10: formMaskInputProperty = new formMaskInputProperty(
      'AddressCountry',
      translate.instant('controlPanel.dwolla.country'),
      4,
      3,
      null,
      2,
      true
    );
    p10.mask = 'aa';
    properties.push(p10);

    return properties;
  }

  private static getDwollaCustomerFundingSource(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'Locations',
        translate.instant('controlPanel.dwolla.assignedLocations'),
        null,
        0,
        0,
        null,
        6,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'reporting.withhold.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions(
      'dwolla_gratuityLocationsFundingSource'
    ).then((res) => {
      p1.options = res;
    });
    properties.push(p1);

    let p01: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      translate.instant('controlPanel.dwolla.accountName'),
      1,
      0,
      null,
      3,
      true
    );
    properties.push(p01);

    let p02: FormDropdownProperty = new FormDropdownProperty(
      'BankAccountType',
      translate.instant('controlPanel.dwolla.accountType'),
      [],
      1,
      1,
      null,
      3,
      true
    );
    p02.options.push({ label: 'Checking', value: 'checking' });
    p02.options.push({ label: 'General-ledger', value: 'general-ledger' });
    p02.options.push({ label: 'Loan', value: 'loan' });
    p02.options.push({ label: 'Savings', value: 'savings' });
    p02.autoDisplayFirstDisabled = true;
    properties.push(p02);

    let p09: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('controlPanel.dwolla.accountNumber'),
      1,
      2,
      null,
      3,
      true
    );
    p09.validationType = ValidationTypes.DwollaFundingSource_AccountNumber;
    p09.validationMessage = 'Account number must contain 4-17 characters';
    properties.push(p09);

    let p15: formMaskInputProperty = new formMaskInputProperty(
      'RoutingNumber',
      translate.instant('controlPanel.dwolla.routingNumber'),
      1,
      3,
      null,
      3,
      true
    );
    p15.validationType = ValidationTypes.DwollaFundingSource_RoutingNumber;
    p15.mask = '999999999';
    properties.push(p15);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'BankName',
      translate.instant('controlPanel.dwolla.bankName'),
      2,
      0,
      null,
      3,
      false
    );
    p10.readonly = true;
    properties.push(p10);
    let p11: FormTextboxProperty = new FormTextboxProperty(
      'Type',
      translate.instant('controlPanel.dwolla.bankType'),
      2,
      1,
      null,
      3,
      false
    );
    properties.push(p11);
    p11.readonly = true;
    let p12: FormDateProperty = new FormDateProperty(
      'Created',
      translate.instant('controlPanel.dwolla.created'),
      true,
      2,
      2,
      null,
      3,
      false
    );
    p12.readonly = true;
    properties.push(p12);
    let p16: FormTextboxProperty = new FormTextboxProperty(
      'GsStatus',
      translate.instant('controlPanel.dwolla.status'),
      2,
      3,
      null,
      3,
      false
    );
    p16.readonly = true;
    properties.push(p16);

    let p17: FormButtonProperty = new FormButtonProperty(
      'VerifyMD',
      translate.instant('controlPanel.dwolla.verifyMD'),
      'fa-solid fa-badge-check',
      3,
      0,
      null,
      4,
      false
    );
    p17.visible = false;
    properties.push(p17);

    let p18: FormButtonProperty = new FormButtonProperty(
      'InitMD',
      translate.instant('controlPanel.dwolla.initMD'),
      'fa-solid fa-badge-check',
      3,
      0,
      null,
      4,
      false
    );
    p18.visible = false;
    properties.push(p18);
    return properties;
  }

  private static getDwollaVerifyMicrodepositProperties(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p01: FormTextboxProperty = new FormTextboxProperty(
      'Amount1',
      translate.instant('controlPanel.dwolla.amount1'),
      0,
      0,
      null,
      12,
      true
    );
    properties.push(p01);

    let p02: FormTextboxProperty = new FormTextboxProperty(
      'Amount2',
      translate.instant('controlPanel.dwolla.amount2'),
      1,
      0,
      null,
      12,
      true
    );
    properties.push(p02);

    return properties;
  }

  private static getDwollaCustomerBankAccounts(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p01: FormDropdownProperty = new FormDropdownProperty(
      'AccountId',
      translate.instant('controlPanel.dwolla.customerBankAccount'),
      null,
      0,
      1,
      null,
      12,
      true
    );
    properties.push(p01);

    return properties;
  }

  private static getDwollaAssignedLocations(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormLocationMultiselectProperty =
      new FormLocationMultiselectProperty(
        'Locations',
        translate.instant('controlPanel.dwolla.assignedLocations'),
        null,
        0,
        0,
        null,
        12,
        true
      );
    p1.defaultLabel = translate.instant(
      'general.locationMultiselectDefaultLabel'
    );
    p1.validationMessage = translate.instant(
      'reporting.withhold.filter.locationValidation'
    );
    PageObejctHelper.getDropdownOptions(
      'dwolla_gratuityLocationsFundingSource'
    ).then((res) => {
      p1.options = res;
    });
    properties.push(p1);
    return properties;
  }

  private static getDwollaEmailVerification(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'NotificationEmail',
      translate.instant('controlPanel.dwolla.email'),
      0,
      0,
      null,
      4,
      false
    );
    p1.tooltip =
      'In order to create funding source you must validate email address for receiving notifications regarding funding source activity';
    p1.validationRegEx = regExLib.regex_Email;

    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'VerificationCodeInsert',
      translate.instant('controlPanel.dwolla.verificationCode'),
      0,
      0,
      null,
      3,
      false
    );
    properties.push(p2);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'VerificationCode',
      translate.instant('controlPanel.dwolla.verificationCode'),
      0,
      0,
      null,
      2,
      false
    );
    p4.visible = false;
    p4.readonly = true;

    properties.push(p4);

    let p3: FormButtonProperty = new FormButtonProperty(
      'VerifyEmail',
      translate.instant('controlPanel.dwolla.verifyEmail'),
      null,
      0,
      1,
      null,
      1,
      false
    );
    properties.push(p3);

    let p5: FormButtonProperty = new FormButtonProperty(
      'SendVerificationCode',
      translate.instant('controlPanel.dwolla.sendVerificationCode'),
      null,
      0,
      1,
      null,
      3,
      false
    );
    properties.push(p5);

    return properties;
  }

  private static getTabapayReconciliationBankStatsFilter(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormDateProperty = new FormDateProperty(
      'DateFrom',
      translate.instant('tabapayreconciliation.datefrom'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'DateTo',
      translate.instant('tabapayreconciliation.dateto'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('tabapayreconciliation.load'),
      null,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p3);
    return properties;
  }

  private static getTabapayReconciledTransactionsFilter(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormDateProperty = new FormDateProperty(
      'DateFrom',
      translate.instant('tabapayreconciliation.datefrom'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'DateTo',
      translate.instant('tabapayreconciliation.dateto'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p2);

    let p4: FormMultiselectProperty = new FormMultiselectProperty(
      'MIDS',
      'Merchant',
      null,
      0,
      0,
      null,
      5,
      true
    );
    properties.push(p4);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('tabapayreconciliation.load'),
      null,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p3);
    return properties;
  }

  private static getTabapayReconciledTransactionsByCustomerFilter(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormDateProperty = new FormDateProperty(
      'DateFrom',
      translate.instant('tabapayreconciliation.datefrom'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'DateTo',
      translate.instant('tabapayreconciliation.dateto'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('tabapayreconciliation.load'),
      null,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p3);
    return properties;
  }

  private static getCompanyGroupDwolla(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'DwollaMultipleApplications',
      'Dwolla multiple applications',
      0,
      0,
      null,
      12
    );
    p1.leftLabel = true;
    properties.push(p1);
    return properties;
  }

  private static getTabapayUnreconciledTransactionsFilter(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormDateProperty = new FormDateProperty(
      'DateFrom',
      translate.instant('tabapayreconciliation.datefrom'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'DateTo',
      translate.instant('tabapayreconciliation.dateto'),
      false,
      0,
      0,
      null,
      2,
      false
    );

    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Load',
      translate.instant('tabapayreconciliation.load'),
      null,
      0,
      1,
      null,
      2,
      false
    );

    properties.push(p3);
    return properties;
  }

  private static getCustomerAdditionalParamsText(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInfoLabelProperty = new FormInfoLabelProperty(
      'Title',
      '',
      0,
      0,
      null,
      2,
      false
    );
    p1.readonly = true;
    p1.color = '#666666';
    p1.pureText = true;

    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Value',
      '',
      0,
      1,
      null,
      3,
      false
    );

    properties.push(p2);
    return properties;
  }

  private static getCustomerAdditionalParamsDecimal(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormInfoLabelProperty = new FormInfoLabelProperty(
      'Title',
      '',
      0,
      0,
      null,
      2,
      false
    );
    p1.readonly = true;
    p1.color = '#666666';
    p1.pureText = true;
    properties.push(p1);

    let p2: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'Value',
      '',
      0,
      2,
      0,
      1,
      null,
      3,
      false
    );

    properties.push(p2);
    return properties;
  }

  private static getCustomerAdditionalParamsDate(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInfoLabelProperty = new FormInfoLabelProperty(
      'Title',
      '',
      0,
      0,
      null,
      2,
      false
    );
    p1.readonly = true;
    p1.color = '#666666';
    p1.pureText = true;
    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'Value',
      '',
      true,
      0,
      1,
      null,
      3,
      false
    );

    properties.push(p2);
    return properties;
  }

  private static getCustomerAdditionalParamsPassword(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormInfoLabelProperty = new FormInfoLabelProperty(
      'Title',
      '',
      0,
      0,
      null,
      2,
      false
    );
    p1.color = '#666666';
    p1.readonly = true;
    p1.pureText = true;

    properties.push(p1);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'Value',
      '',
      0,
      1,
      null,
      3,
      false
    );
    p2.autocompleteOff = true;

    properties.push(p2);
    return properties;
  }

  private static getCompanyGroupCustomerAdditionalParams(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormMultiselectProperty = new FormMultiselectProperty(
      'CustomerAdditionaParamKeysID',
      '',
      null,
      0,
      0,
      null,
      6,
      false
    );

    PageObejctHelper.getDropdownOptions(
      'companygroup_customeradditionalparamkeys'
    ).then((res) => {
      p1.options = res;
    });

    properties.push(p1);
    return properties;
  }

  private static getCompanyGroup_basicDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      translate.instant('companyGroups.cgName'),
      0,
      0,
      null,
      11,
      true
    );
    p1.readonly = true;
    p1.validationMessage = 'Company group name is mandatory';
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'AccountID',
      translate.instant('companyGroups.accountID'),
      1,
      0,
      null,
      11,
      false
    );
    p2.readonly = true;
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Copy',
      'Copy',
      'fas fa-clone',
      1,
      1,
      null,
      1,
      false
    );
    p3.styleClass = 'blue-button';
    properties.push(p3);

    return properties;
  }

  private static getCompanyGroup_datasource(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormDropdownProperty = new FormDropdownProperty(
      'PosID',
      'POS',
      null,
      0,
      0,
      null,
      6,
      false
    );
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'SftpHost',
      'SFTP Host',
      1,
      0,
      null,
      6,
      true
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'SftpPort',
      'SFTP Port',
      2,
      0,
      null,
      6,
      true
    );
    properties.push(p3);

    let p4: FormPasswordProperty = new FormPasswordProperty(
      'username',
      'Username',
      3,
      0,
      null,
      6,
      true
    );
    p4.autocompleteOff = true;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'PeopleSoftFileName',
      'PeopleSoft filename',
      0,
      1,
      null,
      6,
      true
    );
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'HMSFileName',
      'HMS filename',
      1,
      1,
      null,
      6,
      true
    );
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'JobMappingFileName',
      'Job mapping filename',
      2,
      1,
      null,
      6,
      true
    );
    properties.push(p7);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'SftpPrivateKeyFileName',
      'SFTP private key filename',
      3,
      1,
      null,
      6,
      true
    );
    properties.push(p8);

    return properties;
  }

  private static getVisaChoice_customerMapping(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormMultiselectProperty = new FormMultiselectProperty(
      'Customers',
      'Customers',
      null,
      0,
      0,
      null,
      12,
      false
    );
    properties.push(p1);
    return properties;
  }

  private static getPlaid_Originators(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'CompanyName',
      'Company name',
      0,
      0,
      null,
      4,
      true
    );
    p1.validationMessage = 'Company name is mandatory';
    properties.push(p1);

    //let p2: FormTextboxProperty = new FormTextboxProperty(
    //  'OriginatorClientId',
    //  'Originator client ID',
    //  0,
    //  1,
    //  null,
    //  6,
    //  false
    //);
    //properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'TaxId',
      'Tax Id',
      0,
      1,
      null,
      4,
      true
    );
    p3.validationMessage = 'Tax Id is mandatory';
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'City',
      'City',
      0,
      2,
      null,
      4,
      true
    );
    p4.validationMessage = 'City is mandatory';
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'Street',
      'Street',
      1,
      0,
      null,
      4,
      true
    );
    p5.validationMessage = 'Street is mandatory';
    properties.push(p5);

    let p6: FormTextboxProperty = new FormTextboxProperty(
      'Region',
      'Region',
      1,
      1,
      null,
      4,
      true
    );
    p6.validationMessage = 'Region is mandatory';
    properties.push(p6);

    let p7: FormTextboxProperty = new FormTextboxProperty(
      'PostalCode',
      'Postal code',
      1,
      2,
      null,
      4,
      true
    );
    p7.validationMessage = 'Postal code is mandatory';
    properties.push(p7);

    let p8: FormTextboxProperty = new FormTextboxProperty(
      'Country',
      'Country',
      1,
      0,
      null,
      4,
      true
    );
    p8.validationMessage = 'Country is mandatory';
    properties.push(p8);

    let p9: FormTextboxProperty = new FormTextboxProperty(
      'Website',
      'Website',
      1,
      1,
      null,
      4,
      true
    );
    p9.validationMessage = 'Website is mandatory';
    properties.push(p9);

    let p10: FormTextboxProperty = new FormTextboxProperty(
      'NAICSCode',
      'NAICS Code',
      1,
      2,
      null,
      4,
      true
    );
    p10.validationRegEx = regExLib.regex_6Digits;
    p10.validationMessage = 'NAICS Code is mandatory';
    properties.push(p10);

    return properties;
  }

  private static getPlaid_CustomerMappings(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormMultiselectProperty = new FormMultiselectProperty(
      'Customers',
      'Customers',
      null,
      0,
      0,
      null,
      12,
      false
    );
    properties.push(p1);
    return properties;
  }

  private static getpayCardInstant(translate: TranslateService) {
    let properties: FormProperty[] = [];

    this.setLoadCardAllowedProperties(properties, translate);
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'AdditionalData',
      translate.instant('payCard.GroupdId'),
      1,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p1);

    let p2: FormPasswordProperty = new FormPasswordProperty(
      'API_KEY',
      translate.instant('payCard.apiKey'),
      2,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    p2.autocompleteOff = true;
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      translate.instant('payCard.LocationID'),
      3,
      0,
      null,
      this.DefaultColumnSpan,
      true
    );
    properties.push(p3);
    return properties;
  }

  private static getPlaidOriginatorsTransferVolume(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormNumberProperty = new FormNumberProperty(
      'CreditHighestAmount',
      'Credit highest amount',
      'decimal',
      2,
      2,
      0,
      0,
      null,
      2,
      true
    );
    p1.validationMessage = 'Credit highest amount is mandatory';
    properties.push(p1);

    let p2: FormNumberProperty = new FormNumberProperty(
      'CreditAverageAmount',
      'Credit average amount',
      'decimal',
      2,
      2,
      0,
      1,
      null,
      2,
      true
    );
    p2.validationMessage = 'Credit average amount is mandatory.';
    properties.push(p2);

    let p3: FormNumberProperty = new FormNumberProperty(
      'CreditMonthlyAmount',
      'Credit monthly amount',
      'decimal',
      2,
      2,
      0,
      2,
      null,
      2,
      true
    );
    p3.validationMessage = 'Credit monthly amount is mandatory';
    properties.push(p3);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'CreditFrequency',
      'Credit frequency',
      null,
      0,
      3,
      null,
      3,
      true
    );
    p4.validationMessage = 'Credit frequency is mandatory';
    p4.options = [];
    p4.options.push({ label: 'Please select', value: null });
    p4.options.push({ label: 'Daily', value: 'daily' });
    p4.options.push({ label: 'Once per week', value: 'once_per_week' });
    p4.options.push({ label: 'Twice per week', value: 'twice_per_month' });
    p4.options.push({ label: 'Once per month', value: 'once_per_month' });
    properties.push(p4);

    let p41: FormMultiselectProperty = new FormMultiselectProperty(
      'CreditSecCodes',
      'Credit Sec Codes',
      null,
      0,
      4,
      null,
      3,
      true
    );
    p41.validationMessage = 'Credit sec codes is mandatory';
    p41.options = [];
    p41.options.push({ label: 'Corporate Credit or Debit', value: 'ccd' });
    p41.options.push({ label: 'Prearranged Payment or Deposit', value: 'ppd' });
    p41.options.push({ label: 'Web', value: 'web' });
    properties.push(p41);

    let p5: FormNumberProperty = new FormNumberProperty(
      'DebitHighestAmount',
      'Debit highest amount',
      'decimal',
      2,
      2,
      1,
      0,
      null,
      2,
      true
    );
    p5.validationMessage = 'Debit highest amount is mandatory';
    properties.push(p5);

    let p6: FormNumberProperty = new FormNumberProperty(
      'DebitAverageAmount',
      'Debit average amount',
      'decimal',
      2,
      2,
      1,
      1,
      null,
      2,
      true
    );
    p6.validationMessage = 'Debit average amount is mandatory';
    properties.push(p6);

    let p7: FormNumberProperty = new FormNumberProperty(
      'DebitMonthlyAmount',
      'Debit monthly amount',
      'decimal',
      2,
      2,
      1,
      2,
      null,
      2,
      true
    );
    p7.validationMessage = 'Debit monthly amount is mandatory';
    properties.push(p7);

    let p8: FormDropdownProperty = new FormDropdownProperty(
      'DebitFrequency',
      'Debit frequency',
      null,
      1,
      3,
      null,
      3,
      true
    );
    p8.validationMessage = 'Debit frequency is mandatory';
    p8.options = [];
    p8.options.push({ label: 'Please select', value: null });
    p8.options.push({ label: 'Daily', value: 'daily' });
    p8.options.push({ label: 'Once per week', value: 'once_per_week' });
    p8.options.push({ label: 'Twice per week', value: 'twice_per_month' });
    p8.options.push({ label: 'Once per month', value: 'once_per_month' });
    properties.push(p8);

    let p9: FormMultiselectProperty = new FormMultiselectProperty(
      'DebitSecCodes',
      'Debit Sec codes',
      null,
      1,
      4,
      null,
      3,
      true
    );
    p9.validationMessage = 'Debit sec codes is mandatory';
    p9.options = [];
    p9.options.push({ label: 'Corporate Credit or Debit', value: 'ccd' });
    p9.options.push({ label: 'Prearranged Payment or Deposit', value: 'ppd' });
    p9.options.push({ label: 'Telephone-Initiated Entry', value: 'tel' });
    p9.options.push({ label: 'Internet-Initiated Entry', value: 'web' });
    properties.push(p9);

    return properties;
  }

  private static getpayCardPlaid(translate: TranslateService) {
    let properties: FormProperty[] = [];

    this.setLoadCardAllowedProperties(properties, translate);
    let p2: FormPasswordProperty = new FormPasswordProperty(
      'ClientSecret',
      'AccessToken',
      1,
      0,
      null,
      4,
      false
    );
    p2.readonly = true;
    p2.autocompleteOff = true;
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      'Originator client Id',
      2,
      0,
      null,
      4,
      false
    );
    p3.readonly = true;
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      'Account Id',
      3,
      0,
      null,
      4,
      false
    );
    p4.readonly = true;
    properties.push(p4);
    return properties;
  }

  private static getDataExportConnector(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      'Name',
      0,
      0,
      null,
      12,
      false
    );
    p1.validate = true;
    properties.push(p1);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'FK_GSClientType',
      'Client type',
      null,
      1,
      4,
      null,
      12
    );
    p2.visible = true;
    p2.validate = true;
    p2.validationMessage = 'Client type is mandatory';
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'ClientCode',
      'Client Code',
      3,
      0,
      null,
      12,
      false
    );
    p3.readonly = true;
    properties.push(p3);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'FK_ConnectorType',
      'Connector type',
      null,
      4,
      4,
      null,
      12
    );
    p4.validate = true;
    p4.validationMessage = 'Connector type is mandatory';
    properties.push(p4);

    return properties;
  }
  private static getDataExportConnector_credentials(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'FK_ConnectorCredentialsType',
      'Credentials type',
      null,
      1,
      4,
      null,
      12,
      false
    );
    p2.validate = true;
    p2.validationMessage = 'Credentials type is mandatory';

    properties.push(p2);

    return properties;
  }

  private static getDataExportDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'FK_GSClientType',
      'Client type',
      null,
      1,
      4,
      null,
      12,
      true
    );
    p2.validate = true;
    properties.push(p2);

    let p0: FormDropdownProperty = new FormDropdownProperty(
      'FK_DataExport',
      'Data export',
      null,
      1,
      4,
      null,
      12,
      true
    );
    p0.validate = true;
    properties.push(p0);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'ClientCode',
      'Client Code',
      3,
      0,
      null,
      12,
      false
    );
    p3.readonly = true;
    properties.push(p3);

    let p31: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      'Name',
      3,
      0,
      null,
      12,
      false
    );
    p31.validate = true;

    properties.push(p31);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'Connector.PK_ID',
      'Connector',
      null,
      4,
      0,
      null,
      8,
      true
    );
    p4.validationMessage = 'Connector is mandatory';
    properties.push(p4);
    const p42: FormTextboxProperty = new FormTextboxProperty(
      'ConnectorName',
      'Connector',
      4,
      0,
      null,
      8,
      true
    );
    p42.readonly = true;
    p42.visible = false;
    properties.push(p42);
    let p01: FormButtonProperty = new FormButtonProperty(
      'AddConnector',
      'Add',
      'pi pi-plus-circle',
      4,
      1,
      null,
      4,
      false
    );
    properties.push(p01);

    return properties;
  }

  private static getTAConfiguration(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'Fk_JobTitle',
      'Job',
      null,
      0,
      0,
      null,
      6,
      true
    );
    p1.validationMessage = 'This field is mandatory';
    properties.push(p1);

    let p2: FormNumberProperty = new FormNumberProperty(
      'RegularRate',
      'Regular Rate',
      'decimal',
      0,
      2,
      0,
      1,
      null,
      4,
      true
    );
    p2.align = 'right';
    p2.validationMessage = 'This field is mandatory';
    properties.push(p2);

    return properties;
  }

  private static getPlaidBankAccount(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      'Name',
      0,
      0,
      null,
      6,
      true
    );
    p1.readonly = true;
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'VerificationStatus',
      'Verification status',
      0,
      1,
      null,
      6,
      false
    );
    p2.readonly = true;
    properties.push(p2);

    return properties;
  }

  private static getVendorCredentialDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'ClientID',
      'Client ID',
      0,
      0,
      null,
      6,
      true
    );
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'ClientSecret',
      'Client secret',
      0,
      1,
      null,
      6,
      true
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'Description',
      'Description',
      1,
      0,
      null,
      6,
      true
    );
    properties.push(p3);

    let p5: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Active',
      'Active',
      1,
      1,
      0,
      5
    );
    properties.push(p5);

    return properties;
  }

  private static getVendorDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'Name',
      'Name',
      0,
      0,
      null,
      6,
      true
    );
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Phone',
      'Phone',
      0,
      1,
      null,
      6,
      false
    );
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'Address',
      'Address',
      1,
      0,
      null,
      6,
      false
    );
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'Url',
      'URL',
      1,
      1,
      null,
      6,
      false
    );
    properties.push(p4);

    let p5: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Active',
      'Active',
      2,
      0,
      0,
      6
    );
    properties.push(p5);

    return properties;
  }

  private static getPlaidOriginatorsFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormMultiselectProperty = new FormMultiselectProperty(
      'Customers',
      'Customers',
      null,
      0,
      0,
      null,
      6,
      true
    );
    properties.push(p1);

    let p2: FormButtonProperty = new FormButtonProperty(
      'Apply',
      'Apply',
      null,
      0,
      0,
      null,
      6,
      true
    );
    properties.push(p2);

    return properties;
  }

  private static getPlaidDebitTransactionsFilter(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormDateProperty = new FormDateProperty(
      'DateFrom',
      'Date from',
      null,
      0,
      0,
      null,
      3,
      true
    );
    properties.push(p1);

    let p2: FormDateProperty = new FormDateProperty(
      'DateTo',
      'Date to',
      null,
      0,
      0,
      null,
      3,
      true
    );
    properties.push(p2);

    let p3: FormButtonProperty = new FormButtonProperty(
      'Apply',
      'Apply',
      null,
      0,
      0,
      null,
      3,
      true
    );
    properties.push(p3);

    return properties;
  }

  private static getCardProviderNetworkFee(translate: TranslateService) {
    let properties: FormProperty[] = [];
    let p1: FormTextboxProperty = new FormTextboxProperty(
      'NetworkName',
      'Network',
      0,
      0,
      null,
      3,
      false
    );
    p1.readonly = true;
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Rails',
      'Rails',
      0,
      1,
      null,
      3,
      false
    );
    p2.readonly = true;
    properties.push(p2);

    let p3: FormNumberDecimalProperty = new FormNumberDecimalProperty(
      'Fee',
      'Fee',
      2,
      2,
      0,
      2,
      null,
      3,
      false
    );
    properties.push(p3);

    let p4: FormDropdownProperty = new FormDropdownProperty(
      'FeePayer',
      'Fee payer',
      null,
      0,
      3,
      null,
      3,
      true
    );
    p4.options = [];
    p4.options.push({ label: 'No fees', value: 0, isDisabled: true });
    p4.options.push({ label: 'Employee pays', value: 1, isDisabled: false });
    p4.options.push({ label: 'Employer pays', value: 2, isDisabled: false });
    properties.push(p4);

    return properties;
  }

  private static getEmployeeProfileCardAssignedActive(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p0: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Default',
      '',
      0,
      0,
      null,
      6
    );
    properties.push(p0);
    return properties;
  }

  private static setLoadCardAllowedProperties(properties, translate) {
    let px: FormDateProperty = new FormDateProperty(
      'LoadCardAllowedFrom',
      translate.instant('payCard.LoadCardAllowedFrom'),
      true,
      0,
      0,
      null,
      this.DefaultColumnSpan,
      false
    );
    properties.push(px);
    let px1: FormDateProperty = new FormDateProperty(
      'LoadCardAllowedFrom_Wage',
      translate.instant('payCard.LoadCardAllowedFromWage'),
      true,
      0,
      1,
      null,
      this.DefaultColumnSpan,
      false
    );
    properties.push(px1);
    let px2: FormDateProperty = new FormDateProperty(
      'LoadCardAllowedFrom_Commission',
      translate.instant('payCard.LoadCardAllowedFromCommision'),
      true,
      0,
      1,
      null,
      this.DefaultColumnSpan,
      false
    );
    properties.push(px2);
  }

  private static getPayCardTabaPayBank(translate: TranslateService) {
    let properties: FormProperty[] = [];
    this.setLoadCardAllowedProperties(properties, translate);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'LocationID',
      translate.instant('payCard.SubClientID'),
      1,
      0,
      null,
      this.DefaultColumnSpan * 1.5,
      false
    );
    p2.readonly = true;
    properties.push(p2);

    let p3: FormTextboxProperty = new FormTextboxProperty(
      'AccountNumber',
      translate.instant('payCard.AccountNumber'),
      1,
      2,
      null,
      this.DefaultColumnSpan * 1.5,
      false
    );
    p3.readonly = true;
    properties.push(p3);

    let p4: FormTextboxProperty = new FormTextboxProperty(
      'RTPAccountNumber',
      'RTP Account number',
      2,
      0,
      null,
      this.DefaultColumnSpan * 1.5,
      false
    );
    p4.readonly = true;
    properties.push(p4);

    let p5: FormTextboxProperty = new FormTextboxProperty(
      'RTPSubclientId',
      'RTP Sublclient ID',
      2,
      0,
      null,
      this.DefaultColumnSpan * 1.5,
      false
    );
    p5.readonly = true;
    properties.push(p5);

    return properties;
  }

  private static getWorkflowExecutionLog(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDateProperty = new FormDateProperty(
      'DateFrom',
      'Date from',
      true,
      0,
      0,
      0,
      2
    );
    properties.push(p1);

    let p11: FormDateProperty = new FormDateProperty(
      'DateTo',
      'Date to',
      true,
      0,
      1,
      0,
      2
    );
    properties.push(p11);

    let p2: FormMultiselectProperty = new FormMultiselectProperty(
      'WorkflowIDs',
      'WF Definition',
      null,
      0,
      2,
      0,
      2
    );
    p2.visible = true;
    properties.push(p2);

    let p3: FormMultiselectProperty = new FormMultiselectProperty(
      'ExecutionTypes',
      'Exec type',
      null,
      0,
      3,
      0,
      2
    );
    p3.visible = true;
    p3.options = [];
    p3.options.push({ label: 'Scheduled', value: 1 });
    p3.options.push({ label: 'Manual', value: 2 });
    p3.options.push({ label: 'WF Invoke', value: 3 });
    p3.options.push({ label: 'Admin', value: 4 });
    p3.options.push({ label: 'Manual generic', value: 5 });
    properties.push(p3);

    let p4: FormMultiselectProperty = new FormMultiselectProperty(
      'ExecutionStatuses',
      'Exec Status',
      null,
      0,
      4,
      0,
      2
    );
    p4.visible = true;
    p4.options = [
      { label: 'Initiated', value: 1 },
      { label: 'Pending', value: 2 },
      { label: 'Executing', value: 3 },
      { label: 'Finished', value: 4 },
      { label: 'Failed', value: 5 },
      { label: 'Aborted', value: 6 },
      { label: 'Warning', value: 7 },
    ];

    properties.push(p4);

    let p5: FormButtonProperty = new FormButtonProperty(
      'Apply',
      translate.instant('general.show'),
      'fas fa-eye',
      0,
      5,
      null,
      2,
      false
    );
    properties.push(p5);

    return properties;
  }

  private static getWorkflowDefinitionDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'ClientType',
      translate.instant('workflow.clientType'),
      null,
      0,
      1,
      0,
      12
    );
    p1.visible = true;
    p1.options = [];
    p1.options.push({ label: 'Customer', value: 'Customer' });
    p1.options.push({ label: 'Group ', value: 'Company Group' });
    p1.validate = true;
    p1.validationMessage = 'Client type is mandatory';
    properties.push(p1);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'Description',
      translate.instant('workflow.description'),
      1,
      0,
      null,
      12,
      true
    );
    p2.validationMessage = 'Description is mandatory';
    properties.push(p2);

    let p4: FormInputSwitchProperty = new FormInputSwitchProperty(
      'hasAdmin',
      translate.instant('workflow.includeAdmin'),
      2,
      0,
      null,
      6
    );
    properties.push(p4);

    let p5: FormInputSwitchProperty = new FormInputSwitchProperty(
      'hasManual',
      translate.instant('workflow.includeManual'),
      3,
      0,
      null,
      6
    );
    properties.push(p5);
    let p6: FormDropdownProperty = new FormDropdownProperty(
      'FunctionType',
      translate.instant('workflow.wfFunctionType'),
      null,
      3,
      1,
      0,
      6
    );
    p6.visible = false;
    p6.validationMessage = 'Function type is mandatory';
    properties.push(p6);

    let p3: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Active',
      translate.instant('workflow.active'),
      4,
      0,
      null,
      6
    );
    properties.push(p3);

    return properties;
  }
  private static getWorkflowDefinitionExecutionConfiguration(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'ExecutionType',
      translate.instant('workflow.executionType'),
      null,
      0,
      1,
      0,
      6
    );
    p1.visible = true;
    p1.options = [];
    p1.options.push({ label: 'Scheduled', value: 'Scheduled' });
    p1.options.push({ label: 'Manual', value: 'Manual' });
    properties.push(p1);

    let p2: FormDropdownProperty = new FormDropdownProperty(
      'TimeZone',
      translate.instant('workflow.timeZone'),
      null,
      0,
      1,
      0,
      6
    );
    properties.push(p2);

    let p3: FormTimeProperty = new FormTimeProperty(
      'DesiredLocalTime',
      'Desired Local Time',
      1,
      0,
      0,
      6
    );
    properties.push(p3);

    let p4: FormTimeProperty = new FormTimeProperty(
      'AvailableLocalTime',
      'Available Local Time',
      1,
      1,
      0,
      6
    );
    properties.push(p4);

    let p5: FormTimeProperty = new FormTimeProperty(
      'AvailableUTCTime',
      'Available UTC Time',
      2,
      0,
      0,
      6
    );
    properties.push(p5);

    let p6 = new FormNumberProperty(
      'MaxAttempts',
      'Max Attempts',
      'decimal',
      null,
      null,
      3,
      0,
      0,
      6,
      false
    );
    properties.push(p6);

    let p7: FormNumberProperty = new FormNumberProperty(
      'WaitBetweenAttempts',
      'Wait Between Attempts',
      'decimal',
      null,
      null,
      3,
      0,
      0,
      6,
      false
    );
    properties.push(p7);

    let p8: FormDropdownProperty = new FormDropdownProperty(
      'FK_ScheduleFrequency',
      translate.instant('workflow.scheduledFrequency'),
      null,
      4,
      0,
      0,
      6
    );
    properties.push(p8);

    let p9: FormNumberProperty = new FormNumberProperty(
      'ScheduleFrequencyValue',
      'Schedule Frequency Value',
      'decimal',
      null,
      null,
      4,
      1,
      0,
      6,
      false
    );
    properties.push(p9);

    let p10: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Active',
      translate.instant('workflow.active'),
      5,
      0,
      null,
      6
    );
    properties.push(p10);

    return properties;
  }
  private static getWorkflowTaskInvocationDetails(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p7: FormNumberProperty = new FormNumberProperty(
      'InvocationDetails.HeartbeatSeconds',
      'Timeout',
      'decimal',
      null,
      null,
      3,
      0,
      0,
      6,
      false
    );
    properties.push(p7);

    let p2: FormTextboxProperty = new FormTextboxProperty(
      'InvocationDetails.ResourceARN',
      'Resource Name',
      3,
      0,
      0,
      6,
      false
    );
    properties.push(p2);

    return properties;
  }

  private static getCompanyGroupSettings(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormDropdownProperty = new FormDropdownProperty(
      'CompanyGroupSettings.EmployeeSourceOfRecords',
      'Primary source of employee roaster data',
      null,
      0,
      0,
      0,
      3,
      true
    );
    p1.options = [];
    p1.options.push({ label: 'POS Data', value: 1 });
    p1.options.push({ label: 'User registration', value: 2 });
    properties.push(p1);

    let p2: FormInputSwitchProperty = new FormInputSwitchProperty(
      'CompanyGroupSettings.IgnoreOverdueBlock',
      'Do not block locations due to overdue invoices',
      1,
      0,
      null,
      3
    );
    properties.push(p2);

    return properties;
  }

  private static getEmployeeProfileCardAssignedEnableLoad(
    translate: TranslateService
  ) {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Active',
      'Enable load',
      0,
      0,
      0,
      3,
      false
    );
    p1.leftLabel = true;
    properties.push(p1);

    return properties;
  }

  private static getEmployeeProfileDetailsVirtual(translate: TranslateService) {
    let properties: FormProperty[] = [];

    let p1: FormInputSwitchProperty = new FormInputSwitchProperty(
      'Value',
      'Is Virtual Employee:',
      0,
      0,
      0,
      12,
      false
    );
    p1.leftLabel = true;
    p1.labelClass = 'label-is-virtual';
    properties.push(p1);

    return properties;
  }
}
