import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { GSCalendarControl } from '../../controls/gsControls';
import { EventEmitter } from '@angular/core';

export class FormDateProperty extends FormProperty {
  type = PropertyTypes.Date;
  component = GSCalendarControl;
  public showIcon: boolean;
  public readOnly: boolean;
  changed = new EventEmitter<any>();

  constructor(
    name: string, 
    label: string, 
    showIcon: boolean, 
    row?: number, 
    column?: number,
    rowspan?: number, 
    columnspan?: number, 
    validate?: boolean, 
    readOnly?: boolean,
    public inline?: boolean, 
    public minDate?: Date, 
    public maxDate?: Date, 
    public calendarView?: string,
    public dateFormat?: string, 
    public yearNavigator?: boolean, 
    public yearRange?: string, 
    public readonlyInput?: boolean, 
    public disabledDates?
  )
  {
    super(name, label, row, column, rowspan, columnspan, validate);
    this.showIcon = showIcon;
    this.readOnly = readOnly;
  }
}
