import { Injectable, Component } from '@angular/core';
//import { AppConfig } from "../../app.config";
import { CacheService } from 'ng2-cache';
import { BaseClient } from '../common/client/base.client';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PagedResultRequest, Session } from '../common/entity/entities';
import { TranslateService } from '@ngx-translate/core';
import { SvcClients } from '../common/client/SvcClients';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CustomerClient extends BaseClient {
  constructor() {
    super();
  }

  getCustomers(pagedResultRequest: PagedResultRequest, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/customers`,
      pagedResultRequest,
      message
    );
  }

  createCustomerCopy(
    customerID: number,
    copyPaymentSettings: boolean,
    copyPayrollSettings: boolean,
    deactivateExistingCustomer: boolean,
    message: string
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/CreateNewCustomerFromExisting?customerID=${customerID}&copyPaymentInfo=${copyPaymentSettings}&copyPayrollInfo=${copyPayrollSettings}&deactivateExistingCustomer=${deactivateExistingCustomer}`,
      message
    );
  }

  getCompanyGroups() {
    return this.get(SvcClients.AdminSvc, `CompanyGroup`);
  }
  getCompanyGroupsOrderByCode() {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetCompanyGroupsOrderByCode`,
      null
    );
  }
  addCompanyGroups(message: string, name: string) {
    return this.post(
      SvcClients.AdminSvc,
      `CompanyGroup?name=${name}&implementationType=1`,
      null,
      message
    );
  }

  getCompanyGroupDetails(code: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetCompanyGroupDetails?code=${code}`,
      null
    );
  }

  saveCompanyGroupDetails(model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/SaveCompanyGroupDetails`,
      model,
      null
    );
  }

  getImplementationTypes() {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetImplementationTypes`,
      null
    );
  }

  getNoEmailTicketMessages() {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetNoEmailTicketMessages`,
      null
    );
  }

  addCustomer(
    message: string,
    enrollmentID: string,
    groupId,
    implementationType?: number
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/createCustomer?customerEnrollmentID=${enrollmentID}&CompanyGroupID=${groupId}&implementationType=${implementationType}`,
      null,
      message
    );
  }

  getCustomerWizardDashboard(message: string, customerId: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/wizardDashboard?customerId=${customerId}`,
      message
    );
  }

  getCustomer(message: string, customerId: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/customer?customerId=${customerId}`,
      message
    );
  }

  getCompanyGroup(message: string, companyGroupId: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetCompanyGroup?companyGroupId=${companyGroupId}`,
      message
    );
  }

  deactivateCustomer(message: string, customerCode: number, deactivationData) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/deactivateCustomer?customerCode=${customerCode}`,
      deactivationData,
      message
    );
  }

  reactivateCustomer(message: string, customerCode: number) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/reactivateCustomer?customerCode=${customerCode}`,
      null,
      message
    );
  }

  removeCustomer(message: string, customerCode: number) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/removeCustomerFromList?customerCode=${customerCode}`,
      null,
      message
    );
  }

  setSurveyStatus(message: string, customerCode: number, active: boolean) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/setCustomerPollStatus?customerCode=${customerCode}&completed=${!active}`,
      null,
      message
    );
  }

  setWizardFlowFinishedStatus(
    message: string,
    customerCode: number,
    finished: boolean
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/setWizardFlowFinishedStatus?customerCode=${customerCode}&finished=${finished}`,
      null,
      message
    );
  }

  getCustomerSurveyReport(message: string, customerCode: number) {
    //this.openFile(`PayDayAdminPortal/getCustomerPollReport?customerCode=${customerCode}`);

    this.downloadFile(
      SvcClients.AdminSvc,
      `CustomerPoll/GetCustomerPollFile?customerCode=${customerCode}`
    );
    //return this.get(`PayDayAdminPortal/getCustomerPollReport?customerCode=${customerCode}`, message);
  }

  getCustomerInfo(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/customerInfo?customerCode=${customerCode}`,
      message
    );
  }

  getCompanyGroupInfo(message: string, companyGroupCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetCompanyGroupInfo?companyGroupCode=${companyGroupCode}`,
      message
    );
  }

  setCustomerInfo(message: string, customerInfo: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/setCustomerInfo`,
      customerInfo,
      message
    );
  }

  setCompanyGroupInfo(message: string, companyGroupInfo: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/SetCompanyGroupInfo`,
      companyGroupInfo,
      message
    );
  }

  getCustomersSearch(filter) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/getCustomersSearch?filter=${filter}`
    );
  }

  getCompanyGroupsSearch(filter: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/getCompanyGroupsSearch?filter=${filter}`
    );
  }

  sendWelcomeKit(message, customerCode) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/SendWelcomeKit?customerCode=${customerCode}`,
      null,
      message
    );
  }

  //#region-------BUSINESS LOCALIZATION
  getBusinessLocalization(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPBusinessLocalization/getBusinessLocalization?customerCode=${customerCode}`,
      message
    );
  }

  setBusinessLocalization(message: string, customerCode: number, data: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPBusinessLocalization/setBusinessLocalization?customerCode=${customerCode}`,
      data,
      message
    );
  }

  getTimeZones(message?: string) {
    //SET TO CACHE!!!!
    return this.get(
      SvcClients.AdminSvc,
      `TimeZones`,
      message,
      null,
      null,
      'TimeZones'
    );
  }
  //#endregion

  //#region-------INSTALLATION
  getDownloadLink(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `ConfigurationWizard/GetDownloadLink?customerCode=${customerCode}`,
      message
    );
  }

  initiateNewBuild(message: string, customerCode: number) {
    return this.put(
      SvcClients.AdminSvc,
      `ConfigurationWizard/InitiateNewBuild?customerCode=${customerCode}`,
      null,
      message
    );
  }

  customerConfigurationWizardFlow(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetCustomerConfigurationWizardFlow?customerCode=${customerCode}`,
      message
    );
  }

  //#endregion

  //#region-------DATASOURCE CONFIGURATION

  getDatasourceConfiguration(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDatasource/getDatasource?customerCode=${customerCode}`,
      message
    );
  }

  getPosList(forceGet?: boolean) {
    //return this.get(`PDAPDatasource/getPosList`, '', null, forceGet, 'POSList');
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains('Options', 'POS')
        ? true
        : false;
      if (forceGet)
        this.get(SvcClients.AdminSvc, `PDAPDatasource/getPosList`).then(
          (data) => {
            Session.cache.set('Options', 'POS', data);
            resolve(data);
          }
        );
      else resolve(Session.cache.get('Options', 'POS'));
    });
  }

  getSyncDataTypes(forceGet?: boolean) {
    //return this.get(`PDAPDatasource/getSyncDataTypes`, '', null, forceGet, 'SyncDataTypes');
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains('Options', 'SyncDataTypes')
        ? true
        : false;
      if (forceGet)
        this.get(SvcClients.AdminSvc, `PDAPDatasource/getSyncDataTypes`).then(
          (data) => {
            Session.cache.set('Options', 'SyncDataTypes', data);
            resolve(data);
          }
        );
      else resolve(Session.cache.get('Options', 'SyncDataTypes'));
    });
  }

  getGDAVendorList(forceGet?: boolean) {
    //return this.get(`PDAPDatasource/getGDAVendorList`, '', null, forceGet, 'GDAVendors');
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains('Options', 'GDAVendors')
        ? true
        : false;
      if (forceGet)
        this.get(SvcClients.AdminSvc, `PDAPDatasource/getGDAVendorList`).then(
          (data) => {
            Session.cache.set('Options', 'GDAVendors', data);
            resolve(data);
          }
        );
      else resolve(Session.cache.get('Options', 'GDAVendors'));
    });
  }

  generateDefaultSyncTimes(
    message: string,
    customerCode: string,
    datasourceConfiguration
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDatasource/generateDefautlSyncTimes?customerCode=${customerCode}`,
      datasourceConfiguration,
      message
    );
  }

  setDatasourceConfiguration(
    message: string,
    customerCode: string,
    datasourceConfiguration: any
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDatasource/setDatasourceConfiguration?customerCode=${customerCode}`,
      datasourceConfiguration,
      message
    );
  }
  //#endregion

  //#region TIME PROVIDER

  getTimeProvider(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPTimeProvider/GetTimeProvider?customerCode=${customerCode}`,
      message
    );
  }

  setTimeProvider(message: string, customerCode: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTimeProvider/setTimeProvider?customerCode=${customerCode}`,
      model,
      message
    );
  }
  //#endregion

  //#region TENDER MAPPING

  getTenderMapping(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPTenderMapping/GetTenderMapping?customerCode=${customerCode}`,
      message
    );
  }

  setTenderMapping(message: string, customerCode: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTenderMapping/setTenderMapping?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getTenderTypes(forceGet?: boolean) {
    //return this.get(`PDAPDatasource/getPosList`, '', null, forceGet, 'POSList');
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains('Options', 'TenderTypes')
        ? true
        : false;
      if (forceGet)
        this.get(SvcClients.AdminSvc, `PDAPTenderMapping/getTenderTypes`).then(
          (data) => {
            Session.cache.set('Options', 'TenderTypes', data);
            resolve(data);
          }
        );
      else resolve(Session.cache.get('Options', 'TenderTypes'));
    });
  }
  //#endregion

  //#region TRACKING GROUPS

  getTrackingGroups(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPTrackingGroups/GetTrackingGroups?customerCode=${customerCode}`,
      message
    );
  }

  getCategory(message: string, customerCode: string, groupId: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPTrackingGroups/getCategory?customerCode=${customerCode}&groupId=${groupId}`,
      message
    );
  }

  setTrackingGroups(message: string, customerCode: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTrackingGroups/setTrackingGroups?customerCode=${customerCode}`,
      model,
      message
    );
  }

  customerSyncData(message: string, customerCode: number, model) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerSyncDateCustom?customerCode=${customerCode}`,
      model,
      message,
      null,
      'resourceUrl'
    );
  }
  //#endregion

  //#region JOB CONFIGURATION

  getJobConfiguration(message: string, customerCode: number, dateFrom: Date) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPJobConfiguration/getJobConfiguration?customerCode=${customerCode}&dateFrom=${dateFrom}`,
      message
    );
  }

  setJobConfiguration(message: string, customerCode: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPJobConfiguration/setJobConfiguration?customerCode=${customerCode}`,
      model,
      message
    );
  }

  //#endregion

  //#region JOB ELECTION

  getJobElection(
    message: string,
    customerCode: number,
    dateFrom: Date | null,
    typeOfDistribution: number
  ) {
    const formattedDate = dateFrom
      ? `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
      : '';

    const url =
      `PDAPJobElection/GetJobElection?customerCode=${customerCode}&typeOfDistribution=${typeOfDistribution}` +
      (formattedDate ? `&dateFrom=${formattedDate}` : '');

    return this.get(SvcClients.AdminSvc, url, message);
  }

  addPool(message: string, customerCode: number, model) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPJobElection/AddNewPool?customerCode=${customerCode}`,
      model,
      message
    );
  }

  setJobElection(message: string, customerCode: number, model) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPJobElection/SetJobElection?customerCode=${customerCode}`,
      model,
      message
    );
  }

  //#endregion

  //#region GLOBAL CONFIGURATION

  getGlobalConfiguration(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPGlobalConfiguration/getGlobalConfiguration?customerCode=${customerCode}`,
      message
    );
  }

  getAllGlobalConfigurations(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPGlobalConfiguration/getAllGlobalConfigurations?customerCode=${customerCode}`,
      message
    );
  }

  setGlobalConfiguration(message: string, customerCode: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPGlobalConfiguration/setGlobalConfiguration?customerCode=${customerCode}`,
      model,
      message
    );
  }
  //#endregion

  //#region PAY CARD
  getPayCardSelection(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPayCard/GetPayCardSelection?customerCode=${customerCode}`,
      message
    );
  }

  getWiselyLocations(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPayCard/GetWiselyLocations?customerCode=${customerCode}`,
      message
    );
  }

  getWiselyAccounts(message: string, customerCode: number, locationID: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPayCard/GetWiselyAccounts?customerCode=${customerCode}&LocationID=${locationID}`,
      message
    );
  }

  setPayCardSelection(message: string, customerCode: number, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPPayCard/SetPayCardSelection?customerCode=${customerCode}`,
      model,
      message
    );
  }

  //#endregion

  //#region PRICING & BILLING
  getPricingAndBilling(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPricingAndBilling/GetPricingAndBilling?customerCode=${customerCode}`,
      message
    );
  }

  rollOut(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPricingAndBilling/RollOut?customerCode=${customerCode}`,
      message
    );
  }

  addLocation(message: string, customerCode: number, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPPricingAndBilling/CreateBillingInfo?customerCode=${customerCode}`,
      model,
      message
    );
  }

  setPricingAndBilling(message: string, customerCode: number, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPPricingAndBilling/SetPricingAndBilling?customerCode=${customerCode}`,
      model,
      message
    );
  }
  //#endregion

  //#region DISTRIBUTION

  getDistribution(
    message: string,
    customerCode: number,
    typeOfDistribution: number,
    dateFrom: Date | null
  ) {
    const formattedDate = dateFrom
      ? `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`
      : '';

    const url =
      `PDAPDistribution/getDistribution?customerCode=${customerCode}&typeOfDistribution=${typeOfDistribution}` +
      (formattedDate ? `&dateFrom=${formattedDate}` : '');

    return this.get(SvcClients.AdminSvc, url, message);
  }

  getDistributionException(
    message: string,
    customerCode: number,
    disributionRuleId: number
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDistribution/getDistributionException?customerCode=${customerCode}&disributionRuleId=${disributionRuleId}`,
      message
    );
  }

  setDistribution(message: string, customerCode: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDistribution/setDistribution?customerCode=${customerCode}`,
      model,
      message
    );
  }

  setDistributionException(
    message: string,
    customerCode: string,
    disributionRuleId: string,
    model: any
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDistribution/setDistributionException?customerCode=${customerCode}&disributionRuleId=${disributionRuleId}`,
      model,
      message
    );
  }

  getCalculationTypes(customerCode, forceGet?: boolean) {
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains('Distribution', `calcTypes_${customerCode}`)
        ? true
        : false;
      if (forceGet)
        this.get(
          SvcClients.AdminSvc,
          `PDAPDistribution/getCalculationTypes?customerCode=${customerCode}`
        ).then((data) => {
          Session.cache.set(
            'Distribution',
            `calcTypes_${customerCode}`,
            data?.result
          );
          resolve(data?.result);
        });
      else
        resolve(Session.cache.get('Distribution', `calcTypes_${customerCode}`));
    });
  }

  getActivePoolGroups(
    customerCode,
    dateFrom,
    typeOfDistribution,
    forceGet?: boolean
  ) {
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains(
            'Distribution',
            `poolGroups_${customerCode}_typeOfDistribution_${typeOfDistribution}`
          )
        ? true
        : false;
      if (forceGet)
        this.get(
          SvcClients.AdminSvc,
          `PDAPDistribution/getActivePoolGroups?customerCode=${customerCode}&dateFrom=${dateFrom}&typeOfDistribution=${typeOfDistribution}`
        ).then((data) => {
          Session.cache.set(
            'Distribution',
            `poolGroups_${customerCode}_typeOfDistribution_${typeOfDistribution}`,
            data?.result
          );
          resolve(data?.result);
        });
      else
        resolve(
          Session.cache.get(
            'Distribution',
            `poolGroups_${customerCode}_typeOfDistribution_${typeOfDistribution}`
          )
        );
    });
  }
  //#endregion

  //#region PAYROLL
  // getPayroll(message: string, customerCode: number) {
  //   return this.get(
  //     SvcClients.AdminSvc,
  //     `PDAPPayroll/GetPayroll?customerCode=${customerCode}`,
  //     message
  //   );
  // }

  // getPayrollProviders(payrollTypeID) {
  //   return this.get(
  //     SvcClients.AdminSvc,
  //     `PDAPPayroll/GetPayrolProviders?payrollTypeID=${payrollTypeID}`,
  //     null
  //   );
  // }

  // setPayroll(message: string, customerCode: number, model: any) {
  //   return this.post(
  //     SvcClients.AdminSvc,
  //     `PDAPPayroll/SetPayroll?customerCode=${customerCode}`,
  //     model,
  //     message
  //   );
  // }
  //#endregion

  //#region FUNCTION
  getFunction(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPFunction/getFunction?customerCode=${customerCode}`,
      message
    );
  }

  setFunction(message: string, customerCode, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPFunction/setFunction?customerCode=${customerCode}`,
      model,
      message
    );
  }
  //#endregion

  //#region-------ALARMS

  getAlarms(message: string, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPAlarms/getAlarms?customerCode=${customerCode}`,
      message
    );
  }

  getAlarmTypes(customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPAlarms/getAlarmTypes?customerCode=${customerCode}`
    );
  }

  setAlarms(message: string, customerCode, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPAlarms/setAlarms?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getJobTitles(customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPAlarms/getJobTitles?customerCode=${customerCode}`
    );
  }

  getAutoTimeCardConfig(customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPAutoTimeCard/GetAutoTimeCardConfiguration?customerCode=${customerCode}`
    );
  }

  getEmployees(customerCode: number) {
    return this.get(
      SvcClients.ResourceSvc,
      `Employees/GetEmpployees?customerCode=${customerCode}`
    );
  }

  getVirtualEmployees(customerCode: number) {
    return this.get(
      SvcClients.ResourceSvc,
      `Employees/GetVirtualEmployees?customerCode=${customerCode}`
    );
  }

  setAutoTimeCardCofnig(customerCode: number, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPAutoTimeCard/SetAutoTimeCardConfiguration?customerCode=${customerCode}`,
      model
    );
  }

  setVirtualEmployees(customerCode: number, model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `Employees/SetVirtualEmployees?customerCode=${customerCode}`,
      model
    );
  }

  //#endregion

  //getActiveCustomers(filter) {
  //  return this.get(SvcClients.AdminSvc,`PayDayAdminPortal/getActiveCustomers?filter=${filter}`);
  //}

  getCustomerPageObject(customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/getCustomerPageObject?customerCode=${customerCode}`
    );
  }

  getMultiCustomerPageObject(customerCodes: number[]) {
    return this.post(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/getMultiCustomerPageObject?customerCode=${customerCodes[0]}`,
      customerCodes
    );
  }
  //#endregion

  //#region POLICY
  getCustomerPolicyURL(customerCode: number, forEmployee: boolean) {
    return this.get(
      SvcClients.AdminSvc,
      `CustomerPolicy/GetPolicyUrl?customerCode=${customerCode}&employeePolicy=${forEmployee}`
    );
  }

  //#endregion

  //#region -------USER MANUAL
  getUserManuals(message, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDPHelp/GetUserManuals?customerCode=${customerCode}`,
      message
    );
  }

  setUserManuals(
    message,
    model,
    customerCode: number,
    showUserManual: boolean,
    comment: string
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDPHelp/SetUserManual?customerCode=${customerCode}&isVisible=${showUserManual}&comment=${comment}`,
      model,
      message
    );
  }
  //#endregion

  //#region PAYROLL EXPORT TEMPLATE
  getPayrollExportTemplateConfig(message, customerCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPayrollExportTemplateConfig/GetPayrollReportTemplateConfig?customerCode=${customerCode}`,
      message
    );
  }

  setPayrollExportTemplateConfig(message, model: any, customerCode: number) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPPayrollExportTemplateConfig/SetPayrollReportTemplateConfig?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getDeactivationMessages() {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetDeactivationMessages`
    );
  }

  //#endregion

  //#region PAYMENT INTEGRATIONS
  getVisaChoiceApplications(request, message) {
    return this.post(
      SvcClients.AdminSvc,
      'PDAPPaymentIntegrations/GetVisaChoiceApplications',
      request,
      message
    );
  }

  setVisaChoiceApplicationStatus(
    message: string,
    customerCode: number,
    id: number,
    newStatus: number
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPaymentIntegrations/SetVisaChoiceApplicationStatus?customerCode=${customerCode}&visaChoiceApplicationId=${id}&newStatus=${newStatus}`,
      message
    );
  }

  sendVisaChoiceApplication(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      'PDAPPaymentIntegrations/SendVisaChoiceApplication',
      model,
      message
    );
  }

  deleteAdditionalDocument(
    message: string,
    visaChoiceApplicationID: number,
    id: number
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPaymentIntegrations/DeleteAdditionalDocument?visaChoiceApplicationID=${visaChoiceApplicationID}&additionalDocumentId=${id}`,
      message
    );
  }

  saveAdditionalDocument(message: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPPaymentIntegrations/SaveAdditionalDocument`,
      model,
      message
    );
  }
  //#endregion

  getTabapayCustomersByCompanyGroupCode(message: string, companyCode: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetTabapayCustomersByCompanyGroupCode?companyGroupCode=${companyCode}`,
      message
    );
  }

  getTabapayCompanyGroups(message: string) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetTabapayCompanyGroups`,
      message
    );
  }

  //#region QUICKBOOKS
  getCustomerGratSyncFeeForPeriod(message: string, date: any) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPQuickbooks/GetCustomerGratSyncFeeForPeriod?date=${date}`,
      message
    );
  }
  processInvoice(message: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPQuickbooks/ProcessInvoice`,
      model,
      message
    );
  }
  //#endregion

  getLegalDocuments(message: string) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetS3LegalDocuments`,
      message
    );
  }

  getChecksAutoTransferConfig(customerCode: number, message: string) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPChecksAutoTransfer/GetChecksAutoTransferConfig?customerCode=${customerCode}`,
      message
    );
  }
  saveChecksAutoTransferConfig(
    customerCode: number,
    config: any,
    message: string
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPChecksAutoTransfer/SaveChecksAutoTransferConfig?customerCode=${customerCode}`,
      config,
      message
    );
  }

  getDwollaVerifiedCustomerAgreement(
    message: string,
    customerGroupCode: number,
    customerCode: number
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `LinkBanks/GetDwollaVerifiedCustomerAgreement?customerGroupCode=${customerGroupCode}&customerCode=${customerCode}`,
      message
    );
  }

  saveDwollaVerifiedCustomerAgreement(
    message: string,
    customerGroupCode: number,
    customerCode: number
  ) {
    var agree: boolean = true;
    return this.get(
      SvcClients.ResourceSvc,
      `LinkBanks/SetDwollaVerifiedCustomerAgreement?Agree=${agree}&companyGroupCode=${customerGroupCode}&customerCode=${customerCode}`,
      message
    );
  }

  getCustomerAdditionalParamKeys(message: string) {
    return this.get(
      SvcClients.AdminSvc,
      `PayDayAdminPortal/GetCustomerAdditionalParamKeys`,
      message
    );
  }

  getDataExport(customerCode, message?) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetDataExport?customerCode=${customerCode}`,
      message
    );
  }

  getGSClientTypes(message?) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetGSClientTypes`,
      message
    );
  }

  getConnectorCredentialTypeParameters(
    connectorCredentialTypeId,
    connectorCfgId?
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/getConnectorCredentialTypeParameters?connectorCredentialTypeId=${connectorCredentialTypeId}&connectorCfgId=${connectorCfgId}`
    );
  }

  getConnectorTypes(dataExportId?, message?) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetConnectorTypes?dataExportId=${dataExportId}`,
      message
    );
  }

  getConnectorCredentialTypes(message?) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetConnectorCredentialTypes`,
      message
    );
  }

  getDataExports(gsClientTypeId, clientCode, message?) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetDataExports?gsClientTypeId=${gsClientTypeId}&clientCode=${clientCode}`,
      message
    );
  }

  getGSClientConnectorConfigs(
    dataExportId,
    clientCode,
    gsClientTypeId,
    message?
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetGSClientConnectorConfigs?dataExportId=${dataExportId}&clientCode=${clientCode}&gsClientTypeId=${gsClientTypeId}`,
      message
    );
  }

  getDataExportColumns(dataExportId, message?) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetDataExportColumns?dataExportId=${dataExportId}`,
      message
    );
  }

  getDataExportLog(model, message?) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetGSClientDataExportLogs`,
      model
    );
  }

  getDataExportLogFile(filepath: string) {
    //this.openFile(`PayDayAdminPortal/getCustomerPollReport?customerCode=${customerCode}`);

    this.downloadFile(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetDataExportLogFile?filepath=${filepath}`
    );
    //return this.get(`PayDayAdminPortal/getCustomerPollReport?customerCode=${customerCode}`, message);
  }

  getConnectorDetails(
    data: {
      connectorTypeId?;
      credentialTypeId?;
      connectorCfgId?;
      dataExportCfgId?;
    },
    message?
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetGSClientConnectorConfig?connectorTypeId=${data.connectorTypeId}&credentialTypeId=${data.credentialTypeId}&connectorCfgId=${data.connectorCfgId}&dataExportCfgId=${data.dataExportCfgId}`,
      message
    );
  }

  getDataExportDetails(data, message?) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetGSClientDataExportConfig`,
      data,
      message
    );
  }

  setDataExport(customerCode, data) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDataExport/SetDataExport?customerCode=${customerCode}`,
      data
    );
  }

  getPosListForCompanyGroup(forceGet?: boolean) {
    //return this.get(`PDAPDatasource/getPosList`, '', null, forceGet, 'POSList');
    return new Promise<any>((resolve) => {
      forceGet = forceGet
        ? forceGet
        : !Session.cache.contains('Options', 'POSForCompanyGroup')
        ? true
        : false;
      if (forceGet)
        this.get(
          SvcClients.AdminSvc,
          `PDAPDatasource/getPosListForCompanyGroup`
        ).then((data) => {
          Session.cache.set('Options', 'POSForCompanyGroup', data);
          resolve(data);
        });
      else resolve(Session.cache.get('Options', 'POSForCompanyGroup'));
    });
  }

  getCompanyGroupDatasourceConfiguration(
    message: string,
    companyGroupCode: number
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPDatasource/GetCompanyGroupDatasource?companyGroupCode=${companyGroupCode}`,
      message
    );
  }

  setCompanyGroupDatasourceConfiguration(
    message: string,
    companyGroupCode: number,
    datasourceConfiguration: any
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPDatasource/SetCompanyGroupDatasource?companyGroupCode=${companyGroupCode}`,
      datasourceConfiguration,
      message
    );
  }

  getVendors() {
    return this.get(SvcClients.AdminSvc, `PDAPVendor/GetVendors`);
  }

  setVendors(data) {
    return this.post(SvcClients.AdminSvc, `PDAPVendor/SetVendor`, data);
  }

  getVendorCredentials(vendorId) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPVendor/GetVendorCredentials?vendorId=${vendorId}`
    );
  }

  getScopeTypes() {
    return this.get(SvcClients.AdminSvc, `PDAPVendor/GetScopeTypes`);
  }

  getPermissions() {
    return this.get(SvcClients.AdminSvc, `PDAPVendor/GetPermissions`);
  }

  getVendorCredentialDetails(vendorCredentialId) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPVendor/GetVendorCredentialsDetails?vendorCredentialId=${vendorCredentialId}`
    );
  }

  getDataExportsForREstriction(customerCode) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5EmployeeProfile/GetDataExportsForRestriction?customerCode=${customerCode}`
    );
  }

  getCardProviderConfigNetworkFees(message, customerCode, cardProvider) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPPayCard/GetCardProviderConfigNetworkFees?customerCode=${customerCode}&cardProvider=${cardProvider}`
    );
  }

  getUserLegalDocuments(message?: string) {
    return this.get(SvcClients.AdminSvc, `UserLegal`, message);
  }

  getOverdueInvoices(message?: string) {
    return this.get(SvcClients.ResourceSvc, `Billing`, message);
  }

  getWorkflowsExecutionPlan(customerCode) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPWorkflows/GetWorkflowExecutionPlan?customerCode=${customerCode}`,
      null
    );
  }

  getWorkflowsExecutionLog(wfClientExecutionLogRequest: any) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPWorkflows/GetWorkflowClientExecutionLog`,
      wfClientExecutionLogRequest
    );
  }

  getWorkflowsTaskExecutionLog(wfClientExecutionID: any) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPWorkflows/GetWorkflowTaskClientExecutionLog?workflowClientExecutionLogId=${wfClientExecutionID}`,
      null
    );
  }

  getWorkflowsTaskExecutionLogDetails(wfTaskClientExecutionID: number) {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPWorkflows/GetWorkflowTaskClientExecutionLogDetails?workflowTaskClientExecutionID=${wfTaskClientExecutionID}`,
      null
    );
  }

  getStatusMessages() {
    return this.get(
      SvcClients.ResourceSvc,
      `ServiceStatusMessage/GetActiveWithType`,
      null
    );
  }

  rerunWorkflowClientExecution(processID: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPWorkflows/ReRunWorkflowClientExecution`,
      processID,
      undefined,
      undefined,
      true,
      null,
      false,
      processID
    );
  }

  rerunWorkflowTaskClientExecution(
    wfClientExecutionId: number,
    processID: string
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPWorkflows/ReRunWorkflowTaskClientExecution`,
      wfClientExecutionId,
      undefined,
      undefined,
      true,
      null,
      false,
      processID
    );
  }

  branchWalletStatusUpdate(model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `Branch/BranchWalletStatusUpdate`,
      model
    );
  }

  branchWalletCreate(model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `Branch/BranchWalletLinkOrCreate`,
      model
    );
  }
}
