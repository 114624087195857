import { UIElement, Session, Item } from '../entity/entities';
import { DestroySubscribers } from '../decorators/decorators';
import { Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
@DestroySubscribers()
export class BaseComponent {
  public itemName;
  public menuItemName;
  destroy$ = new Subject<void>();

  get menuItem() {
    return Session?.menu?.menuItems.get(this.menuItemName);
  }

  get items() {
    return this.menuItem?.items.values;
  }

  get selected() {
    return this.menuItem?.items.selected;
  }

  public hasItem() {
    return this.selected?.items.keys?.some((k) => k == this.itemName);
  }

  get item() {
    return this.selected?.items.get(this.itemName);
  }

  get formData() {
    return this.item?.data;
  }

  ngOnInit() {
    this.menuItem.items.onSelected.subscribe(this.itemSelected, this);
  }

  ngOnDestroy() {
    this.menuItem?.items.onSelected.unsubscribe(this.itemSelected);
  }

  //#region -------Event Handlers
  //itemSelected;
  itemSelected = (item: Item) => {
    if (!item.items.keys.some((k) => k == this.itemName)) {
      this.loadData(item);
    }
    else {
      this.selectedItem(item);
    }
  };
  //#endregion

  //this method is overriden in each class
  public loadData(item: Item) {}

  public selectedItem(item: Item) {}
}
