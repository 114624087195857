import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, Inject, Optional, Injectable, ViewChildren, ElementRef, AfterContentInit, AfterContentChecked, QueryList, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item } from '../entity/entities';
import { FormLocationMultiselectProperty } from '../entity/form/formLocationMultiselect.property';

@Component({
  selector: 'gs-location-multiselect',
  templateUrl: './gs.locationMultiselect.control.html'
})
@Injectable()
export class GSLocationMultiselectControl extends GSBaseControl implements OnInit, OnDestroy, AfterContentChecked {
  //#region -------Properties

  get options() { return this.property.options }

  @ViewChildren('selectOption') selectOption: QueryList<ElementRef>;
  optionHandled = false;
  _selectAll;

  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormLocationMultiselectProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    (this.property as FormLocationMultiselectProperty).optionsSet.subscribe(this.preselect);
    if ((this.property as FormLocationMultiselectProperty).options)
      this.preselect();
  }

  ngAfterContentChecked() {
    if (this.selectOption && this.selectOption.toArray().length) {
      if (!this.optionHandled) {
        this.selectOption.toArray().filter(e => e.nativeElement.children[0].classList.contains("disabled_opacity-no-color")).forEach(e => {
          e.nativeElement.parentElement.classList.add("no-event");
          e.nativeElement.parentElement.children[0].classList.add("disabled_opacity-no-color");
        })        
        this.optionHandled = true;
      }
    } else
      this.optionHandled = false;
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  preselect = () => {   
    if (this.property.options && this.property.options.length == 1) {
      if (!(this.property.options[0].data.Disabled || this.property.options[0].data.CustomerBlocked)) {
        this.value = [this.property.options[0].value];
        (this.item as Item).reValidate();
        (this.item as Item).reCheckDirty();

        this.onChange.emit({ propertyName: this.name, previousValue: null, newValue: this.value, event: {}, item: this.item, property: this.property });
        this.property.changed.emit({ propertyName: this.name, previousValue: null, newValue: this.value, event: {}, item: this.item, property: this.property });
      }
    }
  }


  change(e) {
    if (e) {      
      if (e.itemValue && (this.options.find(o => o.data.Code == e.itemValue).data.Disabled || this.options.find(o => o.data.Code == e.itemValue).data.CustomerBlocked)) {
        this.value = [];
        setTimeout(() => {
          if (e.value.length > 0)
            e.value.splice(this.value.length - 1, 1);
            this.value = e.value;
        }, 150);
        return;
      }
      if (!e.itemValue)
        this._selectAll = !this._selectAll;
      let previousValue = this.data[this.name];
      this.value = e.itemValue ? e.value : (this._selectAll ? e.value.filter(x => this.options.filter(o => !o.data.Disabled && !o.data.CustomerBlocked).map(o=>o.data.Code).indexOf(x)>-1):[]);
      this.item.reCheckDirty();
      this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property});
      this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    }
  }

  //#endregion

  //#region -------Private
  //#endregion
}
