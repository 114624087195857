import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ComponentFactoryResolver,
  NgModuleRef,
  Injector,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PropertyTypes } from '../entity/enums';
import { FormProperty } from '../entity/form/formProperty';
import { FormRow } from '../entity/form/formRow';
import { TranslateService } from '@ngx-translate/core';
import { GSPlaceholder } from '../directives/placeholder';
import { Item } from '../entity/entities';
import { FormBuilder } from '../builder/gs.form.builder';
import { FormTypes } from '../entity/enum/formTypes';

@Component({
  selector: 'gs-form',
  templateUrl: './gs.form.html',
})
export class GSForm implements OnInit, OnDestroy {
  //#region -------Properties
  private _item: Item;
  @Input()
  get item() {
    return this._item;
  }
  set item(value) {
    if (this._item != value) {
      this._item = value;
      if (this.properties) this.renderProperties(this.properties);
    }
  }
  @Input() properties: FormProperty[];
  @Input() set propertiesType(val: FormTypes) {
    if (val) this.properties = FormBuilder.build(val, this.translate);
  }
  @Input() showSubmit: boolean = false;
  @Input() submitLabel: string = this.translate.instant('general.apply');
  @Input() submitIcon = 'fas fa-save';
  @Input() submitPostion = 'center'; //center, right, left options
  @Input() disableSubmit = false;

  @Output() onSubmit = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();

  PropertyTypes = PropertyTypes;
  rows = [];

  get isValid() {
    if (this.item) return this.item.isValid;
    else return true;
  }

  @ViewChildren(GSPlaceholder)
  public placeholders: QueryList<GSPlaceholder>;

  //#endregion

  //#region -------Constructor
  constructor(
    private formBuilder: UntypedFormBuilder,
    private resolver: ComponentFactoryResolver,
    private translate: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    this.construct();
    //this.item.valid = !this.properties.some(p => !p.valid);
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.placeholders.changes.subscribe(this.handlePlaceholders);
    this.renderProperties(this.properties);
  }

  //#endregion

  //#region -------UI Events
  onPropertyChanged = (e) => {
    if (this.onChange) {
      this.onChange.emit(e);
      // this.item.valid = !this.properties.some(p => !p.valid);
    }
  };
  submit(e) {
    this.onSubmit.emit(e);
  }

  handlePlaceholders = (value) => {
    //let propertiesNames = value._results.filter(p => p.viewContainerRef.element.nativeElement.parentNode.childElementCount == 1).map(p => p.reference);
    //let properties = this.properties.filter(p => propertiesNames.indexOf(p.name) > -1);
    this.renderProperties(this.properties);
  };
  //#endregion

  //#region -------Private
  private construct() {
    if (this.properties) {
      let maxRow = this.maxRow();
      for (let i = 0; i <= maxRow; i++) {
        let row = new FormRow();
        let filter = this.properties
          .filter((p) => p.row == i)
          .sort((a, b) => a.column - b.column);
        if (filter.some((p) => p.rowspan)) row.hasRowSpans = true;
        filter.forEach((property) => {
          row.properties.push(property);
        });
        this.rows.push(row);
      }
    }
  }

  private constructPropertyProperties(row: FormProperty) {}

  private maxRow() {
    if (this.properties.length)
      return this.properties.sort((a, b) => b.row - a.row)[0].row;
    else return 0;
  }

  private maxRowSpan(row: FormRow) {
    return row.properties.sort((a, b) => b.rowspan - a.rowspan)[0].rowspan;
  }

  private renderProperties(properties) {
    properties.forEach((prop, i) => {
      var placeholder = this.placeholders
        .toArray()
        .find((pc) => pc.reference == prop.name);
      if (placeholder) {
        let inputProviders = [
          prop.component,
          { provide: 'property', useFactory: () => prop },
          //this.createProvider(prop),
          { provide: 'item', useFactory: () => this.item },
        ];
        let injector = Injector.create(inputProviders);
        //let injector = ReflectiveInjector.resolveAndCreate(inputProviders, placeholder.viewContainerRef.parentInjector);

        // We create an injector out of the data we want to pass down and this components injector
        //let injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, placeholder.viewContainerRef.parentInjector);

        // We create a factory out of the component we want to create
        let factory = this.resolver.resolveComponentFactory(prop.component);
        let component;
        if (placeholder.viewContainerRef.injector) {
          component = factory.create(
            injector,
            null,
            null,
            placeholder.viewContainerRef.injector.get(NgModuleRef)
          );
        } else {
          component = factory.create(injector, null, null);
        }

        placeholder.viewContainerRef.clear();
        placeholder.viewContainerRef.insert(component.hostView, 0);

        //var component = (placeholder as GSPlaceholder).viewContainerRef.c
        if (component.instance) {
          component.instance.property = prop;
          component.instance.item = this.item;
          component.instance.onChange.subscribe(this.onPropertyChanged);
        }
      }
    });
  }
  //#endregion
}
