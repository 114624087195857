<ng-container [ngSwitch]="col.type">
  <ng-container
    *ngSwitchCase="[ColumnTypes.Text,ColumnTypes.Number,ColumnTypes.CustomerTextMultiSelectFilter,ColumnTypes.TextWithDropFilter ].includes(col.type) ? col.type : !col.type"
  >
    <ng-container *ngIf="!isHidden(item,col)">
      <div
        *ngIf="col.selectRow"
        (click)="onSelectCell({column: col, row: item.data})"
      >
        {{item.data[col.name]}}
      </div>
      <div
        *ngIf="!col.selectRow"
        (click)="handleChange($event, item, col.name, col)"
        gsTooltip="{{(item.parameters && item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].tooltip) ? item.parameters.column[col.name].tooltip:''}}"
        [type]="(item.parameters && item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].tooltipType) ? item.parameters.column[col.name].tooltipType:''"
        [ngClass]="{'pointer':(item.parameters && item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].canClick)}"
        class="{{col.class}} {{item.parameters?.column && item.parameters?.column[col.name]?.icon}} {{item.parameters?.column && item.parameters?.column[col.name]?.class}}"
      >
        {{item.data[col.name]}}
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TextInput">
    <input
      *ngIf="!readOnly && !col.readonly"
      [ngClass]="{'table-control-fill': col.minWidthPX===undefined, 'table-control-inherit': col.minWidthPX!=undefined, 'property-invalid': !validField(item, col.name) }"
      [ngStyle]="{'text-align': col.align}"
      class="table-control p-inputtext"
      type="text"
      [disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
      [class.disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
      pInputText
      [(ngModel)]="item.data[col.name]"
      (ngModelChange)="handleChange($event, item, col.name, col)"
    />
    <ng-container *ngIf="readOnly || col.readonly">
      {{item.data[col.name]}}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.NumberInput">
    <ng-container
      *ngIf="item.parameters[col.name] && !item.parameters[col.name].showButton"
    >
      <input
        [disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
        [class.disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
        [ngClass]="{'table-control-fill': col.minWidthPX===undefined, 'table-control-inherit': col.minWidthPX!=undefined, 'property-invalid': !validField(item, col.name),'disabled':item.parameters && item.parameters[col.name] && item.parameters[col.name].disabled }"
        class="table-control p-inputtext"
        [(ngModel)]="item.data[col.name]"
        type="number"
        [ngStyle]="{'text-align': col.align}"
        (ngModelChange)="handleChange($event, item, col.name, col)"
      />
      <ng-container
        *ngIf="item.parameters && item.parameters[col.name] && item.parameters[col.name].icon"
      >
        <span style="position: absolute; margin-left: 3px; margin-top: 7px">
          <i class="{{item.parameters[col.name].icon}}"></i>
        </span>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="item.parameters[col.name] && item.parameters[col.name].showButton"
    >
      <button
        pButton
        type="button"
        label="{{item.parameters[col.name].description}}"
        class="table-control p-button-secondary"
        (click)="handleChange($event, item, col.name, col)"
        style="
          color: var(--action--edit-color);
          cursor: pointer;
          background-color: transparent;
          border: 0;
        "
      ></button>
    </ng-container>

    <ng-container *ngIf="!item.parameters[col.name]">
      <input
        [disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
        [ngClass]="{'table-control-fill': col.minWidthPX===undefined, 'table-control-inherit': col.minWidthPX!=undefined, 'property-invalid': !validField(item, col.name)}"
        [class.disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference] || item.parameters[col.name] && item.parameters[col.name].disabled"
        class="table-control p-inputtext"
        [(ngModel)]="item.data[col.name]"
        type="number"
        [ngStyle]="{'text-align': col.align}"
        (ngModelChange)="handleChange($event, item, col.name, col)"
      />
      <span
        *ngIf="item.parameters && item.parameters[col.name] && item.parameters[col.name].icon"
        style="position: absolute; margin-left: 3px; margin-top: 7px"
      >
        <i class="{{item.parameters[col.name].icon}}"></i>
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TimeWithSpinners">
    <ng-container>
      <gs-time
        class="table-control-inherit"
        inTable="true"
        [item]="item"
        [property]="{name: col.name}"
        [column]="col"
        [minutesFormat]="col.minutesFormat"
        (onChange)="handleChange($event, item, col.name, col)"
        [readOnly]="readOnly || item.parameters && item.parameters['TimeWithSpinnersReadOnly']"
      >
      </gs-time>
    </ng-container>
    <!--<p-calendar [ngClass]="{' table-control-fill': col.minWidthPX===undefined, ' table-control-inherit': col.minWidthPX!=undefined}" [appendTo]="'body'" timeOnly="true" showTime="true" [(ngModel)]="row[col.name]" dataType="date" (ngModelChange)="handleChange($event, row, col.name, col)"></p-calendar>-->
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TimeUSFormat">
    <ng-container>
      <div>{{item.data[col.name] | timeUS}}</div>
      <!--<gs-time class="table-control-inherit"
               inTable="true"
               [item]="item"
               [property]="{name: col.name}"
               [column]="col"
               [minutesFormat]="col.minutesFormat"
               (onChange)="handleChange($event, item, col.name, col)"
               [readOnly]="readOnly"
               [USFormat]="true">
      </gs-time>-->
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.BoolSwitch" disabled="col.readonly">
    <p-inputSwitch
      [disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
      class="table-control-middle"
      [class.disabled_opacity-no-color]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
      [(ngModel)]="item.data[col.name]"
      (ngModelChange)="handleChange($event, item, col.name, col)"
    ></p-inputSwitch>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ProgressPercent">
    <p-progressBar
      [value]="item.data[col.name]*100"
      style="width: inherit"
    ></p-progressBar>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Checkbox">
    <span>
      <p-checkbox
        class="darkborder"
        [(ngModel)]="item.data[col.name]"
        [disabled]="readOnly || col.readonly || col.enableReference && !item.data[col.enableReference]"
        [binary]="true"
        [ngClass]="{'disabled':(item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].disabled)?item.parameters.column[col.name].disabled:col.disabled}"
        (ngModelChange)="handleChange($event, item, col.name, col)"
      ></p-checkbox>
    </span>
    <span *ngIf="col.label"> {{getValue(item.data,col.label)}} </span>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Dropdown">
    <ng-container *ngIf="!readOnly && !col.readonly">
      <p-dropdown
        *ngIf="col.showNull || item.data[col.name] || item.data[col.name]==0"
        [filter]="col.dropdownSearch"
        filterBy="label"
        class="table-control-middle"
        [ngClass]="{'dropdown-property-invalid': !validField(item,col.name)}"
        [appendTo]="'body'"
        [overlayOptions]="{baseZIndex: 11000}"
        [(ngModel)]="item.data[col.name]"
        [options]="col.options"
        [style]="{'width':' 100%','text-align':'center'}"
        [disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
        [class.disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
        [virtualScroll]="col.virtualScroll"
        [virtualScrollItemSize]="31"
        (ngModelChange)="handleChange($event, item, col.name, col)"
        [placeholder]="col.placeholder"
        optionDisabled="isDisabled"
      >
      </p-dropdown>
    </ng-container>
    <ng-container *ngIf="readOnly || col.readonly">
      {{col.options|gsDropDownValue:item.data[col.name]}}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TimeEmployeeDropdown">
    <gs-time-employee-dropdown
      [item]="item"
      [column]="col"
      (onChange)="handleChange($event, item, col.name, col)"
    ></gs-time-employee-dropdown>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.PETemplateFieldTypeValue">
    <gs-pe-template-item
      class="table-control-inherit"
      [ngClass]="{ 'disabled': col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]}"
      [item]="item"
      [column]="col"
      (onChange)="handleChange($event, item, col.name, col)"
    >
    </gs-pe-template-item>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.BoolTrafficlight">
    <ng-container>
      <div
        [ngClass]="{' trafficlight-green':item.data[col.name] == true, ' trafficlight-orange':item.data[col.name] == false}"
      >
        {{ item.data[col.name] == true? ('general.finished'| gstranslate) :
        ('general.inProgress'| gstranslate) }}
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.BoolTrafficlightYesNo">
    <ng-container>
      <div
        [ngClass]="{' trafficlight-green':item.data[col.name] == true, ' trafficlight-orange':item.data[col.name] == false}"
        style="text-align: center"
      >
        {{ item.data[col.name] == true? ('general.yes' | gstranslate) :
        ('general.no'| gstranslate) }}
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.DeleteAction">
    <button
      *ngIf="!readOnly && (!col.visibleParameterReference || col.visibleParameterReference && item.parameters && item.parameters[col.visibleParameterReference])"
      pButton
      type="button"
      class="table-control p-button-secondary"
      [ngClass]="{ 'disabled': col.disabled }"
      icon="{{col.icon}}"
      (click)="handleChange($event, item, col.name, col)"
      style="{{col.color==null?'color: var(--action--delete-color); cursor: pointer; background-color: transparent; border:0;': 'color: '+ col.color+'; cursor: pointer; background-color: transparent; border:0;'}}"
    >
      <!--color: var(--action--delete-color);
            cursor: pointer;
            background-color: transparent;
            border: 0;
          "-->
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.EditAction">
    <button
      pButton
      type="button"
      class="table-control p-button-secondary"
      [ngClass]="{'disabled': col.disabled }"
      icon="{{col.icon}}"
      (click)="handleChange($event, item, col.name, col)"
      style="
        color: var(--action--edit-color);
        cursor: pointer;
        background-color: transparent;
        border: 0;
      "
    ></button>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.BoolShowAction">
    <button
      *ngIf="item.data[col.name]"
      pButton
      type="button"
      class="table-control p-button-secondary"
      icon="{{col.icon}}"
      (click)="handleChange($event, item, col.name, col)"
      [ngStyle]="{'color': col.color ? col.color : 'var(--action--edit-color)'}"
      style="cursor: pointer; background-color: transparent; border: 0"
    ></button>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.EditActionText">
    <button
      pButton
      type="button"
      class="table-control p-button-secondary"
      icon="{{col.icon}}"
      label="{{item.parameters[col.name+'_AllowedActionLabel']}}"
      (click)="handleChange($event, item, col.name, col)"
      [style.color]="item.parameters[col.name+'_AllowedActionColor']"
      style="background-color: transparent; cursor: pointer; border: 0"
    ></button>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.StarsRating">
    <p-rating
      readonly="col.readonly"
      [stars]="item.data[col.name].Total"
      [(ngModel)]="item.data[col.name].Done"
      [cancel]="!col.readonly"
    ></p-rating>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Button">
    <span *ngIf="col.icon">
      <button
        *ngIf="(item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].visible!=undefined) ? item.parameters.column[col.name].visible : true"
        pButton
        type="button"
        class="table-control p-button-secondary {{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].class?item.parameters.column[col.name].class:''}}"
        gsTooltip="{{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].tooltip? item.parameters.column[col.name].tooltip: (col.tooltip ? col.tooltip:'')}}"
        [type]="'info'"
        [disabled]="col.readonly || col.disabled || ((item.parameters && item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].disabled!=undefined) ? item.parameters.column[col.name].disabled : false) || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
        icon="{{col.icon}}"
        (click)="handleChange($event, item, col.name, col)"
        style="color: var(--action--edit-color); cursor: pointer"
      ></button>
    </span>
    <span *ngIf="item.data[col.name]">
      <div
        [padding]="3"
        class="pointer white"
        [ngClass]="{'edit-background':item.data[col.name],'navigation-master-background':!item.data[col.name],'disabled':col.disabled}"
        style="border-radius: 5px; min-width: 70px; width: 100%"
        (click)="handleChange($event, item, col.name, col)"
      >
        <span class="text-m">
          {{ item.data[col.name] ?
          ('general.team'|gstranslate)+item.data[col.name] :
          ('general.teamUp'|gstranslate) }}
        </span>
      </div>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ButtonText">
    <button
      prevent-click
      *ngIf="(item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].visible!=undefined) ? item.parameters.column[col.name].visible : true"
      pButton
      type="button"
      class="{{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].class?item.parameters.column[col.name].class:''}} text-s-important navigation-master-background white"
      [ngClass]="{'disabled':item.parameters.column && item.parameters.column[col.name]?.disabled?item.parameters.column[col.name].disabled:col.disabled}"
      gsTooltip="{{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].tooltip? item.parameters.column[col.name].tooltip: (col.tooltip ? col.tooltip:'')}}"
      [type]="'info'"
      label="{{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].description? item.parameters.column[col.name].description: col.description}}"
      icon="{{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].icon? item.parameters.column[col.name].icon: col.icon}}"
      (click)="handleChange($event, item, col.name, col)"
      style="cursor: pointer; width: 100%"
      iconPos="{{item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].iconposition? item.parameters.column[col.name].iconposition: 'left'}}"
    ></button>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ProgressFraction">
    {{item.data[col.name].Done}} / {{item.data[col.name].Total}}
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ProgressFractionWithAction">
    <span sstyle="vertical-align: middle;">
      <label style="margin-right: 5px"
        >{{item.data[col.name].Done}} / {{item.data[col.name].Total}}</label
      >
      <div
        style="display: contents"
        (click)="handleChange($event, item, col.name, col)"
      >
        <i class="{{col.icon}}"></i>
      </div>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ExpandButton">
    <span
      [padding-top]="20"
      gsTooltip="{{item.parameters.expandTooltip ? item.parameters.expandTooltip:''}}"
      [type]="item.parameters.disabled? 'error':'info'"
    >
      <span
        *ngIf="items"
        class="text-xl navigation-master-color pointer"
        [ngClass]="{'disabled':item.parameters.disabled}"
        [pRowToggler]="item.data"
      >
        <i
          class="fas fa-chevron-circle-down"
          (click)="handleChange($event, item, col.name, col)"
        ></i>
      </span>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ItemDropdown">
    <ng-container *ngIf="item.parameters[col.name]">
      <p-dropdown
        *ngIf="col.showNull || item.data[col.name] || item.data[col.name]==0"
        [filter]="col.dropdownSearch"
        [disabled]="readOnly || col.readOnly"
        class="table-control-middle"
        [ngClass]="{'dropdown-property-invalid': !validField(item,col.name)}"
        [appendTo]="'body'"
        [(ngModel)]="item.data[col.name]"
        [options]="item.parameters[col.name]"
        [style]="{'width':' 100%','text-align':'center'}"
        (ngModelChange)="handleChange($event, item, col.name, col)"
      >
      </p-dropdown>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.RadioButton">
    <ul *ngIf="col.options" class="list-unstyled list-inline">
      <li *ngFor="let option of col.options">
        <label class="container">
          {{option.label}}
          <input
            type="radio"
            name="col.name_{{rowIndex}}"
            [(ngModel)]="item.data[col.name]"
            [value]="option.value"
            (ngModelChange)="handleRadioButtonChange($event, item, col.name, col, option.value)"
            disabled="{{col.disabled}}"
          />
          <span class="checkmark"></span>
        </label>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Currency">
    <gs-input-decimal
      class="table-control-inherit"
      [ngClass]="{
        'currency-property-invalid': !validField(item, col.name), 
        'disabled': col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]
      }"
      [item]="item"
      [column]="col"
      (onChange)="handleChange($event, item, col.name, col)"
    >
    </gs-input-decimal>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Time" style="padding-right: 5px">
    {{item.data[col.name]}}
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TimeSpan" style="padding-right: 5px">
    {{ (Math.floor(item.data[col.name]/60)).toString().padStart(2,
    '0')+':'+(item.data[col.name]%60).toString().padStart(2, '0') }}
  </ng-container>
  <ng-container
    *ngSwitchCase="ColumnTypes.DateTimeFormat"
    style="padding-right: 5px"
  >
    {{item.data[col.name] | date : col.dateFormat}}
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Date" style="padding-right: 5px">
    {{item.data[col.name] | date}}
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.DateTime">
    <p-calendar
      [appendTo]="'body'"
      [ngClass]="{
        'table-control-fill': col.minWidthPX === undefined, 
        'table-control-inherit': col.minWidthPX != undefined, 
        'calendar-property-invalid': !validField(item, col.name),
        'disabled-datetime': readOnly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]
      }"
      [hideOnDateTimeSelect]="col.closeOnSelect"
      [disabled]="readOnly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
      [(ngModel)]="item.data[col.name]"
      [showTime]="!col.dateOnly"
      [minDate]="col.minDate"
      [maxDate]="col.maxDate"
      [dateFormat]="col.dateFormat ? col.dateFormat : 'mm/dd/yy'"
      [hourFormat]="col.hoursFormat ? col.hoursFormat : 24"
      (ngModelChange)="handleChange($event, item, col.name, col)"
      [ngStyle]="{
        'text-align': col.align, 
        'max-width': col.minWidthPX ? col.minWidthPX +'px' : 'auto'
      }"
    ></p-calendar>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.DateTimeString">
    <div *ngIf="col.hoursFormat!=12">{{item.data[col.name] | dateString}}</div>
    <div *ngIf="col.hoursFormat==12">
      {{item.data[col.name] | date : col.dateFormat}} {{ item.data[col.name] |
      timeUS }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.IconSetDynamic">
    <div *ngIf="item.parameters && item.parameters.icon">
      <i
        class="{{item.parameters.icon}}"
        [style.color]="item.parameters.iconColor"
      ></i>
    </div>
    <div *ngIf="!item.parameters || !item.parameters.icon">
      <p-progressSpinner
        [style]="{width: '25px', height: '25px'}"
        strokeWidth="4"
      ></p-progressSpinner>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Icon">
    <div *ngIf="item.parameters?.column[col.name]?.visible">
      <i
        class="{{item.parameters?.column[col.name]?.icon}}"
        [style.color]="item.parameters?.column[col.name]?.iconColor"
      ></i>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.IconSet">
    <div>
      <i
        class="{{getIcon(item, col)}}"
        [style.color]="getIconColor(item, col)"
      ></i>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ParameterInput">
    <input
      [disabled]="readOnly || col.readonly"
      [ngClass]="{'table-control-fill': col.minWidthPX===undefined, 'table-control-inherit': col.minWidthPX!=undefined }"
      class="table-control p-inputtext"
      [(ngModel)]="item.parameters.column[col.name]"
      type="number"
      [ngStyle]="{'text-align': col.align,'width': col.minWidthPX ?  col.minWidthPX +'px' : 'auto' }"
      (ngModelChange)="handleChange($event, item, col.name, col)"
    />
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.DepartmentDropdown">
    <gs-department-dropdown
      [item]="item"
      [column]="col"
      (onChange)="handleChange($event, item, col.name, col)"
    >
    </gs-department-dropdown>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TimeChangeLog">
    <div style="text-align: center">
      <ng-container [ngSwitch]="item.data[col.name]">
        <ng-container *ngSwitchCase="1">
          <span class="time_change_log add">
            {{'general.add'|gstranslate|uppercase}}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <span class="time_change_log update">
            {{'general.update'|gstranslate|uppercase}}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <span class="time_change_log remove">
            {{'general.remove'|gstranslate|uppercase}}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <span class="time_change_log rollback">
            {{'general.rollback'|gstranslate|uppercase}}
          </span>
        </ng-container>
        <ng-container *ngSwitchDefault> </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.ScheduledValue">
    <ng-container [ngSwitch]="item.data[col.related]">
      <!-- XWeeklyForYDaysOfWeek -->
      <ng-container *ngSwitchCase="4">
        <gs-scheduled-value
          [column]="col"
          [item]="item"
          (propertyChanged)="handleChange($event, item, col.name, col)"
        ></gs-scheduled-value>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div>
          <input
            *ngIf="!readOnly && !col.readonly"
            [ngClass]="{'table-control-fill': col.minWidthPX===undefined, 'table-control-inherit': col.minWidthPX!=undefined, 'property-invalid': !validField(item, col.name) }"
            [ngStyle]="{'text-align': col.align}"
            class="table-control p-inputtext"
            type="text"
            [disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
            [class.disabled]="readOnly || col.readonly || col.disabledParameterReference && item.parameters && item.parameters[col.disabledParameterReference]"
            pInputText
            [(ngModel)]="item.data[col.name]"
            (ngModelChange)="handleChange($event, item, col.name, col)"
          />
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="readOnly || col.readonly">
      {{item.data[col.name]}}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.TextLink">
    <div
      (click)="onSelectCell({column: col, row: item.data})"
      class="text_link"
    >
      {{item.data[col.name]}}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.Modal">
    <div>
      <button
        *ngIf="(item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].visible!=undefined) ? item.parameters.column[col.name].visible : true"
        pButton
        (click)="showDialog($event, item, col.name, col)"
        label="See more"
        class="gs-modal-button"
      ></button>
      <div class="gs-modal">
        <p-dialog
          header="{{col.description}}"
          [modal]="true"
          [(visible)]="showModal"
          [style]="{ width: '65rem' }"
        >
          {{getValue(item.data,col.name)}}
        </p-dialog>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnTypes.DynamicModal">
    <div>
      <button
        *ngIf="(item.parameters.column && item.parameters.column[col.name] && item.parameters.column[col.name].visible!=undefined) ? item.parameters.column[col.name].visible : true"
        pButton
        (click)="showDialog($event, item, col.name, col)"
        label="See more"
        class="gs-modal-button"
      ></button>
      <div class="gs-modal">
        <p-dialog
          *ngIf="item.items"
          header="{{col.description}}"
          [modal]="true"
          [(visible)]="showModal"
          [style]="{ width: '65rem' }"
        >
          <gs-wf-log-details
            class="table-control-inherit"
            [item]="item"
            [column]="col"
            [loadData]="showModal"
            (onChange)="handleChange($event, item, col.name, col)"
          >
          </gs-wf-log-details>
        </p-dialog>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div>{{item.data[col.name]}}</div>
  </ng-container>
</ng-container>
